import { Grid, TextField, useMediaQuery } from "@mui/material";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { useState } from "react";
import { ISRTTherapyInformationProps } from "./srtTherapyInformation.interface";
import { ISalesRoundingPatientDetails } from "../../salesRoundingTool.interface";
import { CustomDropDown } from "../../../../core/customDropdown/customDropdown.component";
import { SalesRoundingValidator } from "../../salesRoundingTool.validator";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { ReactComponent as CalendarIcon } from "../../../../assets/calendar.svg";
import {
  convertStringToDate,
  getTextFromCode,
  getValidObj,
} from "../../../../util/utilityFunctions";
import "./srtTherapyInformation.css";

export const SrtTherapyInformation = ({
  data,
  setData,
  srtDropDownValues,
}: ISRTTherapyInformationProps) => {
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const [focusClasses, setFocusClasses] = useState({
    transitionTherapyStartDate: "",
  });
  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const validator = new SalesRoundingValidator();

  const validateAndSetDate = (date: string | null | undefined) => {
    const formattedDate = date === null ? "" : convertStringToDate(date);
    const isValid =
      formattedDate === ""
        ? getValidObj()
        : validator.validate(formattedDate!, "transitionTherapyStartDate");
    const tempData: ISalesRoundingPatientDetails = {
      ...data,
      therapyInformation: {
        ...data.therapyInformation,
        transitionTherapyStartDate: {
          ...data.therapyInformation.transitionTherapyStartDate,
          errorMessage: null,
          valid: isValid!.status,
          value: formattedDate,
        },
      },
    };
    setData(tempData);
  };

  const validateAndSetLeadGenerationData = (e: any) => {
    const { name, value } = e.target;
    const isValid = validator.validate(value, name);
    const tempData: ISalesRoundingPatientDetails = {
      ...data,
      therapyInformation: {
        ...data.therapyInformation,
        leadGeneration: {
          ...data.therapyInformation.leadGeneration,
          value: value,
          valid: isValid!.status,
          errorMessage: null,
        },
      },
    };
    setData(tempData);
  };

  return (
    <div
      className="sales-rounding-tool-therapy-information-component"
      data-testid="sales-rounding-tool-therapy-information-component"
    >
      <p
        className="sales-rounding-tool-therapy-information-title"
        data-testid="sales-rounding-tool-therapy-information-title"
        id="sales-rounding-tool-therapy-information-title"
      >
        Therapy
      </p>
      <Grid
        className="sales-rounding-tool-therapy-information-grid-container"
        container
      >
        <Grid
          className="sales-rounding-tool-therapy-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            testId={
              data.therapyInformation.transitionTherapyStartDate.componentId!
            }
            id={data.therapyInformation.transitionTherapyStartDate.componentId!}
            labelClassName={focusClasses.transitionTherapyStartDate}
            label={data.therapyInformation.transitionTherapyStartDate.title}
            isRequired={
              data.therapyInformation.transitionTherapyStartDate.required
            }
            error={
              data.therapyInformation.transitionTherapyStartDate.valid ===
              ValidationStatus.INVALID
            }
            isShowWarning={true}
            warningMessage={
              data.therapyInformation.transitionTherapyStartDate.errorMessage
            }
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                components={{ OpenPickerIcon: CalendarIcon }}
                InputAdornmentProps={{
                  classes: {
                    root: "adornedRoot",
                  },
                }}
                InputProps={{
                  classes: {
                    root: `transitionTherapyStartDate ${
                      data.therapyInformation.transitionTherapyStartDate
                        ?.valid === ValidationStatus.INVALID
                        ? "showError"
                        : "noError"
                    }`,
                    input: "input",
                    notchedOutline: "outline",
                  },
                  readOnly: true,
                }}
                minDate={moment().toString()}
                maxDate={moment().add(30, "d").toString()}
                value={
                  data.therapyInformation.transitionTherapyStartDate?.value
                }
                onChange={(value: any) => validateAndSetDate(value)}
                renderInput={(params: any) => {
                  params.inputProps!.placeholder = "__/__/____";
                  return (
                    <TextField
                      id={
                        data.therapyInformation.transitionTherapyStartDate?.id
                      }
                      name="transitionTherapyStartDate"
                      onFocus={(e) => setClasses(e, "Mui-focused")}
                      onBlur={(e) => setClasses(e, "")}
                      {...params}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </InputWithLabel>
        </Grid>
        <Grid
          className="sales-rounding-tool-therapy-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={
              data.therapyInformation.leadGeneration.valid ===
              ValidationStatus.INVALID
            }
            id={data.therapyInformation.leadGeneration.componentId!}
            isRequired={data.therapyInformation.leadGeneration.required}
            isShowWarning={true}
            label={data.therapyInformation.leadGeneration.title!}
            labelClassName="srtLeadGeneration-type-title"
            testId={data.therapyInformation.leadGeneration.componentId!}
            warningMessage={data.therapyInformation.leadGeneration.errorMessage}
            isDropdown={true}
          >
            <CustomDropDown
              handleChange={validateAndSetLeadGenerationData}
              menuItem={srtDropDownValues.isLeadGenerationTextDropdown}
              name="leadGeneration"
              selectpropsClassName={
                data.therapyInformation.leadGeneration.value
                  ? "srtLeadGeneration-info-select"
                  : "placeHolder"
              }
              selectClassName={
                data.therapyInformation.leadGeneration.value
                  ? "srtLeadGeneration-info-input"
                  : "placeHolder"
              }
              testId="leadGeneration"
              value={
                data.therapyInformation.leadGeneration.value
                  ? getTextFromCode(
                      srtDropDownValues.isLeadGenerationCodeDropdown,
                      data.therapyInformation.leadGeneration.value
                    )
                  : null
              }
            />
          </InputWithLabel>
        </Grid>
      </Grid>
    </div>
  );
};
