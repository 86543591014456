import { useState } from "react";
import "./patientInformation.css";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { convertStringToDate } from "../../../../util/utilityFunctions";
import { InpatientOrderValidator } from "../../inpatientOrder.validator";
import { IPatientInformationProps } from "./patientInformation.interface";
import { Grid, InputBase, TextField, useMediaQuery } from "@mui/material";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { ReactComponent as CalendarIcon } from "../../../../assets/calendar.svg";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";

export const PatientInformation = ({
  data,
  isAutoFocusEnabled,
  patientInformation,
  setData,
}: IPatientInformationProps) => {
  const [focusClasses, setFocusClasses] = useState({ dateOfBirth: "" });
  const isSmallerMobileScreen = useMediaQuery("(max-width:414px)");
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const validator = new InpatientOrderValidator();
    const isValid = validator.validate(value, name);
    const tempData = {
      ...data,
      patientInformation: {
        ...data.patientInformation,
        value: {
          ...data.patientInformation.value,
          [name]: {
            ...Object(data.patientInformation.value)[name],
            errorMessage: null,
            valid: isValid!.status,
            value: value,
          },
        },
      },
    };
    setData(tempData);
  };

  const validateAndSetDate = (date: string | null | undefined) => {
    const formattedDate = date ? convertStringToDate(date) : "";
    const validator = new InpatientOrderValidator();
    const isValid = validator.validate(formattedDate!, "dateOfBirth");
    const tempData = {
      ...data,
      patientInformation: {
        ...data.patientInformation,
        value: {
          ...data.patientInformation.value,
          dateOfBirth: {
            ...data.patientInformation.value.dateOfBirth,
            errorMessage: null,
            valid: isValid!.status,
            value: formattedDate ?? "",
          },
        },
      },
    };
    setData(tempData);
  };

  return (
    <div
      className="in-patient-order-patient-information-component"
      data-testid="in-patient-order-patient-information-component"
    >
      <p
        className="in-patient-order-patient-information-title"
        data-testid="in-patient-order-patient-information-title"
        id="in-patient-order-patient-information-title"
      >
        Patient Information
      </p>
      <Grid
        className="in-patient-order-patient-information-grid-container"
        container
      >
        <Grid
          className="in-patient-order-patient-information-grid-item"
          item
          xs={isSmallerMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={
              patientInformation.firstName.valid === ValidationStatus.INVALID
            }
            id={patientInformation.firstName.componentId!}
            isRequired={patientInformation.firstName.required}
            isShowWarning={true}
            label={patientInformation.firstName.title!}
            labelClassName="patient-information-title"
            testId={patientInformation.firstName.componentId!}
            warningMessage={patientInformation.firstName.errorMessage}
          >
            <InputBase
              autoFocus={isAutoFocusEnabled}
              className="patient-information-input"
              data-testid={patientInformation.firstName.id!}
              id={patientInformation.firstName.id!}
              name="firstName"
              onChange={validateAndSetData}
              value={patientInformation.firstName.value}
              inputProps={{ maxLength: 15 }}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="in-patient-order-patient-information-grid-item"
          item
          xs={isSmallerMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={
              patientInformation.lastName.valid === ValidationStatus.INVALID
            }
            id={patientInformation.lastName.componentId!}
            isRequired={patientInformation.lastName.required}
            isShowWarning={true}
            label={patientInformation.lastName.title!}
            labelClassName="patient-information-title"
            testId={patientInformation.lastName.componentId!}
            warningMessage={patientInformation.lastName.errorMessage}
          >
            <InputBase
              className="patient-information-input"
              data-testid={patientInformation.lastName.id!}
              id={patientInformation.lastName.id!}
              name="lastName"
              onChange={validateAndSetData}
              value={patientInformation.lastName.value}
              inputProps={{ maxLength: 15 }}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="in-patient-order-patient-information-grid-item"
          item
          xs={isSmallerMobileScreen ? 12 : isMobileScreen ? 6 : 4}
        >
          <InputWithLabel
            error={
              patientInformation.dateOfBirth.valid === ValidationStatus.INVALID
            }
            id={patientInformation.dateOfBirth.componentId!}
            isRequired={patientInformation.dateOfBirth.required}
            isShowWarning={true}
            label={patientInformation.dateOfBirth.title!}
            labelClassName={focusClasses.dateOfBirth}
            testId={patientInformation.dateOfBirth.componentId!}
            warningMessage={patientInformation.dateOfBirth.errorMessage}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                components={{ OpenPickerIcon: CalendarIcon }}
                InputAdornmentProps={{
                  classes: {
                    root: "adornedRoot",
                  },
                }}
                InputProps={{
                  classes: {
                    root: `in-patient-order-patient-information-date-of-birth ${
                      patientInformation.dateOfBirth.valid ===
                      ValidationStatus.INVALID
                        ? "show-error"
                        : "no-error"
                    }`,
                    input: "input",
                    notchedOutline: "outline",
                  },
                }}
                onChange={(value) => validateAndSetDate(value)}
                renderInput={(params) => {
                  params.error = false;
                  params.inputProps!.placeholder = "__/__/____";
                  return (
                    <TextField
                      id={patientInformation.dateOfBirth.id!}
                      name="dateOfBirth"
                      onFocus={(e) => setClasses(e, "Mui-focused")}
                      onBlur={(e) => setClasses(e, "")}
                      {...params}
                    />
                  );
                }}
                value={patientInformation.dateOfBirth.value}
              />
            </LocalizationProvider>
          </InputWithLabel>
        </Grid>
        <Grid
          className="in-patient-order-patient-information-grid-item"
          item
          xs={isSmallerMobileScreen ? 12 : isMobileScreen ? 6 : 4}
        >
          <InputWithLabel
            error={patientInformation.room.valid === ValidationStatus.INVALID}
            id={patientInformation.room.componentId!}
            isRequired={patientInformation.room.required}
            isShowWarning={true}
            label={patientInformation.room.title!}
            labelClassName="patient-information-title"
            testId={patientInformation.room.componentId!}
            warningMessage={patientInformation.room.errorMessage}
          >
            <InputBase
              className="patient-information-input"
              data-testid={patientInformation.room.id!}
              id={patientInformation.room.id!}
              name="room"
              onChange={validateAndSetData}
              value={patientInformation.room.value}
              inputProps={{ maxLength: 8 }}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="in-patient-order-patient-information-grid-item"
          item
          xs={isSmallerMobileScreen ? 12 : isMobileScreen ? 6 : 4}
        >
          <InputWithLabel
            error={
              patientInformation.patientId.valid === ValidationStatus.INVALID
            }
            id={patientInformation.patientId.componentId!}
            isRequired={patientInformation.patientId.required}
            isShowWarning={true}
            label={patientInformation.patientId.title!}
            labelClassName="patient-information-title"
            testId={patientInformation.patientId.componentId!}
            warningMessage={patientInformation.patientId.errorMessage}
          >
            <InputBase
              className="patient-information-input"
              data-testid={patientInformation.patientId.id!}
              id={patientInformation.patientId.id!}
              name="patientId"
              onChange={validateAndSetData}
              value={patientInformation.patientId.value}
              inputProps={{ maxLength: 12 }}
            />
          </InputWithLabel>
        </Grid>
      </Grid>
    </div>
  );
};
