import "./placeOrder.css";
import React, { useEffect } from "react";
import {
  AuthContext,
  AuthContextType,
} from "../../../../../context/AuthContext";
import { useContext, useState } from "react";
import {
  getCareGiverId,
  getCustomerFacilityAccountNumber,
  getCustomerMasterNumber,
  getSiteUseId,
  makeCapitalEachWordInString,
  useSortableTable,
} from "../../../../../util/utilityFunctions";
import { Grid, TextField } from "@mui/material";
import {
  MyPatientContext,
  MyPatientContextType,
} from "../../../../../context/MyPatientContext";
import { IPlaceOrder } from "./placeOrder.interface";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../../../context/RolesPermissionContext";
import { PlaceOrderTable } from "./placeOrderTable.component";
import { Validator } from "../../../../../util/order.validations";
import { Popup } from "../../../../../core/popup/popup.component";
import { getFaciityPatients } from "../../../../../util/3meService";
import { IPatient } from "../../../../myPatients/patient.interface";
import { ReactComponent as SearchIconSvg } from "../../../../../assets/blackSearchIcon.svg";
import { AddPatientButton } from "../../../../myPatients/addPatientButton/addPatientButton.component";
import { AddPatientContext } from "../../../../myPatients/addPatientContainer/addPatientContainer.context";
import { AddPatientContainer } from "../../../../myPatients/addPatientContainer/addPatientContainer.component";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import { useHistory } from "react-router";
import {
  REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE,
  SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
  USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE,
} from "../../../../../util/errorCode";

export const PlaceOrder = ({ product }: IPlaceOrder) => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [patientSearchKeyWords, setPatientSearchKeyWords] =
    useState<string>("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [patientList, setPatientList] = React.useState<Array<IPatient>>();
  const [isShowLoader, setIsShowLoader] = useState<boolean>(false);

  const columns = [
    { label: "", accessor: "orderID", sortable: false },
    { label: "Name", accessor: "lastName", sortable: true },
    { label: "Date of Birth", accessor: "dob", sortable: true },
    { label: "RO #", accessor: "roNumber", sortable: true },
  ];
  const history = useHistory();
  const [sortedData, setSortedData, handleSorting] = useSortableTable(
    [],
    columns
  );
  const MyPatientObj = useContext<MyPatientContextType | null>(
    MyPatientContext
  );
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  const handleAddPatient = () => {
    setOpen(true);
  };

  const handleSearchPatient = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let searchDataLocal = patientSearchKeyWords;
    const re = /^[a-zA-Z0-9-]+$/;
    let { value } = e.target;
    if ((value === "" || re.test(value)) && patientList) {
      searchDataLocal = value;
    }
    setPatientSearchKeyWords(searchDataLocal);
    if (searchDataLocal.length >= 3) doLocalSearchForPatient(searchDataLocal);
    else if (
      searchDataLocal.length === 0 &&
      patientSearchKeyWords !== searchDataLocal
    )
      doLocalSearchForPatient("");
  };

  const doLocalSearchForPatient = (searchParam: string) => {
    if (searchParam.length > 0 && patientList) {
      const filtedPatient = patientList.filter(
        (patient: IPatient) =>
          patient.lastName.toLowerCase().includes(searchParam.toLowerCase()) ||
          patient.firstName.toLowerCase().includes(searchParam.toLowerCase()) ||
          patient.roNumber.toString().includes(searchParam)
      );
      setSortedData(filtedPatient);
    } else {
      setSortedData(patientList);
    }
  };
  const loadPatientData = async () => {
    setIsShowLoader(true);
    if (
      authObj &&
      authObj.userProfile &&
      authObj.userProfile.userName &&
      authObj.userProfile.userName !== "" &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      const response = await getFaciityPatients(
        getSiteUseId(authObj),
        getCareGiverId(authObj),
        authObj.registeredFaciltyAddress.careGiverSiteUseId,
        authObj.userProfile.userName,
        true,
        getCustomerMasterNumber(authObj),
        authObj.registeredFaciltyAddress.accountNumber!,
        true,
        false
      );
      setIsShowLoader(false);
      if (response && response.succeeded) {
        const data = response.data ?? [];
        if (data.length >= 0) {
          setPatientList(data);
          setSortedData(data);
        }
      } else {
        setError(true);
        setErrorPopUp(true);
        setErrorCode(
          response?.error?.code ||
            response?.error?.errorCode ||
            response?.status
        );
      }
    } else {
      if (!authObj || (authObj && !authObj.userProfile)) {
        setErrorCode(USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE);
      } else if (!authObj || (authObj && !authObj.registeredFaciltyAddress)) {
        setErrorCode(REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE);
      } else if (
        !authObj ||
        (authObj && !authObj.registeredFaciltyAddress?.siteUseId) ||
        (authObj && authObj.registeredFaciltyAddress?.siteUseId === "")
      ) {
        setErrorCode(SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE);
      }
      setError(true);
      setErrorPopUp(true);
    }
  };
  useEffect(() => {
    loadPatientData();
  }, []);

  const loginButtonAction = () => {
    history.push("/");
  };
  const createAccButtonAction = () => {
    history.push("/signup");
  };
  return (
    <>
      {authObj?.isLoggedIn ? (
        <>
          <div className="placeAnOrder-main-component">
            <div className="placeAnOrder-text-section">
              <h5
                className="placeAnOrder-header"
                data-testid="placeAnOrder-header"
              >
                Place an Order
              </h5>
              <div
                className="placeAnOrder-facility-details-section"
                data-testid="facility-address-city-state-zip"
              >
                {authObj && authObj.registeredFaciltyAddress
                  ? `${makeCapitalEachWordInString(
                      authObj?.registeredFaciltyAddress?.accountName
                    )}, ${makeCapitalEachWordInString(
                      authObj?.registeredFaciltyAddress?.address1
                    )}, ${makeCapitalEachWordInString(
                      authObj?.registeredFaciltyAddress?.state
                    )}, ${makeCapitalEachWordInString(
                      authObj?.registeredFaciltyAddress?.city
                    )}, ${authObj?.registeredFaciltyAddress?.zip}`
                  : ""}
              </div>
            </div>
            <div className="placeAnOrder-select-patient">
              <h5
                className="placeAnOrder-select-title"
                data-testid="placeAnOrder-select-title"
              >
                Select Patient
              </h5>
              <Grid className="placeAnOrder-container" container spacing={0}>
                <Grid className="placeAnOrder-grid-item" item xs={8}>
                  <TextField
                    autoComplete="off"
                    data-testid="filter-patients"
                    autoFocus={true}
                    className="placeAnOrder-search-input"
                    inputProps={{ style: { fontSize: 14 } }}
                    placeholder="Filter by Name or RO#"
                    InputProps={{
                      startAdornment: (
                        <SearchIconSvg style={{ marginRight: "5px" }} />
                      ),
                    }}
                    value={patientSearchKeyWords}
                    onChange={handleSearchPatient}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  className="placeAnOrder-grid-item-add-patient"
                  item
                  xs={3}
                >
                  <div className="placeAnOrder-addPatientbutton">
                    <AddPatientButton
                      isBtnVisible={true}
                      onClickHandler={handleAddPatient}
                      isBtnDisabled={
                        permissionObj?.mappedRolesPermissionData.IsSupportRole
                      }
                    />
                  </div>
                </Grid>
              </Grid>
              <PlaceOrderTable
                columns={columns}
                error={error}
                errorPopUp={errorPopUp}
                setErrorPopUp={setErrorPopUp}
                errorCode={errorCode}
                handleSorting={handleSorting}
                product={product}
                sortedData={sortedData}
                spinnerPatientList={isShowLoader}
              />
            </div>
          </div>
          <AddPatientContext.Provider
            value={{
              closePopup: () => setOpen(false),
              patientSearchValidator: new Validator(),
              addPatientToList: () => loadPatientData(),
            }}
          >
            <Popup
              dialogParentClass="add-patient-popup"
              openFlag={open}
              closeHandler={() => setOpen(false)}
            >
              <AddPatientContainer />
            </Popup>
          </AddPatientContext.Provider>
        </>
      ) : (
        <>
          <div className="placeOrderContainer">
            <div
              className="placeOrderContainer-heading"
              data-testid="placeOrderContainer-heading"
            >
              To order this product, you need an account
            </div>
            <div>
              <p
                className="placeOrderContainer-content1"
                data-testid="placeOrderContainer-content1"
              >
                To order this product, you need a 3M™ Express Therapy Portal
                account associated with a facility.
              </p>
              {process.env.REACT_APP_ENV_HIDE_PAGE === "false" && (
                <p className="placeOrderContainer-content2">
                  You can{" "}
                  <span
                    className="createAcc"
                    id="createAcc-id"
                    onClick={() => {
                      createAccButtonAction();
                    }}
                  >
                    create an account now{" "}
                  </span>
                  or contact our National Contact Center at (800) 275-4524 Ext.
                  41858.
                </p>
              )}
            </div>
            <Grid className="placeOrderButtons">
              <Grid className="placeOrderButton1">
                <ExpressButton
                  clickHandler={() => {
                    loginButtonAction();
                  }}
                  parentClass="login-button"
                  testId="login-button"
                  variant="outlined"
                >
                  Login
                </ExpressButton>
              </Grid>
              {process.env.REACT_APP_ENV_HIDE_PAGE === "false" && (
                <Grid className="placeOrderButton2">
                  <ExpressButton
                    clickHandler={() => {
                      createAccButtonAction();
                    }}
                    parentClass="createAcc-button"
                    testId="createAcc-button"
                    variant="contained"
                  >
                    Create Account
                  </ExpressButton>
                </Grid>
              )}
            </Grid>
          </div>
        </>
      )}
    </>
  );
};
