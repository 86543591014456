import React, { ReactNode, useContext, useState } from "react";
import {
  ISalesRoundingContextType,
  SaleRoundingToolContext,
} from "../../../context/SalesRoundingToolContext";
import { SalesRoundingToolPageSection } from "../salesRoundingTool.enum";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { ReactComponent as BackIcon } from "../../../assets/LPBackButton.svg";
import "./salesRoundingToolContainer.css";
import { useHistory, useLocation } from "react-router-dom";
import SalesRoundingFacilityList from "../SalesRoundingTerritoryFacilitiesList/SalesRoundingTerritoryFacilitiesList.component";
import { useMediaQuery } from "@mui/material";
import {
  ISelectedTerritory,
  ISrtDropDownValues,
} from "../salesRoundingTool.interface";
import SalesRoundingDashboard from "../salesRoundingDashboard/salesRoundingDashboard.component";
import { Navigator } from "../../../components/helpAndSupport/Navigator/navigator.component";
import {
  INavigator,
  IRouter,
} from "../../../components/helpAndSupport/Navigator/navigator.interface";
import { SalesRoundingPatientDetails } from "../salesRoundingPatientDetails/salesRoundingPatientDetails.component";
import { FooterButtonGroup } from "../../../components/send3MNote/footerButtonGroup/footerButtonGroup.component";
import { SalesRoundingValidator } from "../salesRoundingTool.validator";
import { IInputField } from "../../../core/interfaces/input.interface";
import { mapSalesRoundingRequest } from "../mappers/request/salesRounding.request.mapper";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { addOrUpdateSRTPatient } from "../../../util/3meService";
import SuccessSnackbar from "../../../core/snackBar/successSnackBar.component";
import ErrorPopup, {
  defaultErrorPopUp,
  IErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import { Popup } from "../../../core/popup/popup.component";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { SRT_PATIENT_DETAIl_UPDATE } from "../../../util/errorMsg";
import { defaultSrtDropDownValues } from "../salesRoundingPatientDetails/salesRoundingPatientDetails.model";

const SalesRoundingToolContainer: React.FC = () => {
  const location = useLocation<any>();
  const [selectedTerritory, setSelectedTerritory] =
    useState<ISelectedTerritory>(location?.state?.stateData);
  const [selectedPatientData, setSelectedPatientData] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
  const [showPopUpLoader, setShowPopUpLoader] = useState(false);
  const [srtDropDownValues, setSrtDropDownValues] =
    useState<ISrtDropDownValues>(defaultSrtDropDownValues);
  const validator = new SalesRoundingValidator();
  const history = useHistory();
  const isMobileScreen = useMediaQuery("(max-width:768px)");

  const salesRoundingObj = useContext<ISalesRoundingContextType | null>(
    SaleRoundingToolContext
  );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const pageSection = salesRoundingObj!.pageSection;
  const setPageSection = salesRoundingObj!.setPageSection;
  const data = salesRoundingObj!.data;
  const setData = salesRoundingObj!.setData;

  const checkForFooter = (): boolean => {
    let isToShowFooter: boolean = false;
    if (pageSection === SalesRoundingToolPageSection.SRT_PATIENT_DETAILS) {
      isToShowFooter = true;
    }
    return isToShowFooter;
  };

  const closeErrorPopUpButtonAction = () => {
    // history.goBack();
    setError(defaultErrorPopUp);
    setPageSection(SalesRoundingToolPageSection.FACILITY_SELECTION);
  };

  const sectionsToDisplay = (): React.ReactNode => {
    let section: ReactNode;
    switch (pageSection) {
      case SalesRoundingToolPageSection.FACILITY_SELECTION:
        section = (
          <SalesRoundingFacilityList
            selectedTerritory={selectedTerritory}
            setSelectedTerritory={setSelectedTerritory}
            setPageSection={setPageSection}
          />
        );
        break;
      case SalesRoundingToolPageSection.SRT_PATIENTS_DASHBOARD:
        section = (
          <SalesRoundingDashboard
            setPageSection={setPageSection}
            setData={setData}
            setSelectedPatientData={setSelectedPatientData}
            srtDropDownValues={srtDropDownValues}
            setSrtDropDownValues={setSrtDropDownValues}
          />
        );
        break;
      case SalesRoundingToolPageSection.SRT_PATIENT_DETAILS:
        section = (
          <SalesRoundingPatientDetails
            setPageSection={setPageSection}
            data={data}
            setData={setData}
            selectedFacility={salesRoundingObj?.selectedFacility!}
            srtDropDownValues={srtDropDownValues}
          />
        );
        break;
    }
    return section;
  };

  const getNavigatorArrayProps = (
    pageSection: SalesRoundingToolPageSection
  ): INavigator => {
    let navigatorProps: INavigator = {
      title: "",
      array: [],
    };
    switch (pageSection) {
      case SalesRoundingToolPageSection.FACILITY_SELECTION:
        navigatorProps = {
          title: "Sales Rounding",
          array: [],
        };
        break;
      case SalesRoundingToolPageSection.SRT_PATIENTS_DASHBOARD:
        navigatorProps = {
          title: "Facility Details",
          array: [
            {
              pageName: "Sales Rounding",
              route: "/salesRounding",
              onLinkClick: () =>
                setPageSection(SalesRoundingToolPageSection.FACILITY_SELECTION),
            },
          ],
        };
        break;
      case SalesRoundingToolPageSection.SRT_PATIENT_DETAILS:
        navigatorProps = {
          title: "Patient Details",
          array: [
            {
              pageName: "Sales Rounding",
              route: "/salesRounding",
              onLinkClick: () =>
                setPageSection(SalesRoundingToolPageSection.FACILITY_SELECTION),
            },
            {
              pageName: "Facility Details",
              route: "/salesRounding",
              onLinkClick: () =>
                setPageSection(
                  SalesRoundingToolPageSection.SRT_PATIENTS_DASHBOARD
                ),
            },
          ],
        };
        break;
    }
    return navigatorProps;
  };
  const handleCloseAlert = () => {
    setShowSnackbar(false);
    setPageSection(SalesRoundingToolPageSection.SRT_PATIENTS_DASHBOARD);
  };
  const cancelButtonAction = () => {
    setPageSection(SalesRoundingToolPageSection.SRT_PATIENTS_DASHBOARD);
  };

  const handleValidateAndSubmitSRT = async () => {
    const [isAllValid, errorField] = validator.validateAll(data, setData);
    if (errorField) {
      checkErroredFieldAndFocusOnInputField(errorField);
    } else if (isAllValid) {
      setShowPopUpLoader(true);
      const mappedRequestBody = await mapSalesRoundingRequest(
        data,
        authObj!,
        selectedPatientData,
        salesRoundingObj?.srtPatientDetailsUpdated!,
        selectedTerritory?.name
      );
      const response = await addOrUpdateSRTPatient(mappedRequestBody);
      if (response && response.succeeded) {
        setShowPopUpLoader(false);
        setShowSnackbar(true);
      } else {
        const apiFailure = {
          errorCode:
            response?.error?.errorCode ||
            response?.error?.code ||
            response?.status,
          errorFlag: true,
          errorMessage: SRT_PATIENT_DETAIl_UPDATE,
          buttonTitle: "Done",
          isPhoneNumberRequired: true,
        };
        setShowPopUpLoader(false);
        setError(apiFailure);
      }
    }
  };

  const checkErroredFieldAndFocusOnInputField = async (
    errorField: IInputField
  ) => {
    if (errorField.componentId && errorField.componentId !== "") {
      const scrollableComponent = document.getElementById(
        errorField.componentId
      );
      await setTimeout(() => {
        if (scrollableComponent) {
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
          });
          if (errorField.id && errorField.id !== "") {
            const autoCursorComponentId = document.getElementById(
              errorField.id
            );
            setTimeout(() => {
              if (autoCursorComponentId) {
                autoCursorComponentId.focus();
              }
            }, 500);
          }
        }
      }, 500);
    }
  };

  return (
    <div
      className="sales-rounding-tool-component-container"
      data-testid="sales-rounding-tool-component-container"
      id="sales-rounding-tool-component-container"
    >
      {showSnackbar === true && (
        <SuccessSnackbar
          alertMessage="Patient listing updated."
          showSnackbar={showSnackbar}
          handleCloseAlert={handleCloseAlert}
        />
      )}
      <ExpressButton
        variant="text"
        parentClass="sales-rounding-tool-container-back-button"
        startIcon={<BackIcon className="backIcon-SelectaFacility" />}
        clickHandler={() => history.goBack()}
        testId="sales-rounding-tool-container-back-button"
      >
        Return to Sales Dashboard
      </ExpressButton>
      <div className="sales-rounding-component">
        {!isMobileScreen && (
          <Navigator
            {...getNavigatorArrayProps(pageSection)}
            isStateDataPresent={true}
            stateData={selectedTerritory}
          />
        )}
        {sectionsToDisplay()}
        {checkForFooter() && (
          <FooterButtonGroup
            firstButtonTitle={"Cancel"}
            firstButtonAction={cancelButtonAction}
            secondButtonTitle={"Update"}
            secondButtonAction={handleValidateAndSubmitSRT}
          />
        )}
      </div>
      <ErrorPopup
        buttontitle={"Done"}
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        isShortSentence={true}
        handleBackButton={() => {
          closeErrorPopUpButtonAction();
        }}
        popUpStyles="errorPopup"
        isSupportPhoneRequired={true}
        showTitle={false}
      />
      <Popup
        closeHandler={() => setShowPopUpLoader(false)}
        dialogParentClass={"srt-dashboard-loader-pop-up"}
        data-testid="srt-dashboard-pop-up"
        hideCloseButton={true}
        openFlag={showPopUpLoader}
      >
        <div
          className="srt-dashboard-loader"
          data-testid="srt-dashboard-loader"
        >
          <LoadingSpinner />
        </div>
      </Popup>
    </div>
  );
};

export default SalesRoundingToolContainer;
