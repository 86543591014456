import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import { useContext, useEffect, useState } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
// Component imports
import { LicenseManager } from "ag-grid-enterprise";
import { ErrorBoundary } from "react-error-boundary";
import { mapUserRolesAndPermissionData } from "../src/RolesPermission/RolesPermission.Mapper";
import Blank from "./blank/blank.component";
import CaptchaFail from "./blank/captchFail.component";
import { FacilitySettings } from "./components/administration/facilitySettings/facilitySettings.component";
import { SaveSuccessfull } from "./components/authenticateProfile/saveSuccess/saveSuccessfull.component";
import { CMSContent } from "./components/cmsContent/cmsContent.component";
import { Education } from "./components/education/education.component";
import { VideoLibrary } from "./components/education/videoLibrary/videoLibrary.component";
import { FacilityBanner } from "./components/facilityBanner/facilityBanner.component";
import { Header } from "./components/header/header.component";
import { Navigator } from "./components/helpAndSupport/Navigator/navigator.component";
import { HelpAndSupport } from "./components/helpAndSupport/helpAndSupport.component";
import { InternalUsersManageProfile } from "./components/internalUsersManageProfile/internalusersManageProfile.component";
import { ManageAccount } from "./components/manageAccount/manageAccount.component";
import { searchFacility } from "./components/manageProfile/facilityInformation/addFacility/addFacility.service";
import { FacilityMode } from "./components/manageProfile/facilityInformation/facility.interface";
import { ManageProfile } from "./components/manageProfile/manageProfile.component";
import { WoundAssessmentReport } from "./components/administration/woundAssessmentReport/woundAssessmentReport.component";
import { OrderOverview } from "./components/myPatients/patientAndTherapyDetails/orderOverview/orderOverview.component";
import { Page } from "./components/page/page.component";
import { ProductDetail } from "./components/productsAndSolutions/productDetail/productDetail.component";
import { ProductsAndSolutions } from "./components/productsAndSolutions/productsAndSolutions.component";
import SystemRequirements from "./components/systemRequirements/systemReq.components";
import { TermsOfUse } from "./components/termsofUse/termsofUse.component";
import config from "./config";
import { AuthContext } from "./context/AuthContext";
import { ExpressButton } from "./core/expressButton/expressButton.component";
import { EmailSent } from "./forgotCredentials/emailSent/emailSent.component";
import { ForgotCredentialParent } from "./forgotCredentials/forgotPassword/forgotPasswordContainer/forgotCredential.component";
import Login from "./signIn/Login";
import { Registration } from "./signUp/registration/registration.component";
import { Signup } from "./signUp/signup/signup.component";
import { SSORedirect } from "./sso/ssoRedirect.component";
import { getKeyVaultContent } from "./util/cmsService";
import { FUNCTION_KEY_REQUESTBODY } from "./util/keyVaultNames";
import { getUserProfile } from "./util/userService";
import { SiteStatusesContext } from "./context/SiteStatusesContext";
import { SiteStatusBanners } from "./signIn/siteStatusesBanners/siteStatusesBanners.component";
import { RolesPermissionContext } from "./context/RolesPermissionContext";
import { mapFunctionKeyData } from "./functionKey/functionKey.Mapper";
import { encrypt } from "./util/encryptDecrypt";
import { showVacOrderMenuOption } from "./util/utilityFunctions";
import FacilitySupplyOrdersContainer from "./components/facilitySupplyOrders/faciliitySupplyOrdersContainer/facilitySupplyOrders.container";

const AppWithRouterAccess = () => {
  const {
    isLoggedIn,
    setIsLoggedIn,
    setUserName,
    termsOfUseAccepted,
    setTermsOfUseAccepted,
    isInternalUser,
    registeredFaciltyAddress,
    setuserRolePermissionData,
    setregisteredFaciltyAddress,
    setAllFacility,
    setsideNavMenuOptionModelData,
    setuserRolesAndPermissionLoaded,
    setIsInternalUser,
    userProfile,
    setUserProfile,
    setDeepLinkPath,
    deepLinkPath,
  } = useContext(AuthContext);

  const { mappedRolesPermissionData, setMappedRolesPermissionData } =
    useContext(RolesPermissionContext);
  const { fullScreenSiteStatues, setFullScreenSiteStatues } =
    useContext(SiteStatusesContext);
  const [loaderSpinner, setloaderSpinner] = useState(false);
  const history = useHistory();
  const location = useLocation();
  if (
    deepLinkPath === null &&
    location.pathname !== "/" &&
    location.pathname.includes("deepLink")
  ) {
    sessionStorage.setItem("DeepLinkPath", location.pathname);
    setDeepLinkPath(location.pathname);
  }

  const oktaAuth1 = new OktaAuth(config.oidc); // config for External
  const oktaAuth2 = new OktaAuth(config.oidc2); // config for Intenal

  const onAuthRequired = () => {
    history.push("/");
  };
  const Getlicense = async () => {
    const body = {
      SecretNames: FUNCTION_KEY_REQUESTBODY,
    };
    const licenseResponse = await getKeyVaultContent(body);
    if (licenseResponse?.succeeded) {
      const mapper = await mapFunctionKeyData(licenseResponse);
      const encryptedData = encrypt(JSON.stringify(mapper));
      sessionStorage.setItem("keys", encryptedData);
      LicenseManager.setLicenseKey(licenseResponse?.item[0].value);
    }
  };
  useEffect(() => {
    Getlicense();
    if (
      isLoggedIn &&
      (location.pathname === "/manageProfile" ||
        location.pathname === "/termsOfUse" ||
        location.pathname.includes("/cmsContent"))
    ) {
      getUser();
    }
  }, [isLoggedIn]);

  setInterval(async () => {
    // Renew 60 seconds before expiry
    if (localStorage.getItem("isInternalUserFromLocal") !== "true") {
      if (
        oktaAuth1.tokenManager.getTokensSync()?.accessToken?.claims?.exp &&
        Math.round(new Date().getTime() / 1000) >
          oktaAuth1.tokenManager.getTokensSync()?.accessToken?.claims?.exp - 60
      ) {
        // oktaAuth1.tokenManager.renew("accessToken");

        const renewToken = await oktaAuth1.token.renewTokens();
        await oktaAuth1.tokenManager.setTokens(renewToken);
      }
    } else {
      if (
        oktaAuth2.tokenManager.getTokensSync()?.accessToken?.claims?.exp &&
        Math.round(new Date().getTime() / 1000) >
          oktaAuth2.tokenManager.getTokensSync()?.accessToken?.claims?.exp - 60
      ) {
        // oktaAuth2.tokenManager.renew("accessToken");
        const renewToken = await oktaAuth2.token.renewTokens();
        await oktaAuth2.tokenManager.setTokens(renewToken);
      }
    }
  }, 15e3);

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "", window.location.origin));
  };
  const searchFacilityAddr = async (data) => {
    let linkedFacilities;
    let manualFacilities;
    if (
      (data?.facilities.length > 0 && registeredFaciltyAddress === undefined) ||
      registeredFaciltyAddress?.facilityAddressID === undefined
    ) {
      setuserRolePermissionData(data && data.userPermissions);
      linkedFacilities =
        data && data.facilities.filter((x) => x.mode === FacilityMode.LINKED);
      if (linkedFacilities && linkedFacilities.length < 1) {
        manualFacilities =
          data && data.facilities.filter((x) => x.mode === FacilityMode.MANUAL);
      }
      if (linkedFacilities && linkedFacilities.length > 0) {
        var facilitySearchRequest = {
          customerNumber: linkedFacilities[0].number,
          customerName: "",
          postalCode: "",
        };
        const searchedFacilities = await searchFacility(facilitySearchRequest);
        setAllFacility(searchedFacilities);
        const facilityRes =
          searchedFacilities &&
          searchedFacilities.filter(
            (x) => x.siteUseId === linkedFacilities[0].siteUseId?.toString()
          )[0];
        let registeredFaciltyAddress = {
          ...facilityRes,
          facilityAddressID: linkedFacilities[0].facilityAddressID,
          careGiverId: linkedFacilities[0].careGiverId,
        };
        setregisteredFaciltyAddress(registeredFaciltyAddress);
        setsideNavMenuOptionModelData((dt) => ({
          ...dt,
          orders: {
            ...dt.orders,
            isVisible: showVacOrderMenuOption(rolesPermissionRes),
          },
          inventory: {
            ...dt.inventory,
            isVisible: rolesPermissionRes.IsShowInventoryOption,
          },
          administration: {
            ...dt.administration,
            isVisible: rolesPermissionRes.IsShowAdminstrationOption,
          },
        }));
        setuserRolesAndPermissionLoaded(true);

        // set Permission
        const rolesPermissionRes = await mapUserRolesAndPermissionData(
          data && data.userPermissions,
          registeredFaciltyAddress?.readyCareFlag
        );
        setMappedRolesPermissionData(rolesPermissionRes);

        setuserRolesAndPermissionLoaded(true);
      } else {
        const rolesPermissionRes = await mapUserRolesAndPermissionData(
          data && data.userPermissions,
          "N"
        );
        setMappedRolesPermissionData(rolesPermissionRes);
        setsideNavMenuOptionModelData((dt) => ({
          ...dt,
          orders: {
            ...dt.orders,
            isVisible: showVacOrderMenuOption(rolesPermissionRes),
          },
          inventory: {
            ...dt.inventory,
            isVisible: rolesPermissionRes.IsShowInventoryOption,
          },
          administration: {
            ...dt.administration,
            isVisible: rolesPermissionRes.IsShowAdminstrationOption,
          },
        }));
        setuserRolesAndPermissionLoaded(true);
      }
    }

    return null;
  };
  const getUser = async () => {
    setloaderSpinner(true);
    const user = await getUserProfile();
    if (user !== undefined) {
      setUserProfile(user);
      await searchFacilityAddr(user);
    } else {
      setloaderSpinner(false);
    }
  };

  const helpSupport = (
    <div className="appWithRouter">
      <div className="helpSectionAtSignup">
        <div className="helpComponent">
          <HelpAndSupport />
        </div>
      </div>
    </div>
  );

  const education = !isLoggedIn ? (
    <div className="appWithRouter">
      <div className="educationAtSignup">
        <div className="educationComponent">
          <Education />
        </div>
      </div>
    </div>
  ) : null;

  const educationVideoLibrary = !isLoggedIn ? (
    <div className="appWithRouter">
      <div className="educationAtSignup">
        <div className="educationComponent">
          <VideoLibrary />
        </div>
      </div>
    </div>
  ) : null;

  const productsAndSolutions = !isLoggedIn ? (
    <div className="appWithRouter">
      <div className="productAndSolutionAtSignup">
        <div className="productAndSolutionComponent">
          <ProductsAndSolutions />
        </div>
      </div>
    </div>
  ) : null;

  const productDetail = !isLoggedIn ? (
    <div className="appWithRouter">
      <div className="productAndSolutionAtSignup">
        <div className="productAndSolutionComponent">
          <ProductDetail />
        </div>
      </div>
    </div>
  ) : null;

  const handleCloseButton = (index) => {
    if (fullScreenSiteStatues) {
      let fullScreenSiteStatuesTemp = [...fullScreenSiteStatues];
      fullScreenSiteStatuesTemp.splice(index, 1);
      setFullScreenSiteStatues(fullScreenSiteStatuesTemp);
    }
  };

  const TermsOfUsehelpSupport =
    isLoggedIn && !termsOfUseAccepted
      ? helpSupport
      : !isLoggedIn || location.pathname === "/ssoRedirect"
      ? helpSupport
      : null;
  const manageProfile = isLoggedIn ? (
    <>
      <div className="managedProfileappRouter">
        {mappedRolesPermissionData && !mappedRolesPermissionData.IsBaseRole && (
          <FacilityBanner />
        )}
        <div className="alertInPageContainerInPage">
          {fullScreenSiteStatues && fullScreenSiteStatues.length > 0 && (
            <SiteStatusBanners
              siteStatuses={fullScreenSiteStatues}
              handleCloseButtonAction={handleCloseButton}
            />
          )}
        </div>
        <div className="manageProfileAtfterLogin">
          <div className="profileComponent">
            {localStorage.getItem("isComingFromSSO") !== "true" &&
              registeredFaciltyAddress && (
                <Navigator
                  array={[
                    {
                      route: "/home",
                      pageName: "My Patients",
                    },
                  ]}
                  className="manage-profile-route-section"
                  title="Manage Profile"
                />
              )}
            <ManageProfile />
          </div>
        </div>
      </div>
    </>
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const woundAssessmentReportfullwindow = isLoggedIn ? (
    <>
      <div className="woundAssessmentappRouter">
        <div className="alertInPageContainerInPage">
          {fullScreenSiteStatues && fullScreenSiteStatues.length > 0 && (
            <SiteStatusBanners
              siteStatuses={fullScreenSiteStatues}
              handleCloseButtonAction={handleCloseButton}
            />
          )}
        </div>
        <div className="woundAssessmentReportLogin">
          <div className="woundAssessmentReportComponent">
            <WoundAssessmentReport />
          </div>
        </div>
      </div>
    </>
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const orderOverviewRoute = isLoggedIn ? (
    <div className="unlinked-order-overview">
      <FacilityBanner />
      <div className="alertInPageContainerInPage">
        {fullScreenSiteStatues && fullScreenSiteStatues.length > 0 && (
          <SiteStatusBanners
            siteStatuses={fullScreenSiteStatues}
            handleCloseButtonAction={handleCloseButton}
          />
        )}
      </div>
      <OrderOverview />
    </div>
  ) : (
    <Route>
      <Redirect to="/home" />
    </Route>
  );
  const internalUsersManageProfile = isLoggedIn ? (
    <div className="internal-managedProfileappRouter">
      <div>
        {localStorage.getItem("isComingFromSSO") !== "true" &&
          mappedRolesPermissionData &&
          !mappedRolesPermissionData.IsBaseRole && <FacilityBanner />}
      </div>
      <div className="internal-manageProfileAtfterLogin">
        <div className="internal-profileComponent">
          {<InternalUsersManageProfile />}
        </div>
      </div>
    </div>
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const manageAccount = isLoggedIn ? (
    <div className="managedProfileappRouter">
      <div className="manageProfileAtfterLogin">
        <div className="profileComponent">
          <ManageAccount />
        </div>
      </div>
    </div>
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  const registration =
    process.env.REACT_APP_ENV_HIDE_PAGE === "false" ? (
      <Registration />
    ) : (
      <Route>
        <Redirect to="/" />
      </Route>
    );

  const signup =
    process.env.REACT_APP_ENV_HIDE_PAGE === "false" ? (
      <Signup />
    ) : (
      <Route>
        <Redirect to="/" />
      </Route>
    );

  const success = (
    <div className="managedProfileappRouter">
      <SaveSuccessfull
        returnButtonText={isLoggedIn ? "Return home" : "Login"}
        returnToPath={
          isInternalUser && registeredFaciltyAddress === undefined
            ? "/ssoRedirect"
            : isLoggedIn
            ? "/home"
            : "/"
        }
      />
    </div>
  );

  const sysreq = isLoggedIn ? null : (
    <div className="appWithRouter">
      <div className="helpSectionAtSignup">
        <div className="helpComponent">
          <SystemRequirements />
        </div>
      </div>
    </div>
  );

  const facilitySupplyOrdersScreen = isLoggedIn ? (
    <>
      <div className="facilitySupplyOrdersAppRouter">
        {mappedRolesPermissionData && !mappedRolesPermissionData.IsBaseRole && (
          <FacilityBanner />
        )}
        <div className="alertInPageContainerInPage">
          {fullScreenSiteStatues && fullScreenSiteStatues.length > 0 && (
            <SiteStatusBanners
              siteStatuses={fullScreenSiteStatues}
              handleCloseButtonAction={handleCloseButton}
            />
          )}
        </div>
        <div className="facilitySupplyOrdersMinDiv">
          <div className="facilitySupplyOrdersComponent">
            {registeredFaciltyAddress &&
              !registeredFaciltyAddress.isPostAcute && (
                <FacilitySupplyOrdersContainer />
              )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <Route>
      <Redirect to="/" />
    </Route>
  );

  useEffect(() => {
    setTermsOfUseAccepted(false);
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails);
    const accessToken = data?.accessToken;
    if (accessToken) {
      setIsLoggedIn(true);
      setUserName(data?.idToken?.claims?.preferred_username);
    } else {
      setUserName("");
      setIsLoggedIn(false);
    }
    const TermsOfUse = localStorage.getItem("eulaAcceptedDate");
    if (TermsOfUse === "null") {
      setTermsOfUseAccepted(false);
    } else {
      setTermsOfUseAccepted(true);
    }
  }, [location.pathname]);

  function handlePreviousClick(path) {
    window.open(path, "_self");
  }
  function BlankScreenErrorInfo({ error, resetErrorBoundary }) {
    return (
      <div className="genericErrorMain">
        <div className="genericErrorSubDiv">
          <div className="genericErrorTextContent">
            <p className="genericErrorStaticTextTitle">
              Oops,something went wrong
            </p>
            <p className="genericErrorStaticText">
              The resource you are looking for might have been removed, had its
              name changed, or is temporarily unavailable.
            </p>
            <p className="genericErrorStaticText">
              If you have questions or issues, please contact our 3M™ Express
              Therapy Portal Support team at{" "}
              <a
                className="phone-value"
                data-testid="phone-value"
                href={`tel:+18002754524`}
              >
                1-800-275-4524
              </a>{" "}
              <span>ext 41858.</span>
            </p>
            <div className="genericErrorButtonMain">
              {" "}
              <ExpressButton
                clickHandler={() => handlePreviousClick("/home")}
                variant="text"
                parentClass="genericErrorStaticButton"
              >
                Return to Home Page
              </ExpressButton>
              <ExpressButton
                clickHandler={() => handlePreviousClick("/helpAndSupport")}
                variant="text"
                parentClass="genericErrorStaticButton"
              >
                Visit Help
              </ExpressButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <ErrorBoundary
        FallbackComponent={BlankScreenErrorInfo}
        onError={() => {
          localStorage.setItem("IsError", true);
        }}
      >
        <Security
          oktaAuth={
            localStorage.getItem("isInternalUserFromLocal") !== "true"
              ? oktaAuth1
              : oktaAuth2
          }
          restoreOriginalUri={restoreOriginalUri}
          onAuthRequired={onAuthRequired}
        >
          {!location.pathname.includes("/forgot") &&
            !location.pathname.includes("/email") && <Header />}
          <Route
            path="/"
            exact
            render={() => (
              <Login
                issuer={`${process.env.REACT_APP_BASE_URL}oauth2/default`}
              />
            )}
          />
          <Route path="/education" exact>
            {education}
          </Route>
          <Route path="/education/videoLibrary" exact>
            {educationVideoLibrary}
          </Route>
          <Route path="/productsAndSolutions" exact>
            {productsAndSolutions}
          </Route>
          <Route path="/productsAndSolutions/productDetail" exact>
            {productDetail}
          </Route>
          <Route path="/ssoRedirect" exact component={SSORedirect} />
          <Route path="/termsOfUse" exact component={TermsOfUse} />
          <Route path="/blank" exact component={Blank} />
          <Route path="/botSuspected" exact component={CaptchaFail} />
          <Route path="/signup" exact>
            {signup}
          </Route>
          <Route path="/registration" exact>
            {registration}
          </Route>
          <Route path="/login/callback" component={LoginCallback} />
          <Route path="/cmsContent/:type" component={CMSContent} />
          <Route path="/helpAndSupport">{TermsOfUsehelpSupport}</Route>
          <Route path="/unlinkOrderOverview">{orderOverviewRoute}</Route>
          <Route path="/woundAssessmentReport" exact>
            {woundAssessmentReportfullwindow}
          </Route>
          <Route path="/orders/facilitySupplyOrders" exact>
            {facilitySupplyOrdersScreen}
          </Route>
          {termsOfUseAccepted && (
            <Route path="/manageProfile">{manageProfile}</Route>
          )}
          {termsOfUseAccepted && (
            <Route path="/internalUsersManageProfile">
              {internalUsersManageProfile}
            </Route>
          )}
          <Route path="/success">{success}</Route>
          <Route path="/sysreq">{sysreq}</Route>
          <Route path="/forgotPassword" component={ForgotCredentialParent} />
          {/* commented as part of DWCX-34050 
         <Route path="/forgotUsername" component={ForgotUsername} />  */}
          <Route path="/emailSent" component={EmailSent} />
        </Security>
        {termsOfUseAccepted &&
          location.pathname !== "/" &&
          location.pathname !== "/signup" &&
          location.pathname !== "/registration" &&
          location.pathname !== "/manageProfile" &&
          location.pathname !== "/woundAssessmentReport" &&
          location.pathname !== "/orders/facilitySupplyOrders" &&
          location.pathname !== "/internalUsersManageProfile" &&
          location.pathname !== "/success" &&
          location.pathname !== "/blank" &&
          location.pathname !== "/forgotPassword" &&
          location.pathname !== "/termsOfUse" &&
          !location.pathname.includes("/cmsContent") &&
          location.pathname !== "/botSuspected" &&
          location.pathname !== "/ssoRedirect" &&
          location.pathname !== "/emailSent" &&
          location.pathname !== "/unlinkOrderOverview" && <Page />}
      </ErrorBoundary>
    </>
  );
};

export default AppWithRouterAccess;
