import { Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import {
  ISalesRoundingPatientDetails,
  IWoundInformation,
  IWoundInformationDropDownValues,
} from "../../salesRoundingTool.interface";
import "./srtWoundInformation.css";
import { ReactComponent as Quantityplusactive } from "../../../../assets/quantityplusactive.svg";
import { ReactComponent as Quantityminusactive } from "../../../../assets/quantityminusactive.svg";
import { ReactComponent as Minusbuttondisabled } from "../../../../assets/minusbuttondisabled.svg";
import { ReactComponent as Plusbuttondisabled } from "../../../../assets/plusbuttondisabled.svg";
import {
  IMobileDisplayContext,
  MobileDisplayContext,
} from "../../../../context/MobileDisplayContext";
import { useContext, useEffect, useState } from "react";
import WoundCard from "./woundCard/woundCard.component";
import { getDeepClone } from "../../../../util/ObjectFunctions";
import {
  defaultPrimaryWoundInfo,
  defaultSecondaryWoundInfo,
} from "../../salesRoundingTool.model";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { defaultWoundInfoDropDownValues } from "../../../../components/inpatientOrder/components/woundInformation/woundInformation.model";
import { format } from "react-string-format";
import { getDropDownContent } from "../../../../util/dropDownService";
import {
  DD_WOUND_DIRECTION,
  DD_ACUTE_WOUND_LOCATION,
  DD_WOUND_ORIENTATION,
  DD_ACUTE_WOUND_TYPE,
} from "../../../../util/staticText";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import { Popup } from "../../../../core/popup/popup.component";

interface ISrtWoundInformation {
  data: ISalesRoundingPatientDetails;
  setData: Function;
  woundInfoData: IWoundInformation;
}
export const SrtWoundInformation = ({
  data,
  setData,
  woundInfoData,
}: ISrtWoundInformation) => {
  const { isMobileScreen } =
    useContext<IMobileDisplayContext>(MobileDisplayContext);
  const [woundInfoDropDownValues, setWoundInfoDropDownValues] =
    useState<IWoundInformationDropDownValues>(defaultWoundInfoDropDownValues);
  const [loader, setLoader] = useState<boolean>(false);
  const woundInfoCountValue = Number(woundInfoData.woundInfoCount.value);
  const isPlusBtnDisable = woundInfoCountValue === 2;
  const isMinusBtnDisable = woundInfoCountValue === 0;
  const isShowPrimaryWoundInfo =
    woundInfoCountValue === 1 || woundInfoCountValue > 1;
  const isShowSecondaryWoundInfo = woundInfoCountValue === 2;
  const handlePlusOrMinusQty = (isToIncrease: boolean) => {
    let updatedCount = isToIncrease
      ? woundInfoCountValue + 1
      : woundInfoCountValue - 1;
    let tempData = woundInfoData;
    tempData = {
      ...tempData,
      woundInfoCount: {
        ...tempData?.woundInfoCount,
        value: String(updatedCount),
      },
    };
    if (!isToIncrease) {
      if (isShowSecondaryWoundInfo) {
        tempData.secondaryWoundInformation = getDeepClone(
          defaultSecondaryWoundInfo
        );
      } else if (isShowPrimaryWoundInfo) {
        tempData.primaryWoundInformation = getDeepClone(
          defaultPrimaryWoundInfo
        );
      }
    }
    setData({
      ...data,
      patientWoundInformation: {
        ...data.patientWoundInformation,
        valid: ValidationStatus.VALID,
        value: tempData,
      },
    });
  };

  const getDropDownValues = async () => {
    const ddContent = format(
      "{0},{1},{2},{3}",
      DD_WOUND_DIRECTION,
      DD_ACUTE_WOUND_LOCATION,
      DD_WOUND_ORIENTATION,
      DD_ACUTE_WOUND_TYPE
    );
    setLoader(true);
    const dropDownResponse = await getDropDownContent(ddContent);
    setLoader(false);
    let locationsArr: string[] = [];
    let directionsArr: string[] = [];
    let orientationsArr: string[] = [];
    let woundTypeArr: string[] = [];
    dropDownResponse.items.forEach((x: any) => {
      if (x.name === DD_ACUTE_WOUND_LOCATION) {
        locationsArr = x.data.map((x: any) => x.text);
      } else if (x.name === DD_WOUND_DIRECTION) {
        directionsArr = x.data.map((x: any) => x.text);
      } else if (x.name === DD_WOUND_ORIENTATION) {
        orientationsArr = x.data.map((x: any) => x.text);
      } else if (x.name === DD_ACUTE_WOUND_TYPE) {
        woundTypeArr = x.data.map((x: any) => x.text);
      }
    });
    setWoundInfoDropDownValues({
      woundDirectionDropDownValues: directionsArr,
      woundLocationDropDownValues: locationsArr,
      woundOrientationDropDownValues: orientationsArr,
      woundTypeDropDownValues: woundTypeArr,
    });
  };

  useEffect(() => {
    getDropDownValues();
  }, []);

  return (
    <Grid container className="srt-wound-info-main-div">
      <Grid item xs={isMobileScreen ? 12 : 8}>
        <Typography
          className="srt-wound-info-card-header"
          data-testid="srt-wound-info-card-header"
        >
          Wound Information (optional)
        </Typography>
        <Stack className="srt-wound-info-counter-div">
          <Typography
            data-testid="srt-wound-info-card-sub-header"
            className="srt-wound-info-card-sub-header"
          >
            Number of wounds
          </Typography>
          <Stack className="srt-wound-info-qty-btn-div">
            <IconButton
              onClick={() => handlePlusOrMinusQty(false)}
              disabled={isMinusBtnDisable}
              data-testid="srt-wound-info-qty-minus-icon-btn"
            >
              {isMinusBtnDisable ? (
                <Minusbuttondisabled />
              ) : (
                <Quantityminusactive />
              )}
            </IconButton>
            <TextField
              size="small"
              className="quantityInput in-patient-qty-input"
              name="quantityInput"
              value={woundInfoData.woundInfoCount.value}
              inputProps={{
                "data-testid": "srt-wound-info-qty-input",
              }}
              placeholder="0"
            />
            <IconButton
              disabled={isPlusBtnDisable}
              onClick={() => handlePlusOrMinusQty(true)}
              data-testid="srt-wound-info-qty-plus-icon-btn"
            >
              {isPlusBtnDisable ? (
                <Plusbuttondisabled />
              ) : (
                <Quantityplusactive />
              )}
            </IconButton>
          </Stack>
        </Stack>
      </Grid>
      <Grid xs={12}>
        {isShowPrimaryWoundInfo && (
          <WoundCard
            data={data}
            isPrimary={true}
            setData={setData}
            woundInfoDropDownValues={woundInfoDropDownValues}
            woundInfoData={woundInfoData.primaryWoundInformation}
          />
        )}
        {isShowSecondaryWoundInfo && (
          <WoundCard
            data={data}
            isPrimary={false}
            setData={setData}
            woundInfoDropDownValues={woundInfoDropDownValues}
            woundInfoData={woundInfoData.secondaryWoundInformation}
          />
        )}
      </Grid>
      <Popup
        openFlag={loader}
        closeHandler={() => {}}
        hideCloseButton={true}
        dialogParentClass="sales-rounding-dashboard-loader-popup"
      >
        <div className="sales-rounding-dashboard-loader">
          <LoadingSpinner />
        </div>
      </Popup>
    </Grid>
  );
};
