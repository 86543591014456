import "./patientInformation.css";
import {
  convertStringToDate,
  getCodeFromText,
  getTextFromCode,
  getValuePostUPSValidation,
} from "../../../util/utilityFunctions";
import InputMask from "react-input-mask";
import {
  NewOrderContext,
  NewOrderContextType,
} from "../../../context/NewOrderContext";
import { INewOrder } from "../newOrder.interface";
import { NewOrderValidator } from "../newOrder.validator";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useContext, useEffect, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Box, Grid, InputBase, TextField, Tooltip } from "@mui/material";
import { ValidateUPSAddressInfo } from "./validateUPSAddressInfo.component";
import {
  IInputField,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import { validateUpsAddress } from "../../../util/validateUpsAddressService";
import { ReactComponent as CalendarIcon } from "../../../assets/calendar.svg";
import { IAddress, IDuplicateRO, IPatientInformation } from "./patientInformation.interface";
import { InputWithLabel } from "../../../core/inputWithLabel/inputWithLabel.component";
import { PatientInfoReviewOrder } from "./reviewOrder/patientInfoReviewOrder.component";
import { CustomAutoComplete } from "../../../core/autoCompleteDropdown/autoCompleteDropdown.component";
import { checkIfDuplicateOrder } from "../../../util/vacOrderService";
import { ERROR_MSG_DUPLICATE_CHECK_FAILED } from "../../../util/errorMsg";
import moment from "moment";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { Popup } from "../../../core/popup/popup.component";
import { NewOrderPageSection } from "../NewOrderContainer.enum";

export const PatientInformation = ({
  data,
  Validator = new NewOrderValidator(),
  setData,
  states,
  statesText,
  vacTherapyInformationData,
  isReviewOrder = false,
  editButtonClicked,
  isOrderSummary = false,
  setIsDuplicateOrder,
  setDuplicateRODetails,
  setErrorPopUpFlag,
  setErrorMessage,
  setErrorCode
}: IPatientInformation) => {
  const [message, setMessage] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const [address, setAddress] = useState<IAddress | null>();
  const [noAddressFound, setNoAddressFound] = useState(false);
  const [selectedUPSAddress, setSelectedUPSAddress] = useState("");
  const [validator] = useState<NewOrderValidator>(Validator!);
  const [validateAddress, setValidateUPSAddress] = useState<any>([]);
  const [focusClasses, setFocusClasses] = useState({
    phone: "",
    dob: "",
  });
  const newOrderObj = useContext<NewOrderContextType | null>(NewOrderContext);
  const [duplicateCheckLoader, setDuplicateCheckLoader] = useState(false);

  const validateUPSAddress = async (obj: INewOrder) => {
    if (
      obj.address1.valid === ValidationStatus.VALID &&
      obj.city.valid === ValidationStatus.VALID &&
      obj.state.valid === ValidationStatus.VALID &&
      obj.zip.valid === ValidationStatus.VALID &&
      obj.address2.valid === ValidationStatus.VALID
    ) {
      document.getElementById("patientCurrentAddressPhone")?.focus();
      const reqParams = {
        AddressLine1: obj.address1.value.trim(),
        AddressLine2: obj.address2.value.trim(),
        City: obj.city.value.trim(),
        State: obj.state.value,
        zipCode: obj.zip.value,
      };
      const address: IAddress = {
        address1: obj.address1.value.trim(),
        address2: obj.address2.value.trim(),
        city: obj.city.value.trim(),
        state: obj.state.value,
        zip: obj.zip.value,
      };
      setAddress(address);
      setOpenAddress(true);
      setIsLoading(true);
      const response = await validateUpsAddress(reqParams);
      if (response && response.item) {
        if (response.item.status !== "ExactMatch") {
          if (response.item.addresses.length > 0) {
            setNoAddressFound(false);
            setValidateUPSAddress(response.item.addresses);
            if (
              JSON.stringify(response.item.addresses[0]) ===
              JSON.stringify(reqParams)
            ) {
            } else {
              setSelectedUPSAddress(JSON.stringify(response.item.addresses[0]));
            }
          } else {
            setSelectedUPSAddress("");
            setMessage("No address found.");
            setNoAddressFound(true);
          }
          setIsLoading(false);
        } else {
          validateIsDuplicateOrder(obj); 
          setOpenAddress(false);
        }
      } else {
        setMessage("Unable to process address validation. Please continue.");
        setIsLoading(false);
        setNoAddressFound(true);
      }
    }
  };

  const validateAndSetData = async (e: any) => {
    const { name, value } = e.target;
    if (
      name === "phone" &&
      data.phone.valid === ValidationStatus.UNTOUCHED &&
      (value === "(___) ___-____" || value === "")
    ) {
      return;
    }
    const isValid = validator.validate(value, name);
    const isEmpty = isValid!.updatedVal?.trim() === "";
    if (name === "phone") {
      newOrderObj?.setIsHandleChangeTriggered(!isEmpty);
    } else {
      newOrderObj?.setIsHandleChangeTriggered(true);
    }

    const inputObj: IInputField = {
      ...Object(data)[name],
      errorMessage: isEmpty ? Object(data)[name].errorMessage : null,
      valid: isEmpty
        ? isValid!.status
        : name === "zip" || name === "email" || name === "phone"
        ? isValid!.status
        : ValidationStatus.VALID,
      value: isValid!.updatedVal,
    };
    let tempData = {
      ...data,
      [name]: inputObj,
    };
    if (name === "phone") {
      if (data.IsSamePermanentAddress.value === "true") {
        tempData = {
          ...tempData,
          patientCurrentAddressPhone: {
            ...tempData.patientCurrentAddressPhone,
            errorMessage: null,
            valid: isValid!.status,
            value: value,
          },
        };
      }
      if (data.deliveryContactQuickFillType?.value === "1") {
        tempData = {
          ...tempData,
          deliveryContactPhone: {
            ...tempData.deliveryContactPhone,
            errorMessage: null,
            valid: isValid!.status,
            value: value,
          },
        };
      }
    }
    setData(tempData);
    if (name === "zip") {
      setTimeout(() => {
        validateUPSAddress(tempData);
      }, 50);
    }
  };

  const validateAndSetStateData = async (e: any, val: any) => {
    if (e !== null) {
      newOrderObj?.setIsHandleChangeTriggered(true);
    }
    const value = getCodeFromText(states, val);
    const isValid = validator.validate(value, "state");
    setData({
      ...data,
      state: {
        ...data.state,
        errorMessage: null,
        valid: isValid!.status,
        value: value,
      },
    });
  };

  const validateMinimumAndSetData = (e: any) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    const { name, value } = e.target;
    const isValid = validator.validate(value, name);
    const isEmpty = isValid!.updatedVal?.trim() === "";
    const tempData = {
      ...data,
      [name]: {
        ...Object(data)[name],
        errorMessage: null,
        valid: isEmpty ? isValid?.status : ValidationStatus.VALID,
        value: isValid!.updatedVal,
      },
    };
    setData(tempData);
  };

  const validateAndSetDate = (date: string | null | undefined) => {
    newOrderObj?.setIsHandleChangeTriggered(true);
    const formattedDate = date === null ? "" : convertStringToDate(date);
    const isValid = validator.validate(formattedDate!, "dob");
    const tempData = {
      ...data,
      dob: {
        ...data.dob,
        errorMessage: null,
        valid: isValid!.status,
        value: formattedDate,
      },
    };
    setData(tempData);
  };

  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };

  const handleAddressConitnueButton = () => {
    let upsValidateAddr;
    if (selectedUPSAddress.includes("enteredAddress")) {
      upsValidateAddr = JSON.parse(
        selectedUPSAddress.split("enteredAddress-")[1]
      );
    } else if (selectedUPSAddress) {
      upsValidateAddr = JSON.parse(selectedUPSAddress);
    }
    if (upsValidateAddr) {
      const tempData = {
        ...data,
        address1: {
          ...data.address1,
          valid: ValidationStatus.VALID,
          value: getValuePostUPSValidation(
            Validator,
            upsValidateAddr.addressLine1,
            "address1"
          ),
        },
        address2: {
          ...data.address2,
          valid: ValidationStatus.VALID,
          value:
            upsValidateAddr.addressLine2 === null
              ? ""
              : getValuePostUPSValidation(
                  Validator,
                  upsValidateAddr.addressLine2,
                  "address2"
                ),
        },
        city: {
          ...data.city,
          valid: ValidationStatus.VALID,
          value: getValuePostUPSValidation(
            Validator,
            upsValidateAddr.city,
            "cityValidation"
          ),
        },
        state: {
          ...data.state,
          valid: ValidationStatus.VALID,
          value: upsValidateAddr.state,
        },
        zip: {
          ...data.zip,
          valid: ValidationStatus.VALID,
          value: upsValidateAddr.zipCode,
        },
      };
      setData(tempData);
      validateIsDuplicateOrder(tempData);
    }
    setOpenAddress(false); 
  };

const validateIsDuplicateOrder = async(obj: INewOrder) =>
{
  if (
    obj.lastName.valid === ValidationStatus.VALID &&
    obj.dob.valid === ValidationStatus.VALID &&
    obj.zip.valid === ValidationStatus.VALID 
  ) {
    setDuplicateCheckLoader(true);
    const reqParams = {
      PatientLastName: obj.lastName.value.trim(),
      ZipCode: obj.zip.value,
      Dob: moment( obj.dob.value).format("yyyy-MM-DD"),
    };
    const response = await checkIfDuplicateOrder(reqParams);
    if(response && response?.item && response?.item?.roNumber)
    {
    let itemValue =
    {
      roNumber: response?.item.roNumber,
      createdDate: response?.item.createdDate
    }
    setDuplicateRODetails!(itemValue);
    setIsDuplicateOrder!(true);
    }
    else if(response && response.error)
    {
      setErrorMessage!(ERROR_MSG_DUPLICATE_CHECK_FAILED);
      setErrorPopUpFlag!(true);
      setErrorCode!(
        response?.error?.code || response?.status || response?.error?.errorCode
      );
    }
    setDuplicateCheckLoader(false);
  }
}
const returnToOrderEntry = () =>
  {
      setOpenAddress(false);
      validateIsDuplicateOrder(newOrderObj?.newOrderData!);
  }
   
  const duplicateCheckSpinner = () => {
    return (
      <div className="duplicate-check-loader">
        <LoadingSpinner />
      </div>
    );
  };

  return (
    <div className="patient-info-component">
      {!isReviewOrder && (
        <div className="patient-info">
          <div className="patient-info-header">
            <div className="patient-info-title-div">
              <h2 className="patient-info-title"> Patient Information</h2>
              <Tooltip
                classes={{ tooltip: "tooltip", popper: "popper" }}
                title={
                  <>
                    <div className="tooltip-content">
                      <div className="tooltip-header">
                        {vacTherapyInformationData &&
                          vacTherapyInformationData?.bubbleInfo[2]
                            ?.sectionHeader}
                      </div>
                      <div className="tooltip-body">
                        {vacTherapyInformationData &&
                          vacTherapyInformationData?.bubbleInfo[2]
                            ?.sectionContent}
                      </div>
                    </div>
                  </>
                }
              >
                <InfoOutlinedIcon
                  color="primary"
                  classes={{ root: "tooltipRoot" }}
                />
              </Tooltip>
            </div>
            <h5 className="patient-info-description">
              The patient's legal name and permanent residence.
            </h5>
          </div>
          <Box className="patient-info-box-container" sx={{ flexGrow: 1 }}>
            <Grid className="patient-info-grid-container" container spacing={2}>
              <Grid item md={6} xs={12}>
                <InputWithLabel
                  error={data.firstName.valid === ValidationStatus.INVALID}
                  id={data.firstName.componentId!}
                  isRequired={true}
                  isShowWarning={true}
                  label={data.firstName.title!}
                  testId="newOrder-First-Name"
                  warningMessage={data.firstName.errorMessage}
                >
                  <InputBase
                    autoFocus={
                      !isReviewOrder &&
                      data &&
                      data.firstName.valid === ValidationStatus.UNTOUCHED
                    }
                    className="patient-info-input"
                    id={data.firstName.id!}
                    name="firstName"
                    onChange={validateMinimumAndSetData}
                    value={data.firstName.value}
                  />
                </InputWithLabel>
              </Grid>
              <Grid item md={6} xs={12}>
                <InputWithLabel
                  error={data.lastName.valid === ValidationStatus.INVALID}
                  id={data.lastName.componentId!}
                  isRequired={true}
                  isShowWarning={true}
                  label={data.lastName.title!}
                  warningMessage={data.lastName.errorMessage}
                >
                  <InputBase
                    className="patient-info-input"
                    id={data.lastName.id!}
                    name="lastName"
                    onChange={validateMinimumAndSetData}
                    value={data.lastName.value}
                  />
                </InputWithLabel>
              </Grid>
            </Grid>
          </Box>
          <Box className="patient-info-box-container" sx={{ flexGrow: 1 }}>
            <Grid className="patient-info-grid-container" container spacing={2}>
              <Grid item md={6} xs={12}>
                <InputWithLabel
                  error={data.dob.valid === ValidationStatus.INVALID}
                  id={data.dob.componentId!}
                  isRequired={true}
                  isShowWarning={true}
                  label={data.dob.title!}
                  labelClassName={focusClasses.dob}
                  warningMessage={data.dob.errorMessage}
                >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      InputAdornmentProps={{
                        classes: {
                          root: "adornedRoot",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: `dob ${
                            data?.dob.valid === ValidationStatus.INVALID
                              ? "showError"
                              : "noError"
                          }`,
                          input: "input",
                          notchedOutline: "outline",
                        },
                      }}
                      components={{ OpenPickerIcon: CalendarIcon }}
                      value={data.dob.value}
                      onChange={(value) => validateAndSetDate(value)}
                      renderInput={(params) => {
                        params.error = false;
                        params.inputProps!.placeholder = "__/__/____";
                        return (
                          <TextField
                            id={data.dob.id!}
                            name="dob"
                            onFocus={(e) => setClasses(e, "Mui-focused")}
                            onBlur={(e) => setClasses(e, "")}
                            {...params}
                          />
                        );
                      }}
                    />
                  </LocalizationProvider>
                </InputWithLabel>
              </Grid>
              <Grid item md={6} xs={12}>
                <InputWithLabel
                  error={data.phone.valid === ValidationStatus.INVALID}
                  id={data.phone.componentId!}
                  isRequired={true}
                  isShowWarning={true}
                  label={data.phone.title!}
                  labelClassName={focusClasses.phone}
                  warningMessage={data.phone.errorMessage}
                >
                  <InputMask
                    className="phone"
                    id={data.phone.id!}
                    mask="(999) 999-9999"
                    name="phone"
                    onBlur={(e) => setClasses(e, "")}
                    onChange={validateAndSetData}
                    onFocus={(e) => setClasses(e, "Mui-focused")}
                    placeholder="(___) ___-____"
                    value={data.phone.value}
                    type="tel"
                    pattern="[0–9]*"
                  />
                </InputWithLabel>
              </Grid>
            </Grid>
          </Box>
          <Box className="patient-info-box-container" sx={{ flexGrow: 1 }}>
            <Grid className="patient-info-grid-container" container spacing={2}>
              <Grid item md={6} xs={12}>
                <InputWithLabel
                  error={data.address1.valid === ValidationStatus.INVALID}
                  id={data.address1.componentId!}
                  isRequired={true}
                  isShowWarning={true}
                  label={data.address1.title!}
                  warningMessage={data.address1.errorMessage}
                >
                  <InputBase
                    className="patient-info-input"
                    id={data.address1.id!}
                    name="address1"
                    onChange={validateAndSetData}
                    value={data.address1.value}
                  />
                </InputWithLabel>
              </Grid>
              <Grid item md={6} xs={12}>
                <InputWithLabel
                  error={data.address2.valid === ValidationStatus.INVALID}
                  id={data.address2.componentId!}
                  isRequired={false}
                  isShowTooltip={true}
                  isShowWarning={true}
                  label={data.address2.title!}
                  labelClassName="patient-address-line-2"
                  tooltipHeader={
                    vacTherapyInformationData &&
                    vacTherapyInformationData?.bubbleInfo[0]?.sectionHeader
                  }
                  tooltipContent={
                    vacTherapyInformationData &&
                    vacTherapyInformationData?.bubbleInfo[0]?.sectionContent
                  }
                  warningMessage={data.address2.errorMessage}
                >
                  <InputBase
                    className="patient-info-input"
                    id={data.address2.id!}
                    name="address2"
                    onChange={validateAndSetData}
                    value={data.address2.value}
                  />
                </InputWithLabel>
              </Grid>
            </Grid>
          </Box>
          <Box className="patient-info-box-container" sx={{ flexGrow: 1 }}>
            <Grid className="patient-info-grid-container" container spacing={2}>
              <Grid item md={6} xs={12}>
                <InputWithLabel
                  error={data.city.valid === ValidationStatus.INVALID}
                  id={data.city.componentId!}
                  isRequired={true}
                  isShowWarning={true}
                  label={data.city.title!}
                  warningMessage={data.city.errorMessage}
                >
                  <InputBase
                    className="patient-info-input"
                    id={data.city.id!}
                    name="city"
                    onChange={validateAndSetData}
                    value={data.city.value}
                  />
                </InputWithLabel>
              </Grid>
              <Grid item md={3} xs={12}>
                <InputWithLabel
                  error={data.state.valid === ValidationStatus.INVALID}
                  id={data.state.componentId!}
                  isRequired={true}
                  isShowWarning={true}
                  label={data.state.title!}
                  warningMessage={data.state.errorMessage}
                >
                  <CustomAutoComplete
                    handleChange={(e: any, val: any) =>
                      validateAndSetStateData(e, val)
                    }
                    id={data.state.id!}
                    menuItem={statesText}
                    selectClassName={
                      data?.state.valid === ValidationStatus.INVALID
                        ? "patient-info-state-input patient-info-error"
                        : "patient-info-state-input"
                    }
                    selectpropsClassName="patient-info-state-root"
                    value={
                      data.state.value
                        ? getTextFromCode(states, data.state.value)
                        : null
                    }
                  />
                </InputWithLabel>
              </Grid>
              <Grid item md={3} xs={12}>
                <InputWithLabel
                  error={data.zip.valid === ValidationStatus.INVALID}
                  id={data.zip.componentId!}
                  isRequired={true}
                  isShowWarning={true}
                  label={data.zip.title!}
                  warningMessage={data.zip.errorMessage}
                >
                  <InputBase
                    className="patient-info-input"
                    id={data.zip.id!}
                    name="zip"
                    onChange={validateAndSetData}
                    value={data.zip.value}
                    inputProps={{
                      maxLength: 5,
                      inputMode: "numeric",
                      pattern: "[0-9*]",
                    }}
                    type="text"
                  />
                </InputWithLabel>
              </Grid>
            </Grid>
          </Box>
          <Box className="patient-info-box-container" sx={{ flexGrow: 1 }}>
            <Grid className="patient-info-grid-container" container spacing={2}>
              <Grid item xs={12}>
                <InputWithLabel
                  error={data.email.valid === ValidationStatus.INVALID}
                  id={data.email.componentId!}
                  isRequired={false}
                  isShowWarning={true}
                  label={data.email.title!}
                  warningMessage={data.email.errorMessage}
                >
                  <InputBase
                    className="patient-info-input"
                    id={data.email.id!}
                    name="email"
                    onChange={validateAndSetData}
                    value={data.email.value}
                  />
                </InputWithLabel>
              </Grid>
            </Grid>
          </Box>
          <ValidateUPSAddressInfo
            address={address!}
            openAddress={openAddress}
            setOpenAddress={setOpenAddress}
            loading={loading}
            setSelectedUPSAddress={setSelectedUPSAddress}
            selectedUPSAddress={selectedUPSAddress}
            validateAddress={validateAddress}
            handleAddressConitnueButton={handleAddressConitnueButton}
            noAddressFound={noAddressFound}
            message={message}
            title=" Please confirm patient address "
            returnToOrderEntry={returnToOrderEntry}
          />
            {duplicateCheckLoader ? (
                  <Popup
                    hideCloseButton={true}
                    openFlag={duplicateCheckLoader}
                    closeHandler={() => {}}
                  >
                    {duplicateCheckSpinner()}
                  </Popup>
                ) : (
                  ""
                )}
        </div>
      )}
      {isReviewOrder && (
        <PatientInfoReviewOrder
          data={data}
          editButtonClicked={editButtonClicked}
          isOrderSummary={isOrderSummary}
        />
      )}
    </div>
  );
};
