import { CartProvider, useCart } from "./CartContext";
import { createContext, useRef, useState } from "react";
import {
  IDropDownContent,
  IInpatientSupplyOrder,
  IInPatientSupplyOrderProduct,
} from "../components/inpatientSupplyOrder/inpatientSupplyOrder.interface";
import { defaultInPatientSupplyOrder } from "../components/inpatientSupplyOrder/inpatientSupplyOrder.data";
import { InpatientSupplyOrderPageSection } from "../components/inpatientSupplyOrder/inpatientSupplyOrder.enum";
import {
  defaultFilterValues,
  IFilterValues,
} from "../components/inpatientSupplyOrder/container/inpatientSupplyOrderContainer.component";

export interface ICustomerInformation {
  creditStatus: string;
  billToSiteUseId: string;
  customerNumber: string;
}

export type InpatientSupplyOrderContextType = {
  data: IInpatientSupplyOrder;
  orderProgress: number;
  page: InpatientSupplyOrderPageSection;
  resetContext: () => void;
  setData: React.Dispatch<React.SetStateAction<IInpatientSupplyOrder>>;
  setOrderProgress: React.Dispatch<React.SetStateAction<number>>;
  setPage: React.Dispatch<
    React.SetStateAction<InpatientSupplyOrderPageSection>
  >;
  currentServerDate: any;
  setCurrentServerDate: React.Dispatch<React.SetStateAction<any>>;
  maxDate: string;
  setMaxDate: React.Dispatch<React.SetStateAction<string>>;
  inPatientSuppliesData: IInPatientSupplyOrderProduct[] | null;
  setInPatientSuppliesData: React.Dispatch<
    React.SetStateAction<IInPatientSupplyOrderProduct[] | null>
  >;
  productsFamilyTypes: string[];
  setProductsFamilyTypes: React.Dispatch<React.SetStateAction<string[]>>;
  filterValues: IFilterValues;
  setFilterValues: React.Dispatch<React.SetStateAction<IFilterValues>>;
  customerInformation: ICustomerInformation | null;
  setCustomerInformation: React.Dispatch<
    React.SetStateAction<ICustomerInformation | null>
  >;
  supplyDropDownList: IDropDownContent[];
  setSupplyDropDownList: React.Dispatch<
    React.SetStateAction<IDropDownContent[]>
  >;
  productFamilyTypes: IDropDownContent[];
  setProductFamilyTypes: React.Dispatch<
    React.SetStateAction<IDropDownContent[]>
  >;
  productFamilyArray: string[];
  setProductFamilyArray: React.Dispatch<React.SetStateAction<string[]>>;
  isCustomerOwnedDisabled: boolean;
  setIsCustomerOwnedDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

type InpatientSupplyOrderContextProviderProps = {
  children: React.ReactNode;
};

export const InpatientSupplyOrderContext =
  createContext<InpatientSupplyOrderContextType | null>(null);

export const InpatientSupplyOrderContextProvider = ({
  children,
}: InpatientSupplyOrderContextProviderProps) => {
  const [data, setData] = useState<IInpatientSupplyOrder>(
    defaultInPatientSupplyOrder
  );
  const [orderProgress, setOrderProgress] = useState<number>(20);
  const [selectedSupEditButtonClicked, setSelectedSupEditButtonClicked] =
    useState<boolean>(false);

  const [page, setPage] = useState<InpatientSupplyOrderPageSection>(
    InpatientSupplyOrderPageSection.LIST_PAGE
  );
  const [currentServerDate, setCurrentServerDate] = useState<any>(null);
  const [maxDate, setMaxDate] = useState<string>("");
  const [inPatientSuppliesData, setInPatientSuppliesData] = useState<
    IInPatientSupplyOrderProduct[] | null
  >(null);
  const [productsFamilyTypes, setProductsFamilyTypes] = useState<string[]>([]);
  const [filterValues, setFilterValues] =
    useState<IFilterValues>(defaultFilterValues);
  const [customerInformation, setCustomerInformation] =
    useState<ICustomerInformation | null>(null);
  const [supplyDropDownList, setSupplyDropDownList] = useState<
    IDropDownContent[]
  >([]);
  const [productFamilyTypes, setProductFamilyTypes] = useState<
    IDropDownContent[]
  >([]);
  const [productFamilyArray, setProductFamilyArray] = useState<string[]>([]);
  const { resetCart } = useCart();
  const [isCustomerOwnedDisabled, setIsCustomerOwnedDisabled] =
    useState<boolean>(false);

  const resetContext = () => {
    setData(defaultInPatientSupplyOrder);
    setOrderProgress(20);
    setPage(InpatientSupplyOrderPageSection.LIST_PAGE);
    resetCart();
    setCurrentServerDate(null);
    setMaxDate("");
    setInPatientSuppliesData(null);
    setProductsFamilyTypes([]);
    setFilterValues(defaultFilterValues);
    setCustomerInformation(null);
    setIsCustomerOwnedDisabled(false);
  };

  return (
    <InpatientSupplyOrderContext.Provider
      value={{
        data,
        orderProgress,
        page,
        resetContext,
        setData,
        setOrderProgress,
        setPage,
        currentServerDate,
        setCurrentServerDate,
        maxDate,
        setMaxDate,
        inPatientSuppliesData,
        setInPatientSuppliesData,
        productsFamilyTypes,
        setProductsFamilyTypes,
        filterValues,
        setFilterValues,
        customerInformation,
        setCustomerInformation,
        supplyDropDownList,
        setSupplyDropDownList,
        productFamilyTypes,
        setProductFamilyTypes,
        productFamilyArray,
        setProductFamilyArray,
        isCustomerOwnedDisabled,
        setIsCustomerOwnedDisabled,
      }}
    >
      {children}
    </InpatientSupplyOrderContext.Provider>
  );
};
