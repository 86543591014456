import { ISRTOrderInformation } from "../../salesRoundingTool.interface";
import { Grid, Typography } from "@mui/material";
import "./orderInformation.css";
import { ShowTitleAndValue } from "../../../../core/showTitleAndValue/showTitleAndValue.component";

interface ISRTOrderInformationProps {
  orderInformation: ISRTOrderInformation;
}

const OrderInformation = ({ orderInformation }: ISRTOrderInformationProps) => {
  return (
    <div
      className="sales-rounding-order-information-div"
      data-testid="sales-rounding-order-information-div"
      id="sales-rounding-order-information-div"
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            className="sales-rounding-order-information-header"
            data-testid="sales-rounding-order-information-header"
            id="sales-rounding-order-information-header"
          >
            Order
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <ShowTitleAndValue
            title={"Rental Order Number"}
            value={orderInformation.roNumber}
            testId={"roNumber"}
          />
        </Grid>{" "}
        <Grid item xs={6}>
          <ShowTitleAndValue
            title={"Placement Type"}
            value={orderInformation.placementType}
            testId={"placementType"}
          />
        </Grid>{" "}
        <Grid item xs={6}>
          <ShowTitleAndValue
            title={"Product"}
            value={orderInformation.product}
            testId={"product"}
          />
        </Grid>{" "}
        <Grid item xs={6}>
          <ShowTitleAndValue
            title={"Serial Number"}
            value={orderInformation.serialNumber}
            testId={"serialNumber"}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderInformation;
