import { AuthContextType } from "../../../../context/AuthContext";
import {
  ISalesRoundingPatientDetails,
  IWoundInformation,
} from "../../salesRoundingTool.interface";
import { IsalesRoundingRequest } from "./salesRounding.request.interface";
import { dischargeRequestAttest } from "../../../../components/pickUpAndDischargeRequest/dischargeRequest/reviewDischargeRequest/attestationSignature.model";
import {
  formatISODate,
  inPatientformatISODate,
} from "../../../../util/utilityFunctions";
import moment from "moment";

export const mapSalesRoundingRequest = async (
  data: ISalesRoundingPatientDetails,
  authObj: AuthContextType,
  selectedPatientData: any,
  isPatientDataUpdated: boolean,
  territoryName: string
) => {
  const woundInfo = data.patientWoundInformation.value as IWoundInformation;

  const getOrderType = (type: string) => {
    let orderType = "";
    switch (type) {
      case "StoredProduct":
        orderType = "3";
        break;
      case "CustomerPlus":
        orderType = "4";
        break;
      case "SolventumProduct":
        orderType = "5";
        break;
    }
    return parseInt(orderType);
  };
  let orderType = getOrderType(selectedPatientData?.type);
  let requestBody: IsalesRoundingRequest = {
    OrderID: selectedPatientData?.orderId ?? "",
    OrderType: orderType,
    roNumber: selectedPatientData?.roNumber,
    roPNumber: selectedPatientData?.ropNumber,
    patientFirstName: data?.patientInformation?.firstName?.value,
    patientId: data?.patientInformation?.patientId?.value,
    patientLastName: data?.patientInformation?.lastName?.value,
    patientDob: inPatientformatISODate(
      data?.patientInformation?.dateOfBirth?.value,
      data?.patientInformation?.dateOfBirth?.valid
    )!,
    roomNo: data?.patientInformation?.room?.value,
    salesRepID: authObj.userProfile!.employeeID
      ? authObj.userProfile!.employeeID
      : "INTERNET",
    employeeTitle: authObj?.userProfile?.title! ?? "",
    userType: authObj?.userProfile?.title! ?? "",
    purchaseOrderNumber:
      selectedPatientData?.purchaseOrderValue ?? "NOT REQUIRED",
    PrescribingPhysicianFirstName: data?.prescribingPhysician?.firstName ?? "",
    PrescribingPhysicianLastName: data?.prescribingPhysician?.lastName ?? "",
    PrescribingPhysicianNPI: data?.prescribingPhysician?.npi ?? "",
    attendingPhysicianFirstName: data?.attendingPhysician?.firstName ?? "",
    attendingPhysicianLastName: data?.attendingPhysician?.lastName ?? "",
    attendingPhysicianNPI: data?.attendingPhysician?.npi ?? "",
    leadGeneration: data?.therapyInformation?.leadGeneration.value ?? "",
    userName: authObj?.userProfile?.userName ?? "",
    productName: data?.orderInformation?.product,
    facilityName: selectedPatientData?.facilityName ?? "",
    serialNumber: data?.orderInformation?.serialNumber,
    placementType: data?.orderInformation?.placementType,
    territoryName: territoryName,
    dischargeStatus: data?.patientStatus?.dischargeStatus?.value ?? "",
    reasonForPatientNotTransitioningHome:
      data?.patientStatus?.notTransitioningHomeReason?.value ?? "",
    otherReasonPatientNotTransitioningHome:
      data?.patientStatus?.other?.value ?? "",
    transitionTherapyStartDate: inPatientformatISODate(
      data?.therapyInformation?.transitionTherapyStartDate?.value,
      data?.therapyInformation?.transitionTherapyStartDate?.valid
    ),
    isHeroDataUpdated:
      orderType === 4 ? false : isPatientDataUpdated ? true : false,
    primaryWoundLength:
      woundInfo?.primaryWoundInformation?.woundLength.value! ?? "",
    primaryWoundWidth:
      woundInfo?.primaryWoundInformation?.woundWidth.value! ?? "",
    primaryWoundDepth:
      woundInfo?.primaryWoundInformation?.woundDepth.value! ?? "",
    primaryWoundMeasurementDate: woundInfo?.primaryWoundInformation
      ?.woundMeasurementDate.value
      ? formatISODate(
          woundInfo?.primaryWoundInformation?.woundMeasurementDate.value,
          woundInfo?.primaryWoundInformation?.woundMeasurementDate.valid
        )
      : null,
    secondaryWoundLength:
      woundInfo?.secondaryWoundInformation?.woundLength.value! ?? "",
    secondaryWoundWidth:
      woundInfo?.secondaryWoundInformation?.woundWidth.value! ?? "",
    secondaryWoundDepth:
      woundInfo?.secondaryWoundInformation?.woundDepth.value! ?? "",
    secondaryWoundMeasurementDate: woundInfo?.secondaryWoundInformation
      ?.woundMeasurementDate.value
      ? formatISODate(
          woundInfo?.secondaryWoundInformation?.woundMeasurementDate.value,
          woundInfo?.secondaryWoundInformation?.woundMeasurementDate.valid
        )
      : null,
    primaryWoundType:
      woundInfo?.primaryWoundInformation?.woundType.value! ?? "",
    primaryWoundLocation:
      woundInfo?.primaryWoundInformation?.woundLocation.value! ?? "",
    primaryWoundDirection:
      woundInfo?.primaryWoundInformation?.woundDirection.value! ?? "",
    primaryWoundOrientation:
      woundInfo?.primaryWoundInformation?.woundOrientation.value! ?? "",
    secondaryWoundType:
      woundInfo?.secondaryWoundInformation?.woundType.value! ?? "",
    secondaryWoundLocation:
      woundInfo?.secondaryWoundInformation?.woundLocation.value! ?? "",
    secondaryWoundDirection:
      woundInfo?.secondaryWoundInformation?.woundDirection.value! ?? "",
    secondaryWoundOrientation:
      woundInfo?.secondaryWoundInformation?.woundOrientation.value! ?? "",
    numberOfWounds: parseInt(woundInfo?.woundInfoCount?.value) ?? null,
    serviceCovering:
      data?.serviceCoveringInformation?.serviceCovering?.value ?? "",
  };
  return requestBody;
};
