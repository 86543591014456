import {
  IInpatientOrder,
  IInpatientOrderSecondPage,
} from "./inpatientOrder.interface";
import { ValidationStatus } from "../../core/interfaces/input.interface";
import { defaultPrescriberInformationdata } from "./__test__/inpatientOrder.test.data";
import { defaultWoundInformation } from "./components/woundInformation/woundInformation.model";
import { defaultPatientInformationData } from "./components/patientInformation/patientInformation.model";
import { defaultBillingInformationData } from "./components/billingInformation/billingInformation.model";
import { defaultReplacementInformationData } from "./components/replacementInformation/replacementInformation.interface";
import { defaultDevicePlacementInformation } from "./components/devicePlacement/devicePlacement.model";
import { defaultDeliveryPreferenceData } from "./components/deliveryPreference/deliveryPreference.model";

export let defaultInpatientOrderData: IInpatientOrder = {
  patientInformation: {
    hasChildsToValidate: true,
    required: true,
    valid: ValidationStatus.UNTOUCHED,
    value: defaultPatientInformationData,
  },
  prescriberInformation: {
    componentId: "in-patient-order-prescriber-information",
    hasChildsToValidate: false,
    order: 6,
    required: false,
    title: "",
    valid: ValidationStatus.VALID,
    value: defaultPrescriberInformationdata,
  },
  devicePlacement: {
    hasChildsToValidate: true,
    required: true,
    valid: ValidationStatus.UNTOUCHED,
    value: defaultDevicePlacementInformation,
  },
  therapyStartDate: {
    componentId: "in-patient-order-therapy-start-date",
    id: "in-patient-order-therapy-start-date-input",
    order: 9,
    required: false,
    title: "Therapy Start Date",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  billingAddress: {
    componentId: "in-patient-order-billing-address",
    id: "in-patient-order-billing-address-input",
    order: 10,
    required: false,
    title: "With which bill-to address should this order be associated?",
    valid: ValidationStatus.UNTOUCHED,
    value: null,
  },
  patientWoundInformation: {
    order: 13,
    required: false,
    isDefaultValid: true,
    valid: ValidationStatus.VALID,
    value: defaultWoundInformation,
  },
  orderWithSolventum: {
    componentId: "inpatient-product-selection-main-div",
    order: 11,
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: null,
    title: "Which product will you be placing?",
    isShowWarning: false,
  },
  selectionSupplies: {
    componentId: "inpatient-product-selection-supplies",
    order: 12,
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
    title: "Will you be ordering supplies with this order?",
    isShowWarning: false,
  },
};

export let defaultInpatientOrderSecondPageData: IInpatientOrderSecondPage = {
  deliveryPreference: {
    hasChildsToValidate: true,
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: defaultDeliveryPreferenceData,
  },
  placementDate: {
    componentId: "in-patient-order-placement-date",
    id: "in-patient-order-placement-date-input",
    order: 1,
    required: true,
    title: "Placement Start Date",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  replacementInformation: {
    required: true,
    valid: ValidationStatus.UNTOUCHED,
    value: defaultReplacementInformationData,
    hasChildsToValidate: true,
  },
  billingInformation: {
    order: 20,
    required: false,
    isDefaultValid: true,
    valid: ValidationStatus.VALID,
    value: defaultBillingInformationData,
    hasChildsToValidate: true,
  },
};
