import Grid from "@mui/material/Grid";
import { useContext, useEffect, useState } from "react";
import { MobileDisplayContext } from "../../../../context/MobileDisplayContext";
import { CustomDropDown } from "../../../../core/customDropdown/customDropdown.component";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import {
  ValidationStatus
} from "../../../../core/interfaces/input.interface";
import { getdropDownContent } from "../../../../util/dropDownService";
import { DD_SRT_DISCHARGE_STATUS, DD_SRT_NOT_TRANSITIONING_TO_HOME_REASON } from "../../../../util/staticText";
import { ISRTPatientStatusProps } from "../../salesRoundingTool.interface";
import { SalesRoundingValidator } from "../../salesRoundingTool.validator";
import "./srtPatientStatus.css";
import { format } from "react-string-format";
import { getCodeFromText } from "../../../../util/utilityFunctions";
import { InputBase } from "@mui/material";
import { Validator } from "../../../../util/order.validations";
export const SrtPatientStatus = ({
  data,
  setData,
  patientStatus,
}: ISRTPatientStatusProps) => {
  const { isMobileScreen } = useContext(MobileDisplayContext);
  const [dischargeReasons, setDischargeReasons] = useState([]);
  const [dischargeReasonText, setDischargeReasonText] = useState([]);
  const [transitioningReason, setTransitioningReason] = useState([]);
  const [transitioningReasonText, setTransitioningReasonText] = useState([]);
  const validator = new SalesRoundingValidator();
  let  otherBoxVisibility = ['Other reason', 'Alternate therapy', 'Patient declined therapy'];
  
  const validateAndSetDischargeStatusData = async (e: any) => {
   const { name, value } = e.target;
     const isValid = validator.validate(value, "dischargeStatus");
     const tempData = {
      ...data,
      patientStatus: {
        ...data.patientStatus,
        dischargeStatus: {
          ...data.patientStatus.dischargeStatus,
          errorMessage: null,
          valid: isValid!.status,
          value: value,
        },
        notTransitioningHomeReason: {
          ...data.patientStatus.notTransitioningHomeReason,
          value:"",
          errorMessage: null,
          valid:ValidationStatus.UNTOUCHED,
          required: (parseInt(getCodeFromText(dischargeReasons,value)) > 1)
        },
        other: {
          ...data.patientStatus.other,
          value:"",
          errorMessage: null,
          valid:ValidationStatus.UNTOUCHED,
          required: false,
        },
      },
    };
    setData(tempData);
   };

   const validateAndSetData = async (e: any) => {
    const { name, value } = e.target;
      const isValid = validator.validate(value, name);
      var tempData=null;
      if(otherBoxVisibility.includes(value))
      {
        tempData = {
          ...data,
          patientStatus: {
            ...data.patientStatus,
            notTransitioningHomeReason: {
              ...data.patientStatus.notTransitioningHomeReason,
              errorMessage: null,
              valid: isValid!.status,
              required:true,
              value: value,
            },
            other: {
              ...data.patientStatus.other,
              errorMessage: null,
              value: "",
              valid: ValidationStatus.UNTOUCHED,
              required:true,
            },
          },
        };
      }
      else
      {
       tempData = {
       ...data,
       patientStatus: {
         ...data.patientStatus,
         [name]: {
          ...Object(data.patientStatus)[name],
           errorMessage: null,
           valid: isValid!.status,
           value: value,
           required:true
         },
         other: {
          ...data.patientStatus.other,
          errorMessage: null,
          value: "",
          valid: ValidationStatus.UNTOUCHED,
          required:false,
        }
       },
     };
    }
     setData(tempData);
    };

  const fetchDropdownContents = async () => {
    try {
      const ddContent = format(
        "{0},{1}",
        DD_SRT_DISCHARGE_STATUS,
        DD_SRT_NOT_TRANSITIONING_TO_HOME_REASON,
       
      );
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const reasonObject = data.items.filter(
          (item: { name: string }) => item.name === DD_SRT_DISCHARGE_STATUS
        );
        const reasonData = reasonObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setDischargeReasons(reasonData);
        setDischargeReasonText(reasonData.map((x: { text: string }) => x.text));

        const transitioningReasons = data.items.filter(
                  (item: { name: string }) => item.name === DD_SRT_NOT_TRANSITIONING_TO_HOME_REASON
                );
                const dataArray = transitioningReasons[0].data.sort(
                  (a: { order: number }, b: { order: number }) =>
                    a.order > b.order ? 1 : -1
                );
                setTransitioningReason(dataArray);
                setTransitioningReasonText(
                  dataArray.map((x: { text: string }) => x.text)
                );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

   const validateAndSetDataFreeFormText = (e: any) => {
      const isValid = validator.validate(e.target.value, "other");
      const tempData = {
        ...data,
        patientStatus: {
          ...data.patientStatus,
          other: {
           ...data.patientStatus.other,
            errorMessage: null,
            valid: isValid!.status,
            value: e.target.value,
            required:true
          },
        },
      };
      setData(tempData);
    };

  useEffect(() => {
    fetchDropdownContents();
  }, []);

  return (
    <div className="srtPatientStatus-info">
      <h2
        className="srtPatientStatus-info-title" data-testid="srtPatientStatus-info-title"
        id="srtPatientStatus-info-titleid"
      >
        Status
      </h2>
      <Grid
        className="srtPatientStatus-info-grid-container" container
      >
        <Grid
          className="srtPatientStatus-info-item" item xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={patientStatus.dischargeStatus.valid  === ValidationStatus.INVALID}
            id={patientStatus.dischargeStatus.componentId!}
            isRequired={patientStatus.dischargeStatus.required}
            isShowWarning={true}
            label={patientStatus.dischargeStatus.title!}
            labelClassName="srtStatus-type-title"
            testId={patientStatus.dischargeStatus.componentId!}
            warningMessage={patientStatus.dischargeStatus.errorMessage}
            isDropdown={true}
          >
            <CustomDropDown
               handleChange={validateAndSetDischargeStatusData}
               menuItem={dischargeReasonText}
               name="dischargeStatus"
               placeHolder="Select a Discharge Status"
               selectpropsClassName={
                 patientStatus.dischargeStatus.value ? "srtPatientStatus-info-select" : "placeHolder"
               }
               selectClassName={
                patientStatus.dischargeStatus.value ? "srtPatientStatus-info-input" : "placeHolder"
               }
               testId="dischargeStatus"
               value={patientStatus.dischargeStatus.value ? patientStatus.dischargeStatus.value : null}
              />
          </InputWithLabel>
        </Grid>
        { dischargeReasons && patientStatus?.dischargeStatus?.value != null && 
          parseInt(getCodeFromText(dischargeReasons, patientStatus.dischargeStatus.value)) > 1 && (
        <Grid
          className="srtPatientStatus-info-item" item xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={patientStatus.notTransitioningHomeReason.valid  === ValidationStatus.INVALID}
            id={patientStatus.notTransitioningHomeReason.componentId!}
            isRequired={patientStatus.notTransitioningHomeReason.required}
            isShowWarning={true}
            label="Reason for Patient Not Transitioning Home?"
            labelClassName="srtStatus-type-title"
            testId="notTransitioningHomeReasonlabel"
            warningMessage={patientStatus.notTransitioningHomeReason.errorMessage}
            isDropdown={true}
          >
            <CustomDropDown
               handleChange={validateAndSetData}
               menuItem={transitioningReasonText}
               name="notTransitioningHomeReason"
               placeHolder="Select a Reason"
               selectpropsClassName={
                 patientStatus.notTransitioningHomeReason.value ? "srtPatientStatus-info-select" : "placeHolder"
               }
               selectClassName={
                patientStatus.notTransitioningHomeReason.value ? "srtPatientStatus-info-input" : "placeHolder"
               }
               testId="notTransitioningHomeReason"
               value={patientStatus.notTransitioningHomeReason.value ? patientStatus.notTransitioningHomeReason.value : null}
              />
          </InputWithLabel>
        </Grid>)}
        {patientStatus.notTransitioningHomeReason.required && transitioningReason && patientStatus?.notTransitioningHomeReason?.value != null && 
         otherBoxVisibility.includes( patientStatus.notTransitioningHomeReason.value) && (
        <Grid
          className="srtPatientStatus-info-item" item xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            id={patientStatus.other.componentId!}
            error={patientStatus.other.valid === ValidationStatus.INVALID}
            isRequired={patientStatus.other.required}
            label="Describe more about the reason"
            labelClassName="srtStatus-type-title"
            testId="discribe-input-label"
            warningMessage={patientStatus.other.errorMessage}
            isShowWarning={true}
          >
          <InputBase
            className="srt-other-info-input"
            data-testid="describe-input-value"
            error={patientStatus.other.valid === ValidationStatus.INVALID}
            inputProps={{
              className:
              patientStatus.other.valid === ValidationStatus.INVALID
                  ? "showCommentError"
                  : "noCommentError",
                  maxLength: 200,
            }}
            multiline={true}
            name="other"
            onChange={validateAndSetDataFreeFormText}
            required={patientStatus.other.required}
            rows={3}
            value={patientStatus.other.value}
          />
        </InputWithLabel>
        </Grid>)}
      </Grid>
    </div>
  );
};
