import { getBlankForNullValue } from "../../../components/myPatients/patientAndTherapyDetails/orderOverview/orderOverviewResponseMapper";
import { ValidationStatus } from "../../../core/interfaces/input.interface";
import {
  makeCapitalEachOfWordInString,
  makeCapitalEachWordInString,
} from "../../../util/utilityFunctions";
import { SRTDischargeStatus } from "../components/srtPatientStatus/srtPatientStatus.enum";
import { IPrescriberModal } from "../../../components/newOrder/prescriberInformation/prescriberSearch/prescriberSearch.model";
import { ISalesRoundingPatient } from "../salesRoundingDashboard/salesRoundingDashboard.interface";
import {
  ISalesRoundingPatientDetails,
  IWoundInformation,
} from "../salesRoundingTool.interface";
import {
  defaultSalesRoundingPatientDetails,
  defaultWoundInformationData,
} from "../salesRoundingTool.model";
import { SalesRoundingValidator } from "../salesRoundingTool.validator";
import { getDeepClone } from "../../../util/ObjectFunctions";

const getWoundInformationData = (patientDataInfo: any) => {
  let mappedWoundInformationData: IWoundInformation = getDeepClone(
    defaultWoundInformationData
  );
  mappedWoundInformationData = {
    ...defaultWoundInformationData,
    woundInfoCount: {
      ...defaultWoundInformationData.woundInfoCount,
      value: getWoundInfoCount(patientDataInfo),
    },
    primaryWoundInformation: {
      ...mappedWoundInformationData.primaryWoundInformation,
      woundType: {
        ...mappedWoundInformationData.primaryWoundInformation.woundType,
        valid: ValidateFields(
          getBlankForNullValue(patientDataInfo.primaryWoundType),
          "woundType"
        )!,
        value: patientDataInfo.primaryWoundType,
      },
      woundLocation: {
        ...mappedWoundInformationData.primaryWoundInformation.woundLocation,
        valid: ValidateFields(
          getBlankForNullValue(patientDataInfo.primaryWoundLocation),
          "woundLocation"
        )!,
        value: getBlankForNullValue(patientDataInfo.primaryWoundLocation),
      },
      woundDirection: {
        ...mappedWoundInformationData.primaryWoundInformation.woundDirection,
        valid: ValidateFields(
          getBlankForNullValue(patientDataInfo.primaryWoundDirection),
          "woundDirection"
        )!,
        value: getBlankForNullValue(patientDataInfo.primaryWoundDirection),
      },
      woundOrientation: {
        ...mappedWoundInformationData.primaryWoundInformation.woundOrientation,
        valid: ValidateFields(
          getBlankForNullValue(patientDataInfo.primaryWoundOrientation),
          "woundOrientation"
        )!,
        value: getBlankForNullValue(patientDataInfo.primaryWoundOrientation),
      },
      woundMeasurementDate: {
        ...mappedWoundInformationData.primaryWoundInformation
          .woundMeasurementDate,
        valid: ValidateFields(
          getBlankForNullValue(patientDataInfo.primaryWoundMeasurementDate),
          "woundMeasurementDate"
        )!,
        value: getBlankForNullValue(
          patientDataInfo.primaryWoundMeasurementDate
        ),
      },
      woundLength: {
        ...mappedWoundInformationData.primaryWoundInformation.woundLength,
        valid: ValidateFields(
          getBlankForNullValue(patientDataInfo.primaryWoundLength),
          "woundLength"
        )!,
        value: getBlankForNullValue(patientDataInfo.primaryWoundLength),
      },
      woundWidth: {
        ...mappedWoundInformationData.primaryWoundInformation.woundWidth,
        valid: ValidateFields(
          getBlankForNullValue(patientDataInfo.primaryWoundWidth),
          "woundWidth"
        )!,
        value: getBlankForNullValue(patientDataInfo.primaryWoundWidth),
      },
      woundDepth: {
        ...mappedWoundInformationData.primaryWoundInformation.woundDepth,
        valid: ValidateFields(
          getBlankForNullValue(patientDataInfo.primaryWoundDepth),
          "woundDepth"
        )!,
        value: getBlankForNullValue(patientDataInfo.primaryWoundDepth),
      },
    },
    secondaryWoundInformation: {
      ...mappedWoundInformationData.secondaryWoundInformation,
      woundType: {
        ...mappedWoundInformationData.secondaryWoundInformation.woundType,
        valid: ValidateFields(patientDataInfo.secondaryWoundType, "woundType")!,
        value: patientDataInfo.secondaryWoundType,
      },
      woundLocation: {
        ...mappedWoundInformationData.secondaryWoundInformation.woundLocation,
        valid: ValidateFields(
          getBlankForNullValue(patientDataInfo.secondaryWoundLocation),
          "woundLocation"
        )!,
        value: getBlankForNullValue(patientDataInfo.secondaryWoundLocation),
      },
      woundDirection: {
        ...mappedWoundInformationData.secondaryWoundInformation.woundDirection,
        valid: ValidateFields(
          getBlankForNullValue(patientDataInfo.secondaryWoundDirection),
          "woundDirection"
        )!,
        value: getBlankForNullValue(patientDataInfo.secondaryWoundDirection),
      },
      woundOrientation: {
        ...mappedWoundInformationData.secondaryWoundInformation
          .woundOrientation,
        valid: ValidateFields(
          getBlankForNullValue(patientDataInfo.secondaryWoundOrientation),
          "woundOrientation"
        )!,
        value: getBlankForNullValue(patientDataInfo.secondaryWoundOrientation),
      },
      woundMeasurementDate: {
        ...mappedWoundInformationData.secondaryWoundInformation
          .woundMeasurementDate,
        valid: ValidateFields(
          getBlankForNullValue(patientDataInfo.secondaryWoundMeasurementDate),
          "woundMeasurementDate"
        )!,
        value: getBlankForNullValue(
          patientDataInfo.secondaryWoundMeasurementDate
        ),
      },
      woundLength: {
        ...mappedWoundInformationData.secondaryWoundInformation.woundLength,
        valid: ValidateFields(
          getBlankForNullValue(patientDataInfo.secondaryWoundLength),
          "woundLength"
        )!,
        value: getBlankForNullValue(patientDataInfo.secondaryWoundLength),
      },
      woundWidth: {
        ...mappedWoundInformationData.secondaryWoundInformation.woundWidth,
        valid: ValidateFields(
          getBlankForNullValue(patientDataInfo.secondaryWoundWidth),
          "woundWidth"
        )!,
        value: getBlankForNullValue(patientDataInfo.secondaryWoundWidth),
      },
      woundDepth: {
        ...mappedWoundInformationData.secondaryWoundInformation.woundDepth,
        valid: ValidateFields(
          getBlankForNullValue(patientDataInfo.secondaryWoundDepth),
          "woundDepth"
        )!,
        value: getBlankForNullValue(patientDataInfo.secondaryWoundDepth),
      },
    },
  };
  return mappedWoundInformationData;
};

const getWoundInfoCount = (srtPatientDetails: any) => {
  if (
    srtPatientDetails.primaryWoundType !== "" &&
    srtPatientDetails.primaryWoundType !== null &&
    srtPatientDetails.secondaryWoundType !== "" &&
    srtPatientDetails.secondaryWoundType !== null
  ) {
    return "2";
  } else if (
    srtPatientDetails.primaryWoundType !== "" &&
    srtPatientDetails.primaryWoundType !== null
  ) {
    return "1";
  } else return "0";
};

export const mapSRTResponseData = async (
  patientData: ISalesRoundingPatient,
  attendingPhysician: IPrescriberModal,
  prescribingPhysician: IPrescriberModal
): Promise<ISalesRoundingPatientDetails> => {
  let srtResponseMappedData: ISalesRoundingPatientDetails =
    defaultSalesRoundingPatientDetails;
  srtResponseMappedData = {
    ...srtResponseMappedData,
    patientInformation: {
      ...srtResponseMappedData.patientInformation,
      firstName: {
        ...srtResponseMappedData.patientInformation.firstName,
        valid: ValidateFields(patientData.firstName, "firstName")!,
        value: makeCapitalEachWordInString(patientData.firstName),
      },
      lastName: {
        ...srtResponseMappedData.patientInformation.lastName,
        valid: ValidateFields(patientData.lastName, "lastName")!,
        value: makeCapitalEachWordInString(patientData.lastName),
      },
      dateOfBirth: {
        ...srtResponseMappedData.patientInformation.dateOfBirth,
        valid: ValidateFields(patientData.dob, "dateOfBirth")!,
        value: patientData.dob,
      },
      room: {
        ...srtResponseMappedData.patientInformation.room,
        valid: ValidateFields(patientData.patientLocation, "room")!,
        value: patientData.patientLocation,
      },
      patientId: {
        ...srtResponseMappedData.patientInformation.patientId,
        valid: ValidateFields(patientData.patientId, "patientId")!,
        value: patientData.patientId,
      },
    },
    orderInformation: {
      roNumber: patientData.roNumber,
      product: patientData.productName,
      serialNumber: patientData.productSerialNumber,
      placementType: patientData.placementType,
    },
    patientStatus: {
      ...srtResponseMappedData.patientStatus,
      dischargeStatus: {
        ...srtResponseMappedData.patientStatus.dischargeStatus,
        valid: ValidateFields(patientData.dischargeStatus, "dischargeStatus")!,
        value: patientData.dischargeStatus,
      },
      notTransitioningHomeReason: {
        ...srtResponseMappedData.patientStatus.notTransitioningHomeReason,
        valid: ValidateFields(
          patientData.reasonNotTransitioningHome,
          "notTransitioningHomeReason"
        )!,
        value: patientData.reasonNotTransitioningHome,
        required:
          patientData.dischargeStatus === SRTDischargeStatus.DISCHARGE_TO_SNF ||
          patientData.dischargeStatus === SRTDischargeStatus.DISCHARGE_TO_LTF,
      },
      other: {
        ...srtResponseMappedData.patientStatus.other,
        valid: ValidateFields(
          getBlankForNullValue(
            patientData.otherReasonPatientNotTransitioningHome
          ),
          "other"
        )!,
        value: patientData?.otherReasonPatientNotTransitioningHome,
        required:
          patientData.reasonNotTransitioningHome ==
          SRTDischargeStatus.OTHER_REASON,
      },
    },
    therapyInformation: {
      ...srtResponseMappedData.therapyInformation,
      leadGeneration: {
        ...srtResponseMappedData.therapyInformation.leadGeneration,
        valid: ValidateFields(patientData.leadGeneration, "leadGeneration")!,
        value: patientData.leadGeneration,
      },
      transitionTherapyStartDate: {
        ...srtResponseMappedData.therapyInformation.transitionTherapyStartDate,
        valid: ValidateFields(
          patientData.transitionTherapyStartDate,
          "transitionTherapyStartDate"
        )!,
        value: patientData.transitionTherapyStartDate,
      },
    },
    attendingPhysician: {
      firstName: attendingPhysician.firstName,
      lastName: attendingPhysician.lastName,
      npi: attendingPhysician.npi,
      city: attendingPhysician.city,
      state: attendingPhysician.state,
      telephoneNumber: attendingPhysician.telephoneNumber,
      zipCode: attendingPhysician.zipCode,
      address1: attendingPhysician.address1,
      address2: attendingPhysician.address2,
    },
    prescribingPhysician: {
      firstName: prescribingPhysician.firstName,
      lastName: prescribingPhysician.lastName,
      npi: prescribingPhysician.npi,
      city: prescribingPhysician.city,
      state: prescribingPhysician.state,
      telephoneNumber: prescribingPhysician.telephoneNumber,
      zipCode: prescribingPhysician.zipCode,
      address1: prescribingPhysician.address1,
      address2: prescribingPhysician.address2,
    },
    serviceCoveringInformation: {
      ...srtResponseMappedData.serviceCoveringInformation,
      serviceCovering: {
        ...srtResponseMappedData.serviceCoveringInformation.serviceCovering,
        valid: ValidateFields(patientData.serviceCovering, "serviceCovering")!,
        value: patientData.serviceCovering,
      },
    },
    patientWoundInformation: {
      valid: ValidationStatus.UNTOUCHED,
      value: getWoundInformationData(patientData),
    },
  };
  return srtResponseMappedData;
};
const ValidateFields = (Value: string, Name: string) => {
  let Validator = new SalesRoundingValidator();
  let notNullValue = Value === null ? "" : Value;
  const Status = Validator?.validate(notNullValue, Name)?.status;
  return Status === ValidationStatus.INVALID
    ? ValidationStatus.UNTOUCHED
    : Status;
};
