export interface ISalesRoundingPatient {
  orderId?: string;
  ropNumber?: string;
  color: string;
  firstName: string;
  lastName: string;
  dob: string;
  roNumber: string;
  placementType: any;
  dischargeStatus: any;
  reasonNotTransitioningHome: any;
  transitionTherapyStartDate: string;
  lastUpdatedDate: string;
  productName: string;
  leadGeneration: string;
  patientId: any;
  patientLocation: string;
  serviceCovering: string;
  primaryWoundType: any;
  primaryWoundLocation: string;
  primaryWoundDirection: string;
  primaryWoundOrientation: string;
  secondaryWoundType: string;
  secondaryWoundLocation: string;
  secondaryWoundDirection: string;
  secondaryWoundOrientation: string;
  productSerialNumber: any;
  status: string;
  type: string;
  numberOfWounds: string;
  isSaleRoundingCompleted: boolean;
  isExpanded?: boolean;
  attendingPhysicianFirstName?: string;
  attendingPhysicianLastName?: string;
  attendingPhysicianNPI?: string;
  prescribingPhysicianFirstName?: string;
  prescribingPhysicianLastName?: string;
  prescribingPhysicianNPI?: string;
  otherReasonPatientNotTransitioningHome: string;
}

export const salesRoundingCSVHeaders: string[] = [
  "RO Number",
  "First Name",
  "Last Name",
  "DOB",
  "Location",
  "Patient ID",
  "Status",
  "Product Name",
  "Serial Number",
  "Type",
  "Placement Type",
  "Discharge Status",
  "Reason Not Transitioning Home",
  "Other Reason Patient Not Transitioning Home",
  "Transition Therapy Start Date",
  "Last Updated Date",
  "Lead Generation",
  "Attending Physician First Name",
  "Attending Physician Last Name",
  "Attending Physician NPI",
  "Prescribing Physician First Name",
  "Prescribing Physician Last Name",
  "Prescribing Physician NPI",
  "Service Covering",
  "Number of Wounds",
  "Is Sales Rounding Completed",
  "Primary Wound Type",
  "Primary Wound Location",
  "Primary Wound Direction",
  "Primary Wound Orientation",
  "Secondary Wound Type",
  "Secondary Wound Location",
  "Secondary Wound Direction",
  "Secondary Wound Orientation",
];
