import "./inpatientSupplyOrderContainer.css";
import { format } from "react-string-format";
import { useMediaQuery } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import {
  ICustomerInformation,
  InpatientSupplyOrderContext,
  InpatientSupplyOrderContextType,
} from "../../../context/InpatientSupplyOrderContext";
import ErrorPopup, {
  defaultErrorPopUp,
  IErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import { Popup } from "../../../core/popup/popup.component";
import {
  getCurrentServerDateTime,
  getFaciityPatients,
} from "../../../util/3meService";
import { IPatient } from "../../myPatients/patient.interface";
import ProgressBar from "../../progressBar/progressBar.component";
import { getdropDownContent } from "../../../util/dropDownService";
import {
  ERROR_MSG_MPD_PATIENT_LOAD,
  SVC_GET_CUSTOMER_INFORMATION_ERROR,
  SVC_IN_PATIENT_SUPPLY_ORDER_SUBMIT,
  SVC_STOCK_SUPPLY_ORDER_SUBMIT,
} from "../../../util/errorMsg";
import {
  CMS_VAC_THERAPY_INFORMATION_CONTENT,
  DD_DELIVERY_METHODS,
  DD_US_STATES_CONTENT,
  DD_VAC_ORDER_PRODUCT_TYPE,
} from "../../../util/staticText";
import {
  IDeliveryDropwnTypes,
  IDropDownContent,
  IInpatientSupplyOrder,
  IInPatientSupplyOrderProduct,
  IInPatientSupplyOrderRequest,
} from "../inpatientSupplyOrder.interface";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { InpatientSupplyOrderPageSection } from "../inpatientSupplyOrder.enum";
import {
  getCustomerFacilityAccountNumber,
  getCustomerMasterNumber,
  getSiteUseId,
  getTextFromCode,
} from "../../../util/utilityFunctions";
import { InpatientSupplyOrderListPage } from "../listPage/inpatientSupplyOrderListPage.component";
import { InPatientSupplyOrderFirstPage } from "../firstPage/inPatientSupplyOrderFirstPage.component";
import { InPatientSupplyOrderSecondPage } from "../secondPage/inpatientSupplyOrderSecondPage.Component";
import { InPatientSupplyOrderFooter } from "../component/footer/inPatientSupplyOrderFooter.component";
import { ProductAvailibiltyPopup } from "../component/popup/productAvailibilityPopup/productAvailibilityPopup.component";
import { useCart } from "../../../context/CartContext";
import { InpatientSupplyOrderValidator } from "../inpatientSupplyOrder.validator";
import {
  IInputField,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import { getDeepClone } from "../../../util/ObjectFunctions";
import { defaultShippingAddressData } from "../inpatientSupplyOrder.data";
import moment from "moment";
import { InPatientSupplyOrderReviewPage } from "../review/inpatientSupplyOrderReview.component";
import { InpatientSuppyOrderSummaryPage } from "../summary/inpatientSupplyOrderSummary.component";
import { mapAcuteSupplyOrderRequest } from "../component/mapper/submitAcuteSupplyOrderRequest.mapper";
import { getCustomerInformation } from "../../../util/inPatientOrderService";
import { submitAcuteSupplyOrderDetails } from "../../../util/actueService";
import {
  REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE,
  USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE,
} from "../../../util/errorCode";
import {
  RolesPermissionContextType,
  RolesPermissionContext,
} from "../../../context/RolesPermissionContext";
import { ICartItem } from "../component/cart/cartItem/cartItem.interface";
export interface IFilterValues {
  searchInput: string;
  searchProductType: string;
  searchSupplyType: string;
}

export const defaultFilterValues: IFilterValues = {
  searchInput: "",
  searchProductType: "",
  searchSupplyType: "",
};

export interface IProductFamilyType {
  text: string;
  code: string;
}

export const InpatientSupplyOrderContainer = () => {
  const history = useHistory();
  const location: any = useLocation();
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const inpatientSupplyOrderObj =
    useContext<InpatientSupplyOrderContextType | null>(
      InpatientSupplyOrderContext
    );
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [sortedData, setSortedData] = useState<IPatient[]>([]);
  const [patientList, setPatientList] = React.useState<Array<IPatient>>();
  const [products, setProducts] = useState([]);
  const [productText, setProductText] = useState([]);
  const [dropDownList, setDropDownList] = useState([]);
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const [states, setStates] = useState([]);
  const [statesText, setStatesText] = useState([]);
  const validator = new InpatientSupplyOrderValidator();
  const [openCart, setOpenCart] = useState(false);
  const { cartState, dispatch } = useCart();
  const [productAvailibiltyPopup, setProductAvailibiltyPopup] =
    useState<boolean>(false);
  const isFromStockSupplies = location?.state?.isFromStockSupplies ?? false;
  const inPatientSuppliesData = inpatientSupplyOrderObj!.inPatientSuppliesData;
  const setInPatientSuppliesData =
    inpatientSupplyOrderObj!.setInPatientSuppliesData;
  const data = inpatientSupplyOrderObj!.data;
  const setData = inpatientSupplyOrderObj!.setData;
  const productsFamilyTypes = inpatientSupplyOrderObj!.productsFamilyTypes;
  const setProductsFamilyTypes =
    inpatientSupplyOrderObj!.setProductsFamilyTypes;
  const filterValues = inpatientSupplyOrderObj!.filterValues;
  const setFilterValues = inpatientSupplyOrderObj!.setFilterValues;
  const [deliveryMethodTypes, setDeliveryMethodTypes] = useState<
    IDeliveryDropwnTypes[]
  >([]);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const [showProductsLoader, setShowProductsLoader] = useState<boolean>(false);

  const cancelButtonAction = () => {
    inpatientSupplyOrderObj?.resetContext();
    history.push({
      pathname: "/orders",
    });
  };

  const closeErrorPopUpButtonAction = () => {
    history.goBack();
  };

  const editButtonClick = (
    scrollableComponentClassName: string,
    pageSection: InpatientSupplyOrderPageSection
  ) => {
    if (pageSection === InpatientSupplyOrderPageSection.SECOND_PAGE) {
      inpatientSupplyOrderObj?.setOrderProgress(60);
      inpatientSupplyOrderObj?.setPage(pageSection);
    } else {
      inpatientSupplyOrderObj?.setOrderProgress(40);
      inpatientSupplyOrderObj?.setPage(
        InpatientSupplyOrderPageSection.FIRST_PAGE
      );
    }
    window.scroll(0, 0);
    setTimeout(() => {
      if (scrollableComponentClassName) {
        let scrollableComponent = document.getElementsByClassName(
          scrollableComponentClassName
        )[0];
        if (scrollableComponent) {
          let a = document.getElementsByClassName(
            "in-patient-supply-order-button"
          );
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
      if (pageSection === InpatientSupplyOrderPageSection.FIRST_PAGE) {
        setTimeout(() => {
          setOpenCart(true);
        }, 500);
      }
    }, 500);
  };

  const previousButtonAction = () => {
    switch (inpatientSupplyOrderObj?.page) {
      case InpatientSupplyOrderPageSection.LIST_PAGE:
        window.scrollTo(0, 0);
        history.goBack();
        break;
      case InpatientSupplyOrderPageSection.FIRST_PAGE:
        window.scrollTo(0, 0);
        inpatientSupplyOrderObj?.setOrderProgress(20);
        if (isFromStockSupplies) {
          history.goBack();
        } else {
          inpatientSupplyOrderObj?.setPage(
            InpatientSupplyOrderPageSection.LIST_PAGE
          );
        }
        setOpenCart(false);
        break;
      case InpatientSupplyOrderPageSection.SECOND_PAGE:
        window.scrollTo(0, 0);
        inpatientSupplyOrderObj?.setOrderProgress(40);
        inpatientSupplyOrderObj?.setPage(
          InpatientSupplyOrderPageSection.FIRST_PAGE
        );
        break;
      case InpatientSupplyOrderPageSection.REVIEW_PAGE:
        window.scrollTo(0, 0);
        inpatientSupplyOrderObj?.setOrderProgress(60);
        inpatientSupplyOrderObj?.setPage(
          InpatientSupplyOrderPageSection.SECOND_PAGE
        );
        break;
      case InpatientSupplyOrderPageSection.SUMMARY_PAGE:
        window.scrollTo(0, 0);
        inpatientSupplyOrderObj?.setOrderProgress(80);
        inpatientSupplyOrderObj?.setPage(
          InpatientSupplyOrderPageSection.REVIEW_PAGE
        );
        break;
      default:
        break;
    }
  };

  const checkErroredFieldAndFocusOnInputField = async (
    errorField: IInputField
  ) => {
    if (errorField.componentId && errorField.componentId !== "") {
      const scrollableComponent = document.getElementById(
        errorField.componentId
      );
      await setTimeout(() => {
        if (scrollableComponent) {
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
          });
          if (errorField.id && errorField.id !== "") {
            const autoCursorComponentId = document.getElementById(
              errorField.id
            );
            setTimeout(() => {
              if (autoCursorComponentId) {
                autoCursorComponentId.focus();
              }
            }, 500);
          }
        }
      }, 500);
    }
  };

  const getCustomerInfo = async (): Promise<ICustomerInformation | null> => {
    const siteUseId = getSiteUseId(authObj);
    let customerInfoObj: ICustomerInformation | null = null;
    if (
      inpatientSupplyOrderObj &&
      !inpatientSupplyOrderObj.customerInformation &&
      siteUseId !== ""
    ) {
      const params = {
        siteUseId: siteUseId,
      };
      setShowLoader(true);
      const response = await getCustomerInformation(params);
      setShowLoader(false);
      if (response && response.succeeded) {
        customerInfoObj = {
          billToSiteUseId: response.item.billToSiteId,
          customerNumber: response.item.customerNumber,
          creditStatus: response.item.creditstatus,
        };
      } else {
        const apiFailure = {
          errorCode:
            response?.error?.errorCode ||
            response?.error?.code ||
            response?.status,
          errorFlag: true,
          errorMessage: SVC_GET_CUSTOMER_INFORMATION_ERROR,
          buttonTitle: "Done",
          isPhoneNumberRequired: true,
        };
        setError(apiFailure);
      }
    } else {
      customerInfoObj = inpatientSupplyOrderObj!.customerInformation;
    }
    return customerInfoObj;
  };
  const submitAcuteSupplyOrderAPI = async (): Promise<boolean> => {
    let isOrderSubmitted: boolean = false;
    const customerInfoObj = await getCustomerInfo();
    if (
      authObj &&
      authObj.userProfile &&
      authObj.registeredFaciltyAddress &&
      customerInfoObj
    ) {
      setShowLoader(true);
      const requestParam = await mapAcuteSupplyOrderRequest(
        authObj,
        customerInfoObj,
        inpatientSupplyOrderObj!.data,
        isFromStockSupplies,
        cartState.cartItems,
        filterValues.searchProductType,
        deliveryMethodTypes
      );
      const response = await submitAcuteSupplyOrderDetails(requestParam);
      setShowLoader(false);
      let errorCode = "";
      if (response && !response.succeeded) {
        errorCode =
          response?.error?.errorCode ||
          response?.error?.code ||
          response?.status;

        const errorFailure = {
          errorCode: errorCode,
          errorFlag: true,
          errorMessage: isFromStockSupplies
            ? SVC_STOCK_SUPPLY_ORDER_SUBMIT
            : SVC_IN_PATIENT_SUPPLY_ORDER_SUBMIT,
        };
        setError(errorFailure);
      } else {
        isOrderSubmitted = true;
        const returnCodes = response.items.map(
          (item: { returnCode: number }) => item.returnCode
        );
        if (returnCodes.length === cartState.cartItems.length) {
          dispatch({
            type: "UPDATE_SUPPLY_STATUS",
            returnCodes: returnCodes,
          });
        }
      }
    } else {
      if (customerInfoObj !== null) {
        let errorCode = "";
        if (!authObj || (authObj && !authObj.userProfile)) {
          errorCode = USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE;
        } else if (!authObj || (authObj && !authObj.registeredFaciltyAddress)) {
          errorCode = REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE;
        }
        const errorFailure = {
          errorCode: errorCode,
          errorFlag: true,
          errorMessage: isFromStockSupplies
            ? SVC_STOCK_SUPPLY_ORDER_SUBMIT
            : SVC_IN_PATIENT_SUPPLY_ORDER_SUBMIT,
        };
        setError(errorFailure);
      }
    }
    return isOrderSubmitted;
  };

  const nextButtonAction = async () => {
    switch (inpatientSupplyOrderObj?.page) {
      case InpatientSupplyOrderPageSection.FIRST_PAGE:
        if (cartState.cartItems.length === 0) {
          setProductAvailibiltyPopup(true);
        } else {
          window.scrollTo(0, 0);
          inpatientSupplyOrderObj?.setOrderProgress(60);
          inpatientSupplyOrderObj?.setPage(
            InpatientSupplyOrderPageSection.SECOND_PAGE
          );
        }
        setOpenCart(false);
        break;
      case InpatientSupplyOrderPageSection.SECOND_PAGE:
        let [isValid, errorField] = validator.validateAll(
          inpatientSupplyOrderObj?.data,
          inpatientSupplyOrderObj?.setData
        );
        if (errorField) {
          checkErroredFieldAndFocusOnInputField(errorField);
        }
        if (isValid === ValidationStatus.VALID) {
          window.scrollTo(0, 0);
          inpatientSupplyOrderObj?.setOrderProgress(80);
          inpatientSupplyOrderObj?.setPage(
            InpatientSupplyOrderPageSection.REVIEW_PAGE
          );
        }
        break;
      case InpatientSupplyOrderPageSection.REVIEW_PAGE:
        window.scrollTo(0, 0);
        let isApiSucceded = await submitAcuteSupplyOrderAPI();
        if (isApiSucceded) {
          inpatientSupplyOrderObj?.setPage(
            InpatientSupplyOrderPageSection.SUMMARY_PAGE
          );
        }

        break;
      case InpatientSupplyOrderPageSection.SUMMARY_PAGE:
        window.scrollTo(0, 0);
        inpatientSupplyOrderObj?.setPage(
          InpatientSupplyOrderPageSection.REVIEW_PAGE
        );
        break;
      default:
        break;
    }
  };

  const loadPatientData = async () => {
    try {
      setShowLoader(true);
      let productList = await fetchDropDownContent();
      const facilityPatientsResponse = await getFaciityPatients(
        getSiteUseId(authObj),
        authObj?.registeredFaciltyAddress?.careGiverId,
        authObj?.registeredFaciltyAddress?.careGiverSiteUseId,
        authObj?.userProfile?.userName,
        true,
        getCustomerMasterNumber(authObj),
        authObj?.registeredFaciltyAddress?.accountNumber!,
        authObj?.registeredFaciltyAddress?.isPostAcute ?? true,
        true
      );
      if (!facilityPatientsResponse || !facilityPatientsResponse.succeeded) {
        let error: IErrorPopUp = {
          errorCode:
            facilityPatientsResponse?.error?.code ||
            facilityPatientsResponse?.error?.errorCode ||
            facilityPatientsResponse?.status,
          errorMessage: ERROR_MSG_MPD_PATIENT_LOAD,
          errorFlag: true,
        };
        setShowLoader(false);
        setError(error);
      }
      let faciltyPatientData =
        facilityPatientsResponse.data !== null
          ? (facilityPatientsResponse.data as Array<IPatient>)
          : [];
      if (faciltyPatientData && faciltyPatientData.length > 0) {
        faciltyPatientData = faciltyPatientData
          .filter(
            (x: IPatient) => x.isAcuteOrder && x.status?.includes("Therapy")
          )
          .map((x: IPatient) => {
            return {
              ...x,
              productNameDescription: getTextFromCode(
                productList,
                x.productBrandCode?.toString()
              ),
            };
          });
        setSortedData(faciltyPatientData);
        setPatientList(faciltyPatientData);
        await filteredDropDownList(productList, faciltyPatientData);
        setShowLoader(false);
      } else setShowLoader(false);
    } catch (error) {
      let errorObj: IErrorPopUp = {
        errorCode: "0000",
        errorMessage: ERROR_MSG_MPD_PATIENT_LOAD,
        errorFlag: true,
      };
      setError(errorObj);
      setShowLoader(false);
      console.log("error", error);
    }
  };

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format(
        "{0},{1}",
        DD_VAC_ORDER_PRODUCT_TYPE,
        DD_DELIVERY_METHODS
      );
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const productObject = data.items.filter(
          (item: { name: string }) => item.name === DD_VAC_ORDER_PRODUCT_TYPE
        );
        const productData = productObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setProducts(productData);
        setProductText(productData.map((x: { text: string }) => x.text));
        return productData;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateInitialDeliveryType = (type: string) => {
    let tempData = inpatientSupplyOrderObj!.data;
    tempData.deliveryInformation.deliveryMethod = {
      ...tempData.deliveryInformation.deliveryMethod,
      value: type,
      valid: ValidationStatus.VALID,
    };
    inpatientSupplyOrderObj!.setData(tempData);
  };

  const fetchStateDropDownContent = async () => {
    try {
      const ddContent = format(
        "{0},{1}",
        DD_US_STATES_CONTENT,
        DD_DELIVERY_METHODS
      );
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const stateObject = data.items.filter(
          (item: { name: string }) => item.name === DD_US_STATES_CONTENT
        );
        const statesData = stateObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        setStates(statesData);
        setStatesText(statesData.map((x: { text: string }) => x.text));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const filteredDropDownList = async (dropDowndata: any, patientList: any) => {
    const intersectionText = dropDowndata
      .filter((firstItem: IDropDownContent) =>
        patientList.some(
          (secondItem: IPatient) =>
            secondItem.productBrandCode !== null &&
            secondItem.productBrandCode!.toString() === firstItem.code
        )
      )
      .map((item: IDropDownContent) => item.text);
    setDropDownList(intersectionText);
  };

  const checkForPreviousButtonText = (): string => {
    return "Previous";
  };

  const checkForNextButtonDisabled = () => {
    return InpatientSupplyOrderPageSection.FIRST_PAGE
      ? cartState.invalidQuantity
      : false;
  };

  const disableSubmitForSupportRole =
    inpatientSupplyOrderObj?.page ===
      InpatientSupplyOrderPageSection.REVIEW_PAGE &&
    permissionObj?.mappedRolesPermissionData.IsSupportRole
      ? true
      : false;

  const checkForNextButtonText = (): string => {
    return inpatientSupplyOrderObj?.page ===
      InpatientSupplyOrderPageSection.REVIEW_PAGE
      ? isMobileScreen
        ? "Submit"
        : "Submit Request"
      : "Next";
  };

  const getDateForNeedByDate = async (tempData: IInpatientSupplyOrder) => {
    const currentDateResponse = await getCurrentServerDateTime();
    if (currentDateResponse) {
      inpatientSupplyOrderObj?.setCurrentServerDate(currentDateResponse);
      const dateTime = moment(currentDateResponse.currentCstTime)
        .add(6, "hours")
        .toDate();
      const formattedDate = moment(dateTime).format("MM/DD/YYYY");
      const formatMaxDate: any = moment(currentDateResponse.currentCstTime).add(
        10,
        "days"
      );
      inpatientSupplyOrderObj?.setMaxDate(formatMaxDate);
    }
    return tempData;
  };

  const updateSalesPurchaseOrderInfo = (tempData: IInpatientSupplyOrder) => {
    return {
      ...tempData,
      patientAndProduct: null,
      salesPurchaseOrderInformation: {
        ...tempData.salesPurchaseOrderInformation,
        salesPONumber: {
          ...tempData.salesPurchaseOrderInformation?.salesPONumber,
          required: false,
          valid: ValidationStatus.VALID,
          value: "",
        },
        isPoRequired: false,
      },
    };
  };

  const loadInitialPermissions = async () => {
    let tempData = inpatientSupplyOrderObj!.data;
    if (inpatientSupplyOrderObj?.currentServerDate === null) {
      tempData = await getDateForNeedByDate(tempData);
    }
    if (isFromStockSupplies) {
      tempData = updateSalesPurchaseOrderInfo(tempData);
    }
    inpatientSupplyOrderObj?.setData(tempData);
  };

  useEffect(() => {
    fetchStateDropDownContent();
    loadInitialPermissions();
  }, []);

  useEffect(() => {
    if (
      authObj?.userProfile?.userName?.length! > 0 &&
      authObj?.registeredFaciltyAddress?.siteUseId &&
      inpatientSupplyOrderObj?.page ===
        InpatientSupplyOrderPageSection.LIST_PAGE
    ) {
      loadPatientData();
    }
  }, [
    authObj?.registeredFaciltyAddress?.siteUseId,
    authObj?.registeredFaciltyAddress?.careGiverId,
    authObj?.userProfile?.userName,
    inpatientSupplyOrderObj?.page,
  ]);

  useEffect(() => {
    if (
      inpatientSupplyOrderObj?.page ===
      InpatientSupplyOrderPageSection.LIST_PAGE
    ) {
      inpatientSupplyOrderObj?.setData({
        ...inpatientSupplyOrderObj?.data,
        shippingAddress: getDeepClone(defaultShippingAddressData),
      });
    }
  }, [inpatientSupplyOrderObj!.page]);

  useEffect(() => {
    if (!authObj!.registeredFaciltyAddress) {
      history.push("/home");
    }
  }, [authObj!.registeredFaciltyAddress]);

  const sectionToDisplay = () => {
    let page: ReactNode;
    switch (inpatientSupplyOrderObj!.page) {
      case InpatientSupplyOrderPageSection.LIST_PAGE:
        page = (
          <InpatientSupplyOrderListPage
            setFilterValues={setFilterValues}
            dropDownList={dropDownList}
            error={error}
            patientList={patientList!}
            showLoader={showLoader}
            setError={setError}
            setShowLoader={setShowLoader}
            setSortedData={setSortedData}
            sortedData={sortedData!}
          />
        );
        break;
      case InpatientSupplyOrderPageSection.FIRST_PAGE:
        page = (
          <InPatientSupplyOrderFirstPage
            isFromStockSupplies={isFromStockSupplies}
            openCart={openCart}
            setOpenCart={setOpenCart}
            setShowLoader={setShowProductsLoader}
            showLoader={showProductsLoader}
            patient={inpatientSupplyOrderObj!.data.patientAndProduct?.patient}
            product={inpatientSupplyOrderObj!.data.patientAndProduct?.product}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
          />
        );
        break;
      case InpatientSupplyOrderPageSection.SECOND_PAGE:
        page = (
          <InPatientSupplyOrderSecondPage
            data={inpatientSupplyOrderObj!.data}
            setData={inpatientSupplyOrderObj!.setData}
            states={states}
            statesText={statesText}
            maxDate={inpatientSupplyOrderObj!.maxDate}
            cartItemsData={cartState}
            setDeliveryMethodTypesComs={setDeliveryMethodTypes}
          />
        );
        break;
      case InpatientSupplyOrderPageSection.REVIEW_PAGE:
        page = (
          <InPatientSupplyOrderReviewPage
            data={inpatientSupplyOrderObj!.data}
            editButtonAction={editButtonClick}
            cartItemsData={cartState}
            isFromStockSupplies={isFromStockSupplies}
          />
        );
        break;
      case InpatientSupplyOrderPageSection.SUMMARY_PAGE:
        page = (
          <InpatientSuppyOrderSummaryPage
            data={inpatientSupplyOrderObj!.data}
            isSummaryOrDetail={true}
            cartItem={cartState}
            isFromStockSupplies={isFromStockSupplies}
          />
        );
        break;
    }
    return page;
  };

  return (
    <div
      className="in-patient-supply-order-container-component"
      id="id-in-patient-supply-order-container-component"
    >
      {inpatientSupplyOrderObj?.page !==
        InpatientSupplyOrderPageSection.SUMMARY_PAGE && (
        <ProgressBar
          pageTitle={
            isFromStockSupplies
              ? "Stock Supply Order"
              : "In-Patient Supply Order"
          }
          progressValue={inpatientSupplyOrderObj?.orderProgress}
          backButtonAction={previousButtonAction}
          isShowBackButton={true}
        />
      )}
      <div className="inPatientsupplyOrderGrid"> {sectionToDisplay()}</div>
      {inpatientSupplyOrderObj?.page !==
        InpatientSupplyOrderPageSection.LIST_PAGE &&
        inpatientSupplyOrderObj?.page !==
          InpatientSupplyOrderPageSection.SUMMARY_PAGE && (
          <InPatientSupplyOrderFooter
            firstButtonAction={cancelButtonAction}
            firstButtonDisabled={false}
            firstButtonTitle="Cancel Order"
            secondButtonAction={previousButtonAction}
            secondButtonDisabled={false}
            secondButtonTitle={checkForPreviousButtonText()}
            thirdButtonAction={nextButtonAction}
            thirdButtonDisabled={
              checkForNextButtonDisabled() || disableSubmitForSupportRole
            }
            thirdButtonTitle={checkForNextButtonText()}
          />
        )}
      <Popup
        openFlag={productAvailibiltyPopup}
        closeHandler={() => setProductAvailibiltyPopup(false)}
        dialogParentClass="product-availibility-supply-order-list-popup"
      >
        <ProductAvailibiltyPopup
          firstButtonText="Back to Start New Order"
          secondButtonText="Select Supplies"
          title="Add at least one product to continue your order"
          dialogParentClass="If you wish to make a different kind of order, please return to the <strong>Start New Order</strong> screen."
          firstButtonHandler={cancelButtonAction}
          secondButtonHandler={() => setProductAvailibiltyPopup(false)}
        />
      </Popup>
      <Popup
        closeHandler={() => setShowLoader(false)}
        dialogParentClass="in-patient-supply-order-container-popup"
        hideCloseButton={true}
        openFlag={showLoader}
      >
        <div
          className="in-patient-supply-order-container-popup-div"
          id="in-patient-supplyspinner"
        >
          <LoadingSpinner />
        </div>
      </Popup>
      <ErrorPopup
        buttontitle={"Done"}
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        isShortSentence={true}
        handleBackButton={() => {
          closeErrorPopUpButtonAction();
        }}
        popUpStyles="errorPopup"
        isSupportPhoneRequired={true}
        showTitle={false}
      />
    </div>
  );
};
