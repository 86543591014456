import "./addPrescriberDetails.css";
import { Button, Grid } from "@mui/material";
import {
  formatPhoneNumber,
  makeCapitalEachWordInString,
} from "../../../../util/utilityFunctions";
import {
  IMobileDisplayContext,
  MobileDisplayContext,
} from "../../../../context/MobileDisplayContext";
import { useContext, useState } from "react";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ReactComponent as SearchIcon } from "../../../../assets/searchPayer.svg";
import { Popup } from "../../../../core/popup/popup.component";
import { PrescriberInformationPopUpContainer } from "../../../../components/inpatientOrder/components/prescriberInformation/popUpContainer/prescriberInformationPopUpContainer.component";
import { PrescriberPopUp } from "../../../../components/inpatientOrder/components/prescriberInformation/popUpContainer/prescriberInformationPopUpContainer.enum";
import { ITerritoryFacility } from "../../salesRoundingTool.interface";
import { ShowTitleAndValue } from "../../../../core/showTitleAndValue/showTitleAndValue.component";
import { IPrescriberModal } from "../../../../components/newOrder/prescriberInformation/prescriberSearch/prescriberSearch.model";
import {
  ISalesRoundingContextType,
  SaleRoundingToolContext,
} from "../../../../context/SalesRoundingToolContext";

interface IAddPrescriberInformation {
  prescriberInformation: IPrescriberModal;
  selectedFacility: ITerritoryFacility;
  isAttendingPhysician: Boolean;
  handleSuccessAndClose: Function;
}

export const AddPrescriberDetails = ({
  prescriberInformation,
  selectedFacility,
  isAttendingPhysician,
  handleSuccessAndClose,
}: IAddPrescriberInformation) => {
  const { isMobileScreen } =
    useContext<IMobileDisplayContext>(MobileDisplayContext);

  const [openContainerPopupFlag, setOpenContainerPopupFlag] = useState(false);
  const [currentPopUp, setCurrentPopUp] = useState<PrescriberPopUp>(
    PrescriberPopUp.LOADER
  );
  const salesRoundingObj = useContext<ISalesRoundingContextType | null>(
    SaleRoundingToolContext
  );
  const closePopUp = () => {
    setOpenContainerPopupFlag(false);
    setCurrentPopUp(PrescriberPopUp.LOADER);
  };

  const searchPrescriberButtonAction = () => {
    setOpenContainerPopupFlag(true);
    if (!isAttendingPhysician) {
      salesRoundingObj?.setSrtPatientDetailsUpdated(true);
    }
  };

  const handleClosePopUpAction = () => {
    closePopUp();
  };

  const handleSetPrescriberAndClose = (prescriber: IPrescriberModal) => {
    handleSuccessAndClose(prescriber);
    closePopUp();
  };

  return (
    <div
      className="srt-prescriber-info-component"
      data-testid="srt-prescriber-info-component"
      id="srt-prescriber-info-component"
    >
      <div className="srt-prescriber-info">
        <h2
          className="srt-prescriber-info-title"
          data-testid="srt-prescriber-info-title"
          id="srt-prescriber-info-title"
        >
          {isAttendingPhysician
            ? "Attending Physician"
            : "Prescribing Physician"}
        </h2>
        <div className="srt-prescriberDiv">
          <p
            className="srt-presDesc"
            data-testid="srt-presDesc"
            id="srt-presDesc"
          >
            {isAttendingPhysician
              ? "Attending Physician manages the patient's therapy"
              : "Prescriber writes the initial prescription"}
          </p>
        </div>
        {prescriberInformation.npi !== "" ? (
          <>
            <Grid
              className="srt-prescriber-selected-details-grid-container"
              container
            >
              <Grid
                className="srt-prescriber-selected-details-grid-item"
                item
                xs={6}
              >
                <p
                  className="srt-prescriber-name"
                  data-testid="srt-prescriber-name"
                  id="srt-prescriber-name"
                >
                  {makeCapitalEachWordInString(
                    `${prescriberInformation.firstName} ${prescriberInformation.lastName}`
                  )}
                </p>
              </Grid>
              <Grid
                className="srt-prescriber-selected-details-grid-item"
                item
                xs={6}
              >
                <Button
                  classes={{
                    root: "srt-change-prescriber-button",
                  }}
                  data-testid="srt-change-prescriber-button"
                  id="srt-change-prescriber-button"
                  onClick={() => {
                    searchPrescriberButtonAction();
                  }}
                >
                  Edit
                </Button>
              </Grid>
              <Grid
                className="srt-prescriber-selected-details-grid-item"
                item
                xs={6}
              >
                <ShowTitleAndValue
                  title={"NPI"}
                  value={prescriberInformation.npi}
                  testId={"prescriber-npi"}
                />
              </Grid>
              <Grid
                className="srt-prescriber-selected-details-grid-item"
                item
                xs={6}
              >
                <ShowTitleAndValue
                  title={"Phone"}
                  value={formatPhoneNumber(
                    prescriberInformation.telephoneNumber
                  )}
                  testId={"prescriber-phone"}
                />
              </Grid>
              <Grid
                className="srt-prescriber-selected-details-grid-item"
                item
                xs={6}
              >
                <ShowTitleAndValue
                  title={"Fax"}
                  value={
                    prescriberInformation.faxNumber === "" ||
                    prescriberInformation.faxNumber === null
                      ? "--"
                      : formatPhoneNumber(prescriberInformation.faxNumber!)
                  }
                  testId={"prescriber-fax"}
                />
              </Grid>
              <Grid
                className="srt-prescriber-selected-details-grid-item"
                item
                xs={!isMobileScreen ? 4 : 6}
              >
                <ShowTitleAndValue
                  title={"Address"}
                  value={`${makeCapitalEachWordInString(
                    prescriberInformation.address1
                  )}\n${
                    prescriberInformation.address2 &&
                    prescriberInformation.address2 !== ""
                      ? `${makeCapitalEachWordInString(
                          prescriberInformation.address2
                        )}\n`
                      : ""
                  }${makeCapitalEachWordInString(
                    prescriberInformation.city
                  )}, ${prescriberInformation.state} ${
                    prescriberInformation.zipCode
                  }`}
                  testId={"srt-prescriber-address"}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            className="srt-prescriber-informantion-grid-container"
            container
            spacing={2}
          >
            <Grid className="srt-prescriber-informantion-item" item xs={12}>
              <InputWithLabel label="" isRequired={false}>
                <Button
                  classes={{ root: "srt-prescriber-informantion-button" }}
                  data-testid="srt-prescriber-informantion-button"
                  onClick={searchPrescriberButtonAction}
                  startIcon={<SearchIcon />}
                  variant="outlined"
                >
                  Search for Prescriber
                </Button>
              </InputWithLabel>
            </Grid>
          </Grid>
        )}
        <Popup
          openFlag={openContainerPopupFlag}
          dialogParentClass="srt-prescriber-pop-up-container"
          closeHandler={handleClosePopUpAction}
          hideCloseButton={currentPopUp === PrescriberPopUp.LOADER}
        >
          <PrescriberInformationPopUpContainer
            closePopUp={handleClosePopUpAction}
            currentPopUp={currentPopUp}
            setCurrentPopUp={setCurrentPopUp}
            successAndClose={handleSetPrescriberAndClose}
            selectedNpi={
              prescriberInformation && prescriberInformation.npi
                ? prescriberInformation.npi
                : ""
            }
            selectedSiteUseId={selectedFacility.siteUseId}
            isComingFromSRT={true}
          />
        </Popup>
      </div>
    </div>
  );
};
