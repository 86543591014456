import moment from "moment";
import "./woundAssessmentReport.css";
import {
  IWoundAssessmentReport,
  IWoundAssessmentReportData,
} from "./woundAssessmentReport.interface";
import { useHistory } from "react-router-dom";
import ErrorPopup, {
  defaultErrorPopUp,
  IErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import { Popup } from "../../../core/popup/popup.component";
import { getPDFContent } from "../../../util/dischargeService";
import { useContext, useEffect, useRef, useState } from "react";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import {
  checkPopUpBlocker,
  getCSV,
  getPdfUrl,
  getSiteUseId,
  makeCapitalEachWordInString,
  showVacOrderMenuOption,
} from "../../../util/utilityFunctions";
import { AuthContext, AuthContextType } from "../../../context/AuthContext";
import { ReactComponent as DownloadIcon } from "../../../assets/download.svg";
import { Navigator } from "../../helpAndSupport/Navigator/navigator.component";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import { getWoundAssessmentReports } from "../../../util/woundAssessmentReportService";
import { ReactComponent as DownloadDisabled } from "../../../assets/downloadDisabled.svg";
import { WoundAssessmentReportTable } from "./components/table/woundAssessmentReportTable.component";
import { WoundAssessmentsReportFilter } from "./components/filter/woundAssessmentsReportFilter.component";
import { IWoundAssessmentsReportFilter } from "./components/filter/woundAssessmentsReportFilter.interface";
import { defaultWoundAssessmentsReportFilter } from "./components/filter/woundAssessmentsReportFilter.data";
import {
  OPEN_PDF_POP_UP_BLOCKED,
  SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
} from "../../../util/errorCode";
import {
  ERROR_MSG_GET_All_FACILITY,
  ERROR_MSG_GET_ASSESSMENTS_FOR_WOUND_ASSESSMENT_REPORT,
  ERROR_MSG_GET_PATIENTS_FOR_WOUND_ASSESSMENT_REPORT,
  ERROR_MSG_POPUP_BLOCKER,
} from "../../../util/errorMsg";
import {
  allFacilitySearch,
  caregiverFacilitySearch,
  checkPostAcuteFacility,
  getCSVDownload,
  getPatient,
  getPatientMenuAction,
} from "../../../util/3meService";
import { OrderDetailContext } from "../../../context/OrderDetailsContext";
import { OrderOverViewTabsTitle } from "../../myPatients/patientAndTherapyDetails/orderOverview/orderOverviewContainer.enum";
import { IFacility } from "../../manageProfile/facilityInformation/facility.interface";
import { selectFacility } from "../../../util/userService";
import { mapUserRolesAndPermissionData } from "../../../RolesPermission/RolesPermission.Mapper";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import { ICheckPostAcuteFacility } from "../../manageProfile/facilityInformation/facilityFound/facilityFound.interface";

export const WoundAssessmentReport = () => {
  const history = useHistory();

  const authObj = useContext<AuthContextType | null>(AuthContext);
  const orderDetailObj = useContext(OrderDetailContext);
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const [disableDownloadButton, setDisableDownloadButton] = useState(true);

  const [data, setData] = useState<IWoundAssessmentsReportFilter>(
    defaultWoundAssessmentsReportFilter
  );
  const latestDataRef = useRef(data);

  // Loader
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  // Error
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);

  // Full Data
  const [allReports, setAllReports] = useState<
    IWoundAssessmentReportData[] | null
  >(null);
  const [reports, setReports] = useState<IWoundAssessmentReport[] | null>(null);
  const [totalReportsCount, setTotalReportsCount] = useState<number>(0);

  // Pagination
  const [selectedSortField, setSelectedSortField] = useState(1);
  const [selectedSortOrder, setSelectedSortOrder] = useState(2);
  const [perPageCount, setPerPageCount] = useState<any>(25);
  const [selectedPageNumber, setSelectedPageNumber] = useState<any>(1);
  const [numberOfPages, setNumberOfPages] = useState<any[]>([]);
  const [allPageNumbers, setAllPageNumbers] = useState<any[]>([]);

  const getWoundAssessmentReportsAPI = async () => {
    const currentData = latestDataRef.current;
    const reqBody = {
      filterCriteria: currentData.searchInput.value,
      endDate: moment(currentData.endDate.value).format("YYYY-MM-DD"),
      pageIndex: selectedPageNumber,
      pageItemCount: perPageCount,
      sortColumn: selectedSortField,
      sortOrder: selectedSortOrder,
      startDate: moment(currentData.startDate.value).format("YYYY-MM-DD"),
      userName: authObj && authObj.userProfile!.userName,
    };
    setIsLoading(true);
    setReports([]);
    const response = await getWoundAssessmentReports(reqBody);
    if (response && response.succeeded && response.data !== null) {
      const result = {
        index: selectedPageNumber,
        items: response.data.woundAssessmentsList,
      };
      if (allReports) {
        let updateAllReports = [...allReports];
        updateAllReports.push(result);
        setAllReports(updateAllReports);
      } else {
        setAllReports([result]);
      }
      setTotalReportsCount(response.data.totalCount);
      setReports(response.data.woundAssessmentsList);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const openAssessmentAPI = async (recordId: string) => {
    const reqBody = {
      email: authObj!.userProfile?.userName,
      entityID: recordId,
      entityType: 1,
      siteUseId: getSiteUseId(authObj),
    };
    setShowLoader(true);
    const response = await getPDFContent(reqBody);
    setShowLoader(false);
    if (response && response.succeeded) {
      const url = getPdfUrl(response.data.file);
      const windowObject = window.open(url, "_blank");
      const isPopUpBlocked = checkPopUpBlocker(windowObject);
      if (isPopUpBlocked) {
        setError({
          isSupportPhoneRequired: false,
          errorCode: OPEN_PDF_POP_UP_BLOCKED,
          errorFlag: true,
          errorMessage: ERROR_MSG_POPUP_BLOCKER,
        });
      }
    } else {
      setError({
        errorCode:
          response.error?.errorCode || response.error?.code || response?.status,
        errorFlag: true,
        errorMessage: ERROR_MSG_GET_ASSESSMENTS_FOR_WOUND_ASSESSMENT_REPORT,
      });
    }
  };

  const getCSVForReportAPI = async () => {
    //csv reports mapping with required columns in sequence
    //this sequence should be and similar to the columns array sequence
    const columns: string[] = [
      "Record ID",
      "RO #",
      "Therapy Status",
      "Patient Name",
      "Patient ID",
      "Facility Name",
      "Facility Acct #",
      "Submitter",
      "Scan/Docs",
      "Assessment Type",
      "Assessment Range",
      "Created On",
      "FileNet upload",
    ];
    const csvPatients = reports!.map((report: IWoundAssessmentReport) => {
      return {
        recordId: report.recordID,
        roNumber: report.roNumber,
        therapyStatus: report.therapyStatus,
        patientName: makeCapitalEachWordInString(report.patientName),
        patientId: report.patientID
          ? makeCapitalEachWordInString(report.patientID)
          : "",
        facilityName: report.facilityName
          ? makeCapitalEachWordInString(report.facilityName)
          : "",
        facilityAccountNumber: report.facilityAccountNumber
          ? makeCapitalEachWordInString(report.facilityAccountNumber)
          : "",
        submitter: report.submitter
          ? makeCapitalEachWordInString(report.submitter)
          : "",
        woundDocScan: report.woundDocScan
          ? makeCapitalEachWordInString(report.woundDocScan)
          : "",
        assessmentType: makeCapitalEachWordInString(report.assessmentType),
        assessmentRange: `${
          report.cycleStartDate && report.cycleStartDate !== ""
            ? moment(report.cycleStartDate).format("MM/DD/YY")
            : "-"
        } - ${
          report.cycleStartDate && report.cycleStartDate !== ""
            ? moment(report.cycleEndDate).format("MM/DD/YY")
            : "-"
        }`,
        assessmentCreatedOn: moment(report.assessmentCreatedOn).format(
          "MM/DD/YY"
        ),
        fileNetUpload: report.fileNetUpload,
      };
    });
    const csvFileName: string = "Wound_Assessment_Report_";
    const reqsBody = {
      Columns: columns,
      Data: csvPatients,
    };
    setShowLoader(true);
    const response = await getCSVDownload(reqsBody);
    setShowLoader(false);
    if (response && response.succeeded) {
      const base64String = response.item;
      await getCSV(base64String, csvFileName);
    } else {
      setError({
        errorCode:
          response.error?.errorCode || response.error?.code || response?.status,
        errorFlag: true,
        errorMessage: ERROR_MSG_GET_ASSESSMENTS_FOR_WOUND_ASSESSMENT_REPORT,
      });
    }
  };

  const checkPostAcuteFacilityFound = async (typeCode: string) => {
    const reqBody = { FacilityTypeCode: typeCode };
    const response: ICheckPostAcuteFacility = await checkPostAcuteFacility(
      reqBody
    );
    return response.postAcuteFound;
  };

  const getCareGiverValue = async (facility: IFacility) => {
    let caregiverIdValue: string;
    const isCareGiver = facility.siteUseCode?.includes("CG");
    if (isCareGiver) {
      const careGiverIdRes = await caregiverFacilitySearch({
        customerAccountNumber: facility.accountNumber,
      });
      caregiverIdValue = careGiverIdRes.items[0].origSystemReference;
      return caregiverIdValue;
    } else {
      return "";
    }
  };

  const handleROClick = async (report: IWoundAssessmentReport) => {
    setShowLoader(true);
    let apiFailure: IErrorPopUp = {
      errorCode: "",
      errorFlag: false,
      errorMessage: "",
    };
    let registeredFacility: IFacility;
    const facilityReqBody = {
      customerName: "",
      customerNumber: report.facilityAccountNumber!,
      state: "",
    };
    const getAllFacilityResponse = await allFacilitySearch(facilityReqBody);
    if (getAllFacilityResponse && getAllFacilityResponse.succeeded) {
      registeredFacility = getAllFacilityResponse.items[0];
      registeredFacility.isPostAcute = await checkPostAcuteFacilityFound(
        registeredFacility.typeCode
      );
      registeredFacility.careGiverId = await getCareGiverValue(
        registeredFacility
      );
      authObj?.setAllFacilties(getAllFacilityResponse.items);
      authObj?.setregisteredFaciltyAddress(registeredFacility!);
      if (registeredFacility.siteUseId !== "") {
        const rolesAndPermissionsReqBody = {
          UserName: authObj?.userProfile?.userName,
          SiteUseId: registeredFacility.siteUseId,
        };
        const rolesAndPermissinsResponse = await selectFacility(
          rolesAndPermissionsReqBody
        );
        if (rolesAndPermissinsResponse) {
          const mappedRolesAndPermissionData =
            await mapUserRolesAndPermissionData(
              rolesAndPermissinsResponse,
              registeredFacility.readyCareFlag
            );
          permissionObj?.setMappedRolesPermissionData(
            mappedRolesAndPermissionData
          );
          let adminText: any = "Administration";
          if (authObj && authObj.unLinkedFacilitesCount) {
            adminText = (
              <div className="sideNavmadminBtnMain">
                Administration
                <span className="sideNavmadminBtnStyle">
                  {authObj.unLinkedFacilitesCount}{" "}
                </span>{" "}
              </div>
            );
          }
          authObj?.setsideNavMenuOptionModelData({
            ...authObj?.sideNavMenuOptionModelData,
            orders: {
              labelText: "Start New Order",
              isVisible: showVacOrderMenuOption(mappedRolesAndPermissionData),
            },
            inventory: {
              labelText: "Inventory",
              isVisible: mappedRolesAndPermissionData.IsShowInventoryOption,
            },
            administration: {
              labelText: adminText,
              isVisible: mappedRolesAndPermissionData.IsShowAdminstrationOption,
            },
          });
          authObj?.setuserRolesAndPermissionLoaded(true);
          authObj?.setIsInternalUserFacilitySelected(true);
          await openOrderOverviewAPI(report, registeredFacility.siteUseId!);
        }
      } else {
        setShowLoader(false);
        apiFailure = {
          errorFlag: true,
          errorCode: SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
          errorMessage: ERROR_MSG_GET_All_FACILITY,
        };
      }
    } else {
      setShowLoader(false);
      apiFailure = {
        errorFlag: true,
        errorCode:
          getAllFacilityResponse.error?.errorCode ||
          getAllFacilityResponse.error?.code ||
          getAllFacilityResponse?.status,
        errorMessage: ERROR_MSG_GET_All_FACILITY,
      };
    }
    setError(apiFailure);
  };

  const openOrderOverviewAPI = async (
    report: IWoundAssessmentReport,
    siteUseId: string
  ) => {
    let patient: any;
    let menuActions: any;
    const dateOfBirth = report.patientDOB
      ? moment(report.patientDOB).format("YYYY-MM-DD")
      : "";
    if (siteUseId !== "" && dateOfBirth !== "") {
      const response = await getPatient(
        report.roNumber,
        dateOfBirth,
        siteUseId,
        false
      );
      if (response && response.succeeded && response.data !== null) {
        patient = response.data;
        menuActions = await getPatientMenuAction(
          patient.type,
          patient.isFromAllFacility,
          report.roNumber,
          dateOfBirth,
          false
        );
        patient.color = menuActions.data.color;
        patient.statusColor = menuActions.data.color + "Circle";
        patient.menuActions = menuActions.data.menuActions;
        patient.siteUseId = siteUseId;
        orderDetailObj!.resetData();
        orderDetailObj!.resetSeletedSupplyOrderData();
        orderDetailObj!.resetWoundData();
        orderDetailObj?.setSelectedOrderTab(
          OrderOverViewTabsTitle.WOUND_PROGRESS_TAB_TITLE
        );
        history.push({
          pathname: "/home/orderOverview",
          state: {
            stateData: patient,
          },
        });
        window.scrollTo(0, 0);
      } else {
        setError({
          errorCode:
            response.error?.errorCode ||
            response.error?.code ||
            response?.status,
          errorFlag: true,
          errorMessage: ERROR_MSG_GET_PATIENTS_FOR_WOUND_ASSESSMENT_REPORT,
        });
      }
      setShowLoader(false);
    }
  };

  const downloadButtonAction = async () => {
    if (reports && reports.length > 0) {
      getCSVForReportAPI();
    }
  };

  // Pagination Methods
  const handleSelectChange = (e: any) => {
    e.preventDefault();
    clearResult();
    let count = parseInt(e.target.value);
    setPerPageCount(count);
  };

  const handlePreviousClick = () => {
    setSelectedPageNumber((prev: number) => (prev <= 1 ? prev : prev - 1));
  };

  const handleNextClick = () => {
    setSelectedPageNumber((next: number) =>
      next >= numberOfPages.length ? next : next + 1
    );
  };

  const handlePageNumberClick = (item: any) => {
    if (typeof item === "string" && item.includes("...")) {
      getPageNumbers(item);
    } else {
      setSelectedPageNumber(parseInt(item));
    }
  };

  const handleSorting = (sortField: any, sortOrder: any) => {
    setSelectedSortField(sortField);
    setSelectedSortOrder(sortOrder === "asc" ? 1 : 2);
    clearResult();
  };

  const clearResult = () => {
    setAllReports([]);
    setSelectedPageNumber(1);
  };

  const getPageNumbers = (pageNumber: any) => {
    if (reports && reports.length > 0) {
      const howManyPages = Math.ceil(totalReportsCount / perPageCount);
      const pages: any[] = [];
      for (let i = 1; i <= howManyPages; i++) {
        pages.push(i);
      }
      setNumberOfPages(pages);
      var tempNumberOfPages: any[] = [];
      tempNumberOfPages = [...allPageNumbers];
      let dotsInitial = "...";
      let dotsLeft = "... ";
      let dotsRight = " ...";
      if (pages.length < 6) {
        tempNumberOfPages = pages;
      } else if (pageNumber >= 1 && pageNumber <= 3) {
        tempNumberOfPages = [1, 2, 3, 4, dotsInitial, pages.length];
      } else if (pageNumber === 4) {
        const sliced = pages.slice(0, 4);
        tempNumberOfPages = [...sliced, dotsInitial, pages.length];
      } else if (pageNumber > 4 && pageNumber < pages.length - 2) {
        // from 5 to 8 -> (10 - 2)
        const sliced1 = pages.slice(pageNumber - 2, pageNumber);
        // sliced1 (5-2, 5) -> [4,5]
        const sliced2 = pages.slice(pageNumber, pageNumber + 1);
        // sliced1 (5, 5+1) -> [6]
        tempNumberOfPages = [
          1,
          dotsLeft,
          ...sliced1,
          ...sliced2,
          dotsRight,
          pages.length,
        ];
        // [1, '...', 4, 5, 6, '...', 10]
      } else if (pageNumber > pages.length - 3) {
        // > 7
        const sliced = pages.slice(pages.length - 4);
        // slice(10-4)
        tempNumberOfPages = [1, dotsLeft, ...sliced];
      } else if (pageNumber === dotsInitial) {
        // [1, 2, 3, 4, "...", 10].length = 6 - 3  = 3
        // arrOfCurrButtons[3] = 4 + 1 = 5
        // or
        // [1, 2, 3, 4, 5, "...", 10].length = 7 - 3 = 4
        // [1, 2, 3, 4, 5, "...", 10][4] = 5 + 1 = 6
        setSelectedPageNumber(allPageNumbers[allPageNumbers.length - 3] + 1);
      } else if (pageNumber === dotsRight) {
        setSelectedPageNumber(allPageNumbers[3] + 2);
      } else if (pageNumber === dotsLeft) {
        setSelectedPageNumber(allPageNumbers[3] - 2);
      }
      setAllPageNumbers(tempNumberOfPages);
    } else {
      if (!isLoading) {
        setAllPageNumbers([]);
        setNumberOfPages([]);
        setSelectedPageNumber(1);
      }
    }
  };

  const getReports = () => {
    const index = allReports
      ? allReports.findIndex(
          (data: IWoundAssessmentReportData) =>
            data.index === selectedPageNumber
        )
      : -1;
    if (index > -1) {
      const result = allReports![index];
      setReports(result.items);
    } else {
      getWoundAssessmentReportsAPI();
    }
  };

  useEffect(() => {
    clearResult();
    latestDataRef.current = data;
  }, [data]);

  useEffect(() => {
    getReports();
  }, [
    latestDataRef.current,
    perPageCount,
    selectedPageNumber,
    selectedSortField,
    selectedSortOrder,
  ]);

  useEffect(() => {
    getPageNumbers(selectedPageNumber);
    setDisableDownloadButton((reports && reports.length === 0) ?? true);
  }, [reports, totalReportsCount]);

  return (
    <div
      className="wound-assessment-report-component"
      data-testid="wound-assessment-report-component"
    >
      <div
        className="wound-assessment-report-container"
        data-testid="wound-assessment-report-container"
      >
        <Navigator
          array={[
            {
              route: "/administration",
              pageName: "Administration",
            },
          ]}
          className="wound-assessment-report-route-section"
          title="Wound Assessment Report"
        />
        <div
          className="wound-assessment-report-header"
          data-testid="wound-assessment-report-header"
        >
          <p
            className="wound-assessment-report-title"
            data-testid="wound-assessment-report-title"
            id="wound-assessment-report-title"
          >
            Wound Assessment Report
          </p>
          <div
            className="wound-assessment-report-header-details"
            data-testid="wound-assessment-report-header-details"
          >
            <p
              className="wound-assessment-report-order-returned"
              data-testid="wound-assessment-report-order-returned"
              id="wound-assessment-report-order-returned"
            >
              {`${totalReportsCount} assessments returned`}
            </p>
            <div className="wound-assessment-report-download-button-component">
              <ExpressButton
                clickHandler={downloadButtonAction}
                disabled={disableDownloadButton}
                parentClass="wound-assessment-report-download-button"
                startIcon={
                  disableDownloadButton ? (
                    <DownloadDisabled />
                  ) : (
                    <DownloadIcon />
                  )
                }
                testId="wound-assessment-report-download-button"
                variant="text"
              >
                Download Report
              </ExpressButton>
            </div>
          </div>
          <WoundAssessmentsReportFilter data={data} setData={setData} />
          <WoundAssessmentReportTable
            allPageNumbers={allPageNumbers}
            handleNextClick={handleNextClick}
            handlePageNumberClick={handlePageNumberClick}
            handlePreviousClick={handlePreviousClick}
            handleSelectChange={handleSelectChange}
            handleSorting={handleSorting}
            isLoading={isLoading}
            openAssessment={openAssessmentAPI}
            openOrderOverview={handleROClick}
            numberOfPages={numberOfPages}
            perPageCount={perPageCount}
            reports={reports ?? []}
            selectedPageNumber={selectedPageNumber}
            totalReportsCount={totalReportsCount}
          />
        </div>
      </div>
      <Popup
        closeHandler={() => setShowLoader(false)}
        dialogParentClass="wound-assessment-report-container-popup"
        hideCloseButton={true}
        openFlag={showLoader}
      >
        <div className="wound-assessment-report-container-popup-div">
          <LoadingSpinner />
        </div>
      </Popup>
      <ErrorPopup
        buttontitle="Done"
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        handleBackButton={() => setError(defaultErrorPopUp)}
        isShortSentence={true}
        isSupportPhoneRequired={error.isSupportPhoneRequired ?? true}
        popUpStyles="errorPopup"
        showTitle={false}
      />
    </div>
  );
};
