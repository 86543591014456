import { useMediaQuery, InputBase, TextField, Grid } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useContext, useState } from "react";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { convertStringToDate } from "../../../../util/utilityFunctions";
import { ReactComponent as CalendarIcon } from "../../../../assets/calendar.svg";
import { ISRTPatientInformationProps } from "../../salesRoundingTool.interface";
import "./patientInformation.css";
import { SalesRoundingValidator } from "../../salesRoundingTool.validator";
import {
  ISalesRoundingContextType,
  SaleRoundingToolContext,
} from "../../../../context/SalesRoundingToolContext";

export const PatientInformation = ({
  data,
  setData,
  patientInformation,
}: ISRTPatientInformationProps) => {
  const [focusClasses, setFocusClasses] = useState({ dateOfBirth: "" });
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const validator = new SalesRoundingValidator();
  const setClasses = (e: any, classname: string) => {
    setFocusClasses(
      Object.assign({}, focusClasses, { [e.target.name]: classname })
    );
  };
  const salesRoundingObj = useContext<ISalesRoundingContextType | null>(
    SaleRoundingToolContext
  );

  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const isValid = validator.validate(value, name);
    const tempData = {
      ...data,
      patientInformation: {
        ...data.patientInformation,
        [name]: {
          ...Object(data.patientInformation)[name],
          errorMessage: null,
          valid: isValid!.status,
          value: value,
        },
      },
    };
    setData(tempData);
    salesRoundingObj?.setSrtPatientDetailsUpdated(true);
  };

  const validateAndSetDate = (date: string | null | undefined) => {
    const formattedDate = date ? convertStringToDate(date) : "";
    const isValid = validator.validate(formattedDate!, "dateOfBirth");
    const tempData = {
      ...data,
      patientInformation: {
        ...data.patientInformation,
        dateOfBirth: {
          ...data.patientInformation.dateOfBirth,
          errorMessage: null,
          valid: isValid!.status,
          value: formattedDate ?? "",
        },
      },
    };
    setData(tempData);
    salesRoundingObj?.setSrtPatientDetailsUpdated(true);
  };

  return (
    <div
      className="sales-rounding-tool-patient-information-component"
      data-testid="sales-rounding-tool-patient-information-component"
    >
      <p
        className="sales-rounding-tool-patient-information-title"
        data-testid="sales-rounding-tool-patient-information-title"
        id="sales-rounding-tool-patient-information-title"
      >
        Patient Information
      </p>
      <Grid
        className="sales-rounding-tool-patient-information-grid-container"
        container
      >
        <Grid
          className="sales-rounding-tool-patient-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={
              patientInformation.firstName.valid === ValidationStatus.INVALID
            }
            id={patientInformation.firstName.componentId!}
            isRequired={patientInformation.firstName.required}
            isShowWarning={true}
            label={patientInformation.firstName.title!}
            labelClassName="patient-information-title"
            testId={patientInformation.firstName.componentId!}
            warningMessage={patientInformation.firstName.errorMessage}
          >
            <InputBase
              className="patient-information-input"
              data-testid={patientInformation.firstName.id!}
              id={patientInformation.firstName.id!}
              name="firstName"
              onChange={validateAndSetData}
              value={patientInformation.firstName.value}
              inputProps={{ maxLength: 15 }}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="sales-rounding-tool-patient-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={
              patientInformation.lastName.valid === ValidationStatus.INVALID
            }
            id={patientInformation.lastName.componentId!}
            isRequired={patientInformation.lastName.required}
            isShowWarning={true}
            label={patientInformation.lastName.title!}
            labelClassName="patient-information-title"
            testId={patientInformation.lastName.componentId!}
            warningMessage={patientInformation.lastName.errorMessage}
          >
            <InputBase
              className="patient-information-input"
              data-testid={patientInformation.lastName.id!}
              id={patientInformation.lastName.id!}
              name="lastName"
              onChange={validateAndSetData}
              value={patientInformation.lastName.value}
              inputProps={{ maxLength: 15 }}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="sales-rounding-tool-patient-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={
              patientInformation.dateOfBirth.valid === ValidationStatus.INVALID
            }
            id={patientInformation.dateOfBirth.componentId!}
            isRequired={patientInformation.dateOfBirth.required}
            isShowWarning={true}
            label={patientInformation.dateOfBirth.title!}
            labelClassName={focusClasses.dateOfBirth}
            testId={patientInformation.dateOfBirth.componentId!}
            warningMessage={patientInformation.dateOfBirth.errorMessage}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                components={{ OpenPickerIcon: CalendarIcon }}
                InputAdornmentProps={{
                  classes: {
                    root: "adornedRoot",
                  },
                }}
                InputProps={{
                  classes: {
                    root: `sales-rounding-tool-patient-information-date-of-birth ${
                      patientInformation.dateOfBirth.valid ===
                      ValidationStatus.INVALID
                        ? "show-error"
                        : "no-error"
                    }`,
                    input: "input",
                    notchedOutline: "outline",
                  },
                }}
                onChange={(value) => validateAndSetDate(value)}
                renderInput={(params) => {
                  params.inputProps!.placeholder = "__/__/____";
                  return (
                    <TextField
                      id={patientInformation.dateOfBirth.id!}
                      name="dateOfBirth"
                      onFocus={(e) => setClasses(e, "Mui-focused")}
                      onBlur={(e) => setClasses(e, "")}
                      {...params}
                    />
                  );
                }}
                value={patientInformation.dateOfBirth.value}
              />
            </LocalizationProvider>
          </InputWithLabel>
        </Grid>
        <Grid
          className="sales-rounding-tool-patient-information-grid-item"
          item
          xs={isMobileScreen ? 12 : 6}
        >
          <InputWithLabel
            error={patientInformation.room.valid === ValidationStatus.INVALID}
            id={patientInformation.room.componentId!}
            isRequired={patientInformation.room.required}
            isShowWarning={true}
            label={patientInformation.room.title!}
            labelClassName="patient-information-title"
            testId={patientInformation.room.componentId!}
            warningMessage={patientInformation.room.errorMessage}
          >
            <InputBase
              className="patient-information-input"
              data-testid={patientInformation.room.id!}
              id={patientInformation.room.id!}
              name="room"
              onChange={validateAndSetData}
              value={patientInformation.room.value}
              inputProps={{ maxLength: 8 }}
            />
          </InputWithLabel>
        </Grid>
        <Grid
          className="sales-rounding-tool-patient-information-grid-item"
          item
          xs={12}
        >
          <InputWithLabel
            error={
              patientInformation.patientId.valid === ValidationStatus.INVALID
            }
            id={patientInformation.patientId.componentId!}
            isRequired={patientInformation.patientId.required}
            isShowWarning={true}
            label={patientInformation.patientId.title!}
            labelClassName="patient-information-title"
            testId={patientInformation.patientId.componentId!}
            warningMessage={patientInformation.patientId.errorMessage}
          >
            <InputBase
              className="patient-information-input"
              data-testid={patientInformation.patientId.id!}
              id={patientInformation.patientId.id!}
              name="patientId"
              onChange={validateAndSetData}
              value={patientInformation.patientId.value}
              inputProps={{ maxLength: 12 }}
            />
          </InputWithLabel>
        </Grid>
      </Grid>
    </div>
  );
};
