import { ReactComponent as PrintIcon } from "../../../../assets/print.svg";
import { IPendingSupplyOrderInterface } from "./pendingSupplyOrder.interface";
import { WindowService } from "../../../../util/window.service";
import { AlertDetails } from "../alertDetails/alertDetails.component";
import { getCMSContent } from "../../../../util/cmsService";
import {
  CMS_HELPSUPPORT_CONTENT,
  LETTER_FOR_EXCESSIVE_SUPPLIES,
} from "../../../../util/staticText";
import "./pendingSupplyOrder.css";
import { IHelpFile } from "../../../helpAndSupport/helpAndSupport.interface";
import { useState } from "react";
import ErrorPopup from "../../../../core/errorPopup/errorPopup.component";
import { LoadingSpinner } from "../../../../core/loader/LoadingSpinner";
import { ERROR_MSG_EXCESSIVE_SUPPLY_PDF_FAIL } from "../../../../util/errorMsg";
import { Popup } from "../../../../core/popup/popup.component";

export const PendingSupplyOrder = ({
  closePopUpAction,
}: IPendingSupplyOrderInterface) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorPopUpFlag, setErrorPopUpFlag] = useState<boolean>(false);
  const fetchExcessiveSupplyFormLink = async () => {
    const data = await getCMSContent(CMS_HELPSUPPORT_CONTENT);
    setShowLoader(true);
    if (data?.item?.resources) {
      const excessiveSupplyResource = data.item.resources.find(
        (resource: IHelpFile) =>
          resource.labelText === LETTER_FOR_EXCESSIVE_SUPPLIES
      );
      if (excessiveSupplyResource && excessiveSupplyResource.fileLink) {
        return excessiveSupplyResource.fileLink;
      }
    } else {
      setErrorMessage(ERROR_MSG_EXCESSIVE_SUPPLY_PDF_FAIL);
      setErrorPopUpFlag(true);
      setErrorCode(data?.error?.code || data?.error?.errorCode || data?.status);
    }
    setShowLoader(false);
    return null;
  };

  const handleButtonClick = async () => {
    closePopUpAction();
    const excessiveSupplyFormLink = await fetchExcessiveSupplyFormLink();
    if (excessiveSupplyFormLink) {
      const windowService = new WindowService();
      windowService.openPdf(excessiveSupplyFormLink);
    } else {
      setErrorMessage(ERROR_MSG_EXCESSIVE_SUPPLY_PDF_FAIL);
      setErrorPopUpFlag(true);
    }
  };

  return (
    <div>
      <AlertDetails
        title="Excessive Supply"
        titleClassName="pending-supply-order-header-title"
        body="Supplies are outside of the anniversary time frame"
        footer="Print Excessive Supply Form"
        buttonIcon={<PrintIcon />}
        buttonOnClick={handleButtonClick}
        showCallForAssistance={true}
      />
      <Popup
        openFlag={showLoader}
        closeHandler={() => setShowLoader(false)}
        dialogParentClass={"pending-supply-order-component-loader-pop-up"}
        data-testid="pending-supply-order-component-loader -pop-up"
        hideCloseButton={true}
      >
        <div
          className="pending-supply-order-component-loader"
          data-testid="pending-supply-order-component-loader"
        >
          <LoadingSpinner />
        </div>
      </Popup>
      <ErrorPopup
        errorPopupFlag={errorPopUpFlag}
        handleBackButton={() => setErrorPopUpFlag(false)}
        popUpStyles="errorPopup"
        errorMessage={errorMessage}
        buttontitle="Done"
        showTitle={false}
        isShortSentence={true}
        errorCode={errorCode}
      />
    </div>
  );
};
