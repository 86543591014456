import { IPatientInformation } from "../../components/inpatientOrder/components/patientInformation/patientInformation.interface";
import { IPrescriberModal } from "../../components/newOrder/prescriberInformation/prescriberSearch/prescriberSearch.model";
import {
  IInputField,
  ValidationStatus,
} from "../../core/interfaces/input.interface";
import { defaultSrtServiceCoveringInformation } from "./components/serviceCoveringInformation/serviceCoveringInformation.model";
import { defaultSrtStatusData } from "./components/srtPatientStatus/srtPatientStatus.model";
import { defaultSrtTherapyInformation } from "./components/srtTherapyInformation/srtTherapyInformation.model";
import {
  ISalesRoundingPatientDetails,
  ISRTOrderInformation,
  IWoundInformation,
  IWoundInformationDropDownValues,
  IWoundInformationSection,
} from "./salesRoundingTool.interface";

export const defaultPatientInformationData: IPatientInformation = {
  firstName: {
    componentId: "sales-rounding-tool-patient-information-first-name",
    id: "sales-rounding-tool-patient-information-first-name-input",
    order: 1,
    required: true,
    title: "Patient First Name",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  lastName: {
    componentId: "sales-rounding-tool-patient-information-last-name",
    id: "sales-rounding-tool-patient-information-last-name-input",
    required: true,
    order: 2,
    title: "Patient Last Name",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  dateOfBirth: {
    componentId: "sales-rounding-tool-patient-information-date-of-birth",
    id: "sales-rounding-tool-patient-information-date-of-birth-input",
    order: 3,
    required: true,
    title: "Date of Birth",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  room: {
    componentId: "sales-rounding-tool-patient-information-room",
    id: "sales-rounding-tool-patient-information-room-input",
    required: true,
    order: 4,
    title: "Room #",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  patientId: {
    componentId: "sales-rounding-tool-patient-information-patient-id",
    id: "sales-rounding-tool-patient-information-patient-id-input",
    isDefaultValid: true,
    order: 5,
    title: "Patient Id",
    valid: ValidationStatus.VALID,
    value: "",
  },
};

export const defaultOrderInformation: ISRTOrderInformation = {
  roNumber: "",
  serialNumber: "",
  placementType: "",
  product: "",
};

export const defaultAttendingPhysician: IPrescriberModal = {
  firstName: "",
  lastName: "",
  npi: "",
  city: "",
  state: "",
  telephoneNumber: "",
  zipCode: "",
  address1: "",
  address2: "",
};

export const defaultPrescribingPhysician: IPrescriberModal = {
  firstName: "",
  lastName: "",
  npi: "",
  city: "",
  state: "",
  telephoneNumber: "",
  zipCode: "",
  address1: "",
  address2: "",
};
export let defaultWoundInfoCount: IInputField = {
  componentId: "sales-rounding-wound-qty-count",
  id: "sales-rounding-wound-qty-count",
  isDefaultValid: true,
  required: false,
  title: "",
  valid: ValidationStatus.VALID,
  value: "0",
};
export const defaultPrimaryWoundInfo: IWoundInformationSection = {
  woundType: {
    componentId: "sales-rounding-wound-type",
    id: "sales-rounding-wound-type-input",
    order: 12,
    required: true,
    title: "Primary Wound Type",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundLocation: {
    componentId: "sales-rounding-wound-location",
    id: "sales-rounding-wound-location-input",
    order: 13,
    required: true,
    title: "Wound Location",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundDirection: {
    componentId: "sales-rounding-wound-direction",
    id: "sales-rounding-wound-direction-input",
    isOptional: true,
    order: 14,
    required: false,
    title: "Wound Direction",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundOrientation: {
    componentId: "sales-rounding-wound-orientation",
    id: "sales-rounding-wound-orientation-input",
    isOptional: true,
    order: 15,
    required: false,
    title: "Wound Orientation",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundMeasurementDate: {
    componentId: "sales-rounding-wound-measurment-date",
    id: "sales-rounding-wound-measurment-date-input",
    order: 16,
    required: true,
    title: "Wound Measurement Date",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundLength: {
    componentId: "sales-rounding-wound-length",
    id: "sales-rounding-wound-length-input",
    isOptional: true,
    order: 17,
    required: false,
    title: "Length",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundWidth: {
    componentId: "sales-rounding-wound-width",
    id: "sales-rounding-wound-width-input",
    isOptional: true,
    order: 18,
    required: false,
    title: "Width",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundDepth: {
    componentId: "sales-rounding-wound-depth",
    id: "sales-rounding-wound-depth-input",
    isOptional: true,
    order: 19,
    required: false,
    title: "Depth",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundVolume: {
    componentId: "sales-rounding-wound-volume",
    id: "sales-rounding-wound-input",
    isOptional: true,
    required: false,
    title: "Volume",
    valid: ValidationStatus.VALID,
    value: "",
  },
};

export const defaultSecondaryWoundInfo: IWoundInformationSection = {
  woundType: {
    componentId: "sales-rounding-secondary-wound-type",
    id: "sales-rounding-secondary-wound-type-input",
    order: 20,
    required: true,
    title: "Secondary Wound Type",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundLocation: {
    componentId: "sales-rounding-secondary-wound-location",
    id: "sales-rounding-secondary-wound-location-input",
    order: 21,
    required: true,
    title: "Wound Location",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundDirection: {
    componentId: "sales-rounding-secondary-wound-direction",
    id: "sales-rounding-secondary-wound-direction-input",
    isOptional: true,
    order: 22,
    required: false,
    title: "Wound Direction",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundOrientation: {
    componentId: "sales-rounding-secondary-wound-orientation",
    id: "sales-rounding-secondary-wound-orientation-input",
    isOptional: true,
    order: 23,
    required: false,
    title: "Wound Orientation",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundMeasurementDate: {
    componentId: "sales-rounding-secondary-wound-measurment-date",
    id: "sales-rounding-secondary-wound-measurment-date-input",
    order: 24,
    required: true,
    title: "Wound Measurement Date",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundLength: {
    componentId: "sales-rounding-secondary-wound-length",
    id: "sales-rounding-secondary-wound-length-input",
    isOptional: true,
    order: 25,
    required: false,
    title: "Length",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundWidth: {
    componentId: "sales-rounding-secondary-wound-width",
    id: "sales-rounding-secondary-wound-width-input",
    title: "Width",
    isOptional: true,
    order: 26,
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundDepth: {
    componentId: "sales-rounding-secondary-wound-depth",
    id: "sales-rounding-secondary-wound-depth-input",
    isOptional: true,
    order: 27,
    required: false,
    title: "Depth",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundVolume: {
    componentId: "sales-rounding-secondary-wound-volume",
    id: "sales-rounding-secondary-wound-input",
    title: "Volume",
    isOptional: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
};

export const defaultWoundInformationDropDownValues: IWoundInformationDropDownValues =
  {
    woundDirectionDropDownValues: [],
    woundLocationDropDownValues: [],
    woundOrientationDropDownValues: [],
    woundTypeDropDownValues: [],
  };

export const defaultWoundInformationData: IWoundInformation = {
  woundInfoCount: defaultWoundInfoCount,
  primaryWoundInformation: defaultPrimaryWoundInfo,
  secondaryWoundInformation: defaultSecondaryWoundInfo,
};
export const defaultWoundInfoField: IInputField = {
  hasChildsToValidate: true,
  required: false,
  isDefaultValid: true,
  valid: ValidationStatus.VALID,
  value: defaultWoundInformationData,
};
export const defaultSalesRoundingPatientDetails: ISalesRoundingPatientDetails =
  {
    patientInformation: defaultPatientInformationData,
    orderInformation: defaultOrderInformation,
    patientStatus: defaultSrtStatusData,
    therapyInformation: defaultSrtTherapyInformation,
    attendingPhysician: defaultAttendingPhysician,
    prescribingPhysician: defaultPrescribingPhysician,
    patientWoundInformation: defaultWoundInfoField,
    serviceCoveringInformation: defaultSrtServiceCoveringInformation,
  };
