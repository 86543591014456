import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { ISalesRoundingTherapyInformation } from "./srtTherapyInformation.interface";

export const defaultSrtTherapyInformation: ISalesRoundingTherapyInformation = {
  transitionTherapyStartDate: {
    componentId: "srt-transition-therapy-start-date",
    id: "srt-transition-therapy-start-date-input",
    order: 9,
    required: false,
    title: "Transition Therapy Start Date",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },

  leadGeneration: {
    componentId: "srt-lead-generation",
    id: "srt-lead-generation-select",
    order: 10,
    required: false,
    title: "Lead Generation",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
};
