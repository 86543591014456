import moment from "moment";
import {
  InpatientOrderPageSection,
  InpatientOrderSubmission,
} from "../inpatientOrder.enum";
import {
  SVC_GET_CUSTOMER_INFORMATION_ERROR,
  SVC_GET_SOLVENTUM_PRODUCT,
  SVC_IN_PATIENT_ORDER_SUBMIT,
  SVC_ORDER_NOT_SUBMITTED,
  SVC_SOLVENTUM_PRODUCT_IN_PATIENT_ORDER_SUBMIT,
  SVC_STORED_PRODUCT_IN_PATIENT_ORDER_SUBMIT,
  SVC_WOUND_NOT_SUBMITTED,
} from "../../../util/errorMsg";
import {
  IN_PATIENT_ORDER_BILLING_ADDRESSES_MISSING,
  IN_PATIENT_ORDER_INVALID_DATE_OF_BIRTH,
  REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE,
  SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
  USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE,
} from "../../../util/errorCode";
import {
  DD_ACUTE_WOUND_LOCATION,
  DD_ACUTE_WOUND_TYPE,
  DD_NEED_BY_TIME_CONTENT,
  DD_WOUND_DIRECTION,
  DD_WOUND_ORIENTATION,
} from "../../../util/staticText";
import "./inpatientOrderContainer.css";
import {
  getCustomerInformation,
  getProductPriceAndAvailability,
} from "../../../util/inPatientOrderService";
import { format } from "react-string-format";
import {
  InpatientOrderContext,
  InpatientOrderContextType,
} from "../../../context/InpatientOrderContext";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import {
  IInputField,
  ValidationStatus,
} from "../../../core/interfaces/input.interface";
import ErrorPopup, {
  IErrorPopUp,
  defaultErrorPopUp,
} from "../../../core/errorPopup/errorPopup.component";
import { useHistory, useLocation } from "react-router-dom";
import { Popup } from "../../../core/popup/popup.component";
import {
  deliveryPrefernceRoundToNearestMinutes,
  getCodeFromText,
  getCustomerFacilityAccountNumber,
  getCustomerMasterNumber,
  getDevicePlacementType,
  getSiteUseId,
  inPatientformatISODate,
} from "../../../util/utilityFunctions";
import {
  IInpatientOrder,
  IInpatientOrderSecondPage,
  IReSubmitAcuteOrderRequest,
  IReSubmitAcuteWoundInfo,
  ISubmitAcuteOrderFailureScreens,
} from "../inpatientOrder.interface";
import ProgressBar from "../../progressBar/progressBar.component";
import {
  getdropDownContent,
  getDropDownContent,
} from "../../../util/dropDownService";
import { ReactNode, useContext, useEffect, useState } from "react";
import {
  getAcuteOrderSummary,
  getAcuteProductImageLink,
  getOrderSuppliesByFacility,
  submitAcuteOrderDetails,
} from "../../../util/actueService";
import { LoadingSpinner } from "../../../core/loader/LoadingSpinner";
import { InpatientOrderValidator } from "../inpatientOrder.validator";
import { AuthContextType, AuthContext } from "../../../context/AuthContext";
import {
  IPatientDetail,
  IProductDetail,
} from "../components/patientAndProductDetail/patientAndProductDetail.interface";
import {
  ICustomerPlusListingReqBody,
  IInventoryProductDetails,
  ISolventumProduct,
} from "../../inventory/inventoryTabContainer.enum";
import { IBillingAddress } from "../components/billingAddress/billingAddress.interface";
import { InpatientOrderFirstPage } from "../firstPage/inpatientOrderFirstPage.component";
import {
  mapAcuteOrderRequest,
  mapResubmitAcuteOrderRequest,
} from "../components/mapper/inpatientOrder.request.mapper";
import { InpatientOrderFooter } from "../components/footer/inpatientOrderFooter.component";
import { InpatientOrderReviewPage } from "../reviewPage/inpatientOrderReviewPage.component";
import { InpatientOrderSecondPage } from "../secondPage/inpatientOrderSecondPage.component";
import { InpatientOrderSummaryPage } from "../summaryPage/inpatientOrderSummaryPage.component";
import { ISubmitAcuteOrderRequest } from "../components/mapper/inpatientOrder.request.interface";
import { IPatientInformation } from "../components/patientInformation/patientInformation.interface";
import {
  CustomerPlusListingTableTypes,
  ICustomerPlusListingDetails,
} from "../../inventory/customerPlusInventory/customerPlusInventory.interface";
import {
  DevicePlacementTabs,
  PlacementTypeSelection,
} from "../components/devicePlacement/devicePlacemet.enums";
import {
  getCustomerPlusListData,
  getInventoryInfoList,
  getOrderWithSolventumProduct,
} from "../../../util/inventoryMgrService";
import {
  mapInPatientOrderResponse,
  mapInPatientSecondPageResponse,
} from "../components/mapper/response/inpatientOrder.Response.Mapper";
import {
  InPatientOrderSummaryData,
  IOrderSuppliesResult,
} from "../components/mapper/response/inPatientOrder.Response.interface";
import { getCurrentServerDateTime } from "../../../util/3meService";
import { defaultReplacementInformationData } from "../components/replacementInformation/replacementInformation.interface";
import { defaultDeliveryPreferenceData } from "../components/deliveryPreference/deliveryPreference.model";
import InpatientOrderPopup from "../components/inpatientPopups/InpatientOrderPopupContainer.component";
import { IInPatientOrderPopupSection } from "../components/inpatientPopups/inpatientOrderPopup.enum";
import {
  reSubmitAcuteOrderDetails,
  reSubmitAcuteOrderWoundDetails,
} from "../../../util/orderMgrService";
import { InPatientSupplyOrderFirstPage } from "../../inpatientSupplyOrder/firstPage/inPatientSupplyOrderFirstPage.component";
import { useCart } from "../../../context/CartContext";
import { ProductAvailibiltyPopup } from "../../inpatientSupplyOrder/component/popup/productAvailibilityPopup/productAvailibilityPopup.component";
import {
  defaultFilterValues,
  IFilterValues,
} from "../../inpatientSupplyOrder/container/inpatientSupplyOrderContainer.component";
import { IInPatientSupplyOrderRequest } from "../../inpatientSupplyOrder/inpatientSupplyOrder.interface";
import { IBillingInformation } from "../components/billingInformation/billingInformation.interface";
import {
  InpatientSupplyOrderContext,
  InpatientSupplyOrderContextType,
} from "../../../context/InpatientSupplyOrderContext";

export interface IDevicePlacementTableData {
  customerPlusListingData: ICustomerPlusListingDetails[];
  storedProductListingData: IInventoryProductDetails[];
}

export const defaultDevicePlacementTableData: IDevicePlacementTableData = {
  customerPlusListingData: [],
  storedProductListingData: [],
};

export const InpatientOrderContainer = () => {
  const history = useHistory();
  const location: any = useLocation();
  const { cartState, resetCart, dispatch } = useCart();
  const [error, setError] = useState<IErrorPopUp>(defaultErrorPopUp);
  const [isAutoFocusEnabled, setIsAutoFocusEnabled] = useState<boolean>(true);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [devicePlacementSelectedTab, setDevicePlacementSelectedTab] =
    useState<DevicePlacementTabs>(DevicePlacementTabs.STORED_PRODUCT_TAB);
  const [openAcuteOrderFailurepopup, setOpenAcuteOrderFailurepopup] =
    useState<boolean>(false);
  const [popupSection, setPopupSection] = useState<IInPatientOrderPopupSection>(
    IInPatientOrderPopupSection.RE_SUBMIT_WOUND_IFNO_ACUTE_ORDER
  );
  const [placementTypeChanged, setPlacementTypeChanged] =
    useState<boolean>(false);
  const allProducts:
    | ICustomerPlusListingDetails[]
    | IInventoryProductDetails[] = location.state?.allProducts;
  const selectedProdcut:
    | ICustomerPlusListingDetails
    | IInventoryProductDetails = location.state?.product;
  const [openCart, setOpenCart] = useState<boolean>(false);
  const [productAvailibiltyPopup, setProductAvailibiltyPopup] =
    useState<boolean>(false);
  const [filterValues, setFilterValues] =
    useState<IFilterValues>(defaultFilterValues);

  const authObj = useContext<AuthContextType | null>(AuthContext);
  const inpatientOrderObj = useContext<InpatientOrderContextType | null>(
    InpatientOrderContext
  );
  const inPatientSupplyOrderObj =
    useContext<InpatientSupplyOrderContextType | null>(
      InpatientSupplyOrderContext
    );
  const permissionObj = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );
  const data = inpatientOrderObj!.data;
  const setData = inpatientOrderObj!.setData;
  const isSuppliesDataAvailable = inpatientOrderObj!.isSuppliesDataAvailable;
  const setIsSuppliesDataAvailable =
    inpatientOrderObj!.setIsSuppliesDataAvailable;
  const secondPageData = inpatientOrderObj!.secondPageData;
  const setSecondPageData = inpatientOrderObj!.setSecondPageData;
  const isFromStartNewOrder = inpatientOrderObj!.isFromStartNewOrder;
  const inPatientOrderId = location?.state?.orderID;
  const isCustomerPlusProduct =
    inpatientOrderObj!.data.devicePlacement.value.productType.value ===
    DevicePlacementTabs.CUSTOMER_PLUS_TAB;
  const devicePlacementTableData = inpatientOrderObj!.devicePlacementTableData;
  const setDevicePlacementTableData =
    inpatientOrderObj!.setDevicePlacementTableData;
  const addresses = inpatientOrderObj!.addresses;
  const setAddresses = inpatientOrderObj!.setAddresses;
  const woundInfoDropDownValues = inpatientOrderObj!.woundInfoDropDownValues;
  const setWoundInfoDropDownValues =
    inpatientOrderObj!.setWoundInfoDropDownValues;
  const devicePlacementScrollableTab =
    inpatientOrderObj!.devicePlacementScrollableTab;
  const setDevicePlacementScrollableTab =
    inpatientOrderObj!.setDevicePlacementScrollableTab;
  const isSelectionSuppliesFlow: boolean =
    data.selectionSupplies.value === "yes";
  const salesPOValue = inpatientOrderObj?.salesPOValue;
  const setSalesPOValue = inpatientOrderObj!.setSalesPOValue;
  const patientInfo = data.patientInformation.value;
  const productInfo = data.orderWithSolventum.value;
  const patient = {
    firstName: patientInfo.firstName.value,
    lastName: patientInfo.lastName.value,
    dateOfBirth: patientInfo.dateOfBirth.value,
  };
  const product = {
    imageLink: productInfo?.productImage,
    name: productInfo?.productDesc,
    serialNumber: "",
    productCode: productInfo?.sku,
  };

  const checkForPreviousButtonText = (): string => {
    return inpatientOrderObj?.page !== InpatientOrderPageSection.FIRST_PAGE
      ? "Previous"
      : "";
  };

  const checkForNextButtonText = (): string => {
    return inpatientOrderObj?.page === InpatientOrderPageSection.REVIEW_PAGE
      ? "Submit Order"
      : "Next";
  };

  const setIsCustomerPlusProduct = (flag: boolean | null) => {
    let tempData: IInpatientOrder = data;
    tempData.devicePlacement.value.productType.value = flag
      ? DevicePlacementTabs.CUSTOMER_PLUS_TAB
      : DevicePlacementTabs.STORED_PRODUCT_TAB;
    setData(tempData);
  };

  const getDropDownValues = async () => {
    const ddContent = format(
      "{0},{1},{2},{3}",
      DD_WOUND_DIRECTION,
      DD_ACUTE_WOUND_LOCATION,
      DD_WOUND_ORIENTATION,
      DD_ACUTE_WOUND_TYPE
    );
    const dropDownResponse = await getDropDownContent(ddContent);
    let locationsArr: string[] = [];
    let directionsArr: string[] = [];
    let orientationsArr: string[] = [];
    let woundTypeArr: string[] = [];
    dropDownResponse.items.forEach((x: any) => {
      if (x.name === DD_ACUTE_WOUND_LOCATION) {
        locationsArr = x.data.map((x: any) => x.text);
      } else if (x.name === DD_WOUND_DIRECTION) {
        directionsArr = x.data.map((x: any) => x.text);
      } else if (x.name === DD_WOUND_ORIENTATION) {
        orientationsArr = x.data.map((x: any) => x.text);
      } else if (x.name === DD_ACUTE_WOUND_TYPE) {
        woundTypeArr = x.data.map((x: any) => x.text);
      }
    });
    setWoundInfoDropDownValues({
      woundDirectionDropDownValues: directionsArr,
      woundLocationDropDownValues: locationsArr,
      woundOrientationDropDownValues: orientationsArr,
      woundTypeDropDownValues: woundTypeArr,
    });
  };

  const checkErroredFieldAndFocusOnInputField = async (
    errorField: IInputField
  ) => {
    if (errorField.componentId && errorField.componentId !== "") {
      const scrollableComponent = document.getElementById(
        errorField.componentId
      );
      await setTimeout(() => {
        if (scrollableComponent) {
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
          });
          if (errorField.id && errorField.id !== "") {
            const autoCursorComponentId = document.getElementById(
              errorField.id
            );
            setTimeout(() => {
              if (autoCursorComponentId) {
                autoCursorComponentId.focus();
              }
            }, 500);
          }
        }
      }, 500);
    }
  };

  const editButtonClick = (
    scrollableComponentClassName: string,
    pageSection: InpatientOrderPageSection
  ) => {
    if (pageSection) {
      inpatientOrderObj?.setOrderProgress(40);
      inpatientOrderObj?.setPage(pageSection);
    } else {
      inpatientOrderObj?.setOrderProgress(20);
      inpatientOrderObj?.setPage(InpatientOrderPageSection.FIRST_PAGE);
    }
    window.scroll(0, 0);
    setTimeout(() => {
      if (scrollableComponentClassName) {
        let scrollableComponent = document.getElementsByClassName(
          scrollableComponentClassName
        )[0];
        if (scrollableComponent) {
          scrollableComponent.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
      if (pageSection === InpatientOrderPageSection.SUPPLY_PAGE) {
        setTimeout(() => {
          setOpenCart(true);
        }, 500);
      }
    }, 500);
  };

  const updtedProductDetails = async (): Promise<IBillingAddress[] | null> => {
    let isCustomerPlusProductValue: boolean | null = null;
    if (allProducts?.length > 0) {
      const firstProduct:
        | ICustomerPlusListingDetails
        | IInventoryProductDetails = allProducts[0];
      isCustomerPlusProductValue =
        (firstProduct as IInventoryProductDetails).storeAndClean === undefined;
    }
    if (isFromStartNewOrder) {
      setIsCustomerPlusProduct(false);
    } else {
      setIsCustomerPlusProduct(isCustomerPlusProductValue);
    }
    let tempData = {
      ...data,
      devicePlacement: {
        ...data.devicePlacement,
        valid: ValidationStatus.VALID,
        value: {
          ...data.devicePlacement.value,
          productType: {
            ...data.devicePlacement.value.productType,
            valid:
              selectedProdcut || isFromStartNewOrder
                ? ValidationStatus.VALID
                : ValidationStatus.UNTOUCHED,
            value: isCustomerPlusProductValue
              ? DevicePlacementTabs.CUSTOMER_PLUS_TAB
              : DevicePlacementTabs.STORED_PRODUCT_TAB,
          },
          selectedProduct: {
            ...data.devicePlacement.value.selectedProduct,
            valid: selectedProdcut
              ? ValidationStatus.VALID
              : ValidationStatus.UNTOUCHED,
            value: selectedProdcut,
          },
          placementType: {
            ...data.devicePlacement.value.placementType,
            required: isFromStartNewOrder,
            value: isFromStartNewOrder
              ? PlacementTypeSelection.PLACE_FROM_STORAGE
              : null,
          },
        },
      },
      therapyStartDate: {
        ...data.therapyStartDate,
        required: isCustomerPlusProductValue ?? false,
      },
    };
    if (!isCustomerPlusProductValue) {
      tempData.patientWoundInformation.value = {
        ...tempData.patientWoundInformation.value,
        primaryWoundInformation: {
          ...tempData.patientWoundInformation.value.primaryWoundInformation,
          woundMeasurementDate: {
            ...tempData.patientWoundInformation.value.primaryWoundInformation
              .woundMeasurementDate,
            required: true,
          },
        },
        secondaryWoundInformation: {
          ...tempData.patientWoundInformation.value.secondaryWoundInformation,
          woundMeasurementDate: {
            ...tempData.patientWoundInformation.value.secondaryWoundInformation
              .woundMeasurementDate,
            required: true,
          },
        },
      };
      return await getCustomerInformationAPI(tempData);
    } else {
      setData(tempData);
    }
    return null;
  };

  const updatePatientAndProductDetails = async (
    patientInformation: IPatientInformation,
    placementType: PlacementTypeSelection,
    selectedProdcut: IInventoryProductDetails | null,
    solventumProduct: ISolventumProduct | null,
    isNavigationRequired: boolean = true,
    isToNavigateSupplyList: boolean = false
  ) => {
    if (placementType !== PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM) {
      if (
        (inpatientOrderObj!.product &&
          selectedProdcut &&
          (selectedProdcut.product !== inpatientOrderObj?.product.name ||
            selectedProdcut.serialNumber !==
              inpatientOrderObj!.product.serialNumber)) ||
        (inpatientOrderObj!.product === null && selectedProdcut)
      ) {
        setShowLoader(true);
        const imageLink = await getProductImageLink(
          selectedProdcut!.productName
        );
        setShowLoader(false);
        const tempProductData: IProductDetail = {
          imageLink: imageLink,
          name: selectedProdcut!.product ?? "",
          serialNumber: selectedProdcut!.serialNumber,
        };
        inpatientOrderObj!.setProduct(tempProductData);
      }
    } else {
      if (inpatientOrderObj!.product === null && solventumProduct) {
        const tempProduct = {
          imageLink: solventumProduct.productImage,
          name: solventumProduct.productCompleteDescrption ?? "",
          serialNumber: "",
        };
        inpatientOrderObj!.setProduct(tempProduct);
      }
    }
    const tempPatientData: IPatientDetail = {
      firstName: patientInformation.firstName.value,
      lastName: patientInformation.lastName.value,
      dateOfBirth: patientInformation.dateOfBirth.value,
    };
    inpatientOrderObj!.setPatient(tempPatientData);
    if (isNavigationRequired) {
      navigateToSecondPage(isToNavigateSupplyList);
    }
  };

  const navigateToSecondPage = (isToNavigateSupplyList: boolean = false) => {
    window.scrollTo(0, 0);
    inpatientOrderObj?.setOrderProgress(40);
    if (isToNavigateSupplyList) {
      inpatientOrderObj?.setPage(InpatientOrderPageSection.SUPPLY_PAGE);
      setOpenCart(false);
    } else {
      inpatientOrderObj?.setPage(InpatientOrderPageSection.SECOND_PAGE);
    }
  };

  const cancelButtonAction = () => {
    inpatientOrderObj?.resetContext();
    history.goBack();
  };

  const previousButtonAction = () => {
    switch (inpatientOrderObj?.page) {
      case InpatientOrderPageSection.FIRST_PAGE:
        window.scrollTo(0, 0);
        history.goBack();
        break;
      case InpatientOrderPageSection.SUPPLY_PAGE:
        window.scrollTo(0, 0);
        inpatientOrderObj?.setOrderProgress(20);
        inpatientOrderObj?.setPage(InpatientOrderPageSection.FIRST_PAGE);
        break;
      case InpatientOrderPageSection.SECOND_PAGE:
        if (isSelectionSuppliesFlow) {
          window.scrollTo(0, 0);
          inpatientOrderObj?.setOrderProgress(40);
          inpatientOrderObj?.setPage(InpatientOrderPageSection.SUPPLY_PAGE);
          setOpenCart(false);
        } else {
          window.scrollTo(0, 0);
          inpatientOrderObj?.setOrderProgress(20);
          inpatientOrderObj?.setPage(InpatientOrderPageSection.FIRST_PAGE);
        }
        break;
      case InpatientOrderPageSection.REVIEW_PAGE:
        window.scrollTo(0, 0);
        if (isCustomerPlusProduct) {
          inpatientOrderObj?.setOrderProgress(20);
          inpatientOrderObj?.setPage(InpatientOrderPageSection.FIRST_PAGE);
        } else {
          inpatientOrderObj?.setOrderProgress(40);
          inpatientOrderObj?.setPage(InpatientOrderPageSection.SECOND_PAGE);
        }
        break;
      case InpatientOrderPageSection.SUMMARY_PAGE:
        window.scrollTo(0, 0);
        inpatientOrderObj?.setOrderProgress(80);
        inpatientOrderObj?.setPage(InpatientOrderPageSection.REVIEW_PAGE);
        break;
      default:
        break;
    }
  };

  const setDateTime = (
    data: IInpatientOrderSecondPage,
    currentServerDate: any
  ): IInpatientOrderSecondPage => {
    let tempData: IInpatientOrderSecondPage = data;
    if (currentServerDate) {
      const dateTime = moment(currentServerDate?.currentCstTime)
        .add(30, "hours")
        .toDate();
      const formattedDate = moment(dateTime).format("MM/DD/YYYY");
      var time = moment(
        deliveryPrefernceRoundToNearestMinutes(dateTime)
      ).format("h:mm A");
      if (
        data.deliveryPreference.value.needByDate.value === "" &&
        data.deliveryPreference.value.needByTime.value === ""
      ) {
        if (
          (dateTime.getHours() === 21 && dateTime.getMinutes() === 0) ||
          (dateTime.getHours() === 6 && dateTime.getMinutes() === 0)
        ) {
          tempData = {
            ...tempData,
            deliveryPreference: {
              ...tempData.deliveryPreference,
              valid: ValidationStatus.VALID,
              value: {
                ...tempData.deliveryPreference.value,
                needByDate: {
                  ...tempData.deliveryPreference.value.needByDate,
                  errorMessage: null,
                  valid: ValidationStatus.VALID,
                  value: formattedDate,
                },
                needByTime: {
                  ...tempData.deliveryPreference.value.needByTime,
                  errorMessage: null,
                  valid: ValidationStatus.VALID,
                  value: getCodeFromText(inpatientOrderObj?.needTimes, time),
                },
              },
            },
          };
        } else if (dateTime.getHours() >= 21 || dateTime.getHours() < 6) {
          if (dateTime.getHours() <= 5) {
            tempData = {
              ...tempData,
              deliveryPreference: {
                ...tempData.deliveryPreference,
                valid: ValidationStatus.VALID,
                value: {
                  ...tempData.deliveryPreference.value,
                  needByDate: {
                    ...tempData.deliveryPreference.value.needByDate,
                    errorMessage: null,
                    valid: ValidationStatus.VALID,
                    value: formattedDate,
                  },
                  needByTime: {
                    ...tempData.deliveryPreference.value.needByTime,
                    errorMessage: null,
                    valid: ValidationStatus.VALID,
                    value: getCodeFromText(
                      inpatientOrderObj?.needTimes,
                      inpatientOrderObj?.needTimesText[0]!
                    ),
                  },
                },
              },
            };
          } else {
            //assign to next day first slot
            const addedExtraDay = moment(dateTime)
              .add(1, "days")
              .format("MM/DD/YYYY");
            tempData = {
              ...tempData,
              deliveryPreference: {
                ...tempData.deliveryPreference,
                valid: ValidationStatus.VALID,
                value: {
                  ...tempData.deliveryPreference.value,
                  needByDate: {
                    ...tempData.deliveryPreference.value.needByDate,
                    errorMessage: null,
                    valid: ValidationStatus.VALID,
                    value: addedExtraDay,
                  },
                  needByTime: {
                    ...tempData.deliveryPreference.value.needByTime,
                    errorMessage: null,
                    valid: ValidationStatus.VALID,
                    value: getCodeFromText(
                      inpatientOrderObj?.needTimes,
                      inpatientOrderObj?.needTimesText[0]!
                    ),
                  },
                },
              },
            };
          }
        } else {
          tempData = {
            ...tempData,
            deliveryPreference: {
              ...tempData.deliveryPreference,
              valid: ValidationStatus.VALID,
              value: {
                ...tempData.deliveryPreference.value,
                needByDate: {
                  ...tempData.deliveryPreference.value.needByDate,
                  errorMessage: null,
                  valid: ValidationStatus.VALID,
                  value: formattedDate,
                },
                needByTime: {
                  ...tempData.deliveryPreference.value.needByTime,
                  errorMessage: null,
                  valid: ValidationStatus.VALID,
                  value: getCodeFromText(inpatientOrderObj?.needTimes, time),
                },
              },
            },
          };
        }
      }
      return tempData;
    }
    return tempData;
  };
  const salesPORequiredValue = () => {
    if (salesPOValue && isSelectionSuppliesFlow) {
      return true;
    }
    return false;
  };

  const updatedAndReturnSecondPageDetails = (
    secondPageDetail: IInpatientOrderSecondPage,
    isSolventumProduct: boolean
  ): IInpatientOrderSecondPage => {
    if (placementTypeChanged) {
      resetCart();
      setFilterValues(defaultFilterValues);
    }
    const secondPageTempData = {
      ...secondPageDetail,
      placementDate: {
        ...secondPageDetail.placementDate,
        required: !isSolventumProduct,
        valid: !placementTypeChanged
          ? secondPageDetail.placementDate.valid
          : ValidationStatus.UNTOUCHED,
        value: !placementTypeChanged
          ? secondPageDetail.placementDate.value
          : "",
      },
      replacementInformation: {
        ...secondPageDetail.replacementInformation,
        hasChildsToValidate: !isSolventumProduct,
        required: !isSolventumProduct,
        value: !placementTypeChanged
          ? secondPageDetail.replacementInformation.value
          : defaultReplacementInformationData,
      },
      deliveryPreference: {
        ...secondPageDetail.deliveryPreference,
        hasChildsToValidate: isSolventumProduct,
        required: isSolventumProduct,
        value: !placementTypeChanged
          ? secondPageDetail.deliveryPreference.value
          : defaultDeliveryPreferenceData,
      },
      billingInformation: {
        ...secondPageDetail.billingInformation,
        value: {
          ...secondPageDetail.billingInformation.value,
          salesPONumber: {
            ...secondPageDetail.billingInformation.value.salesPONumber,
            required: salesPORequiredValue(),
            valid: salesPORequiredValue()
              ? secondPageDetail.billingInformation.value.salesPONumber.valid
              : ValidationStatus.UNTOUCHED,
            value: isSelectionSuppliesFlow
              ? secondPageDetail.billingInformation.value.salesPONumber.value
              : "",
          },
        },
      },
    };
    return secondPageTempData;
  };

  const updateSecondPageDetails = async (
    placementType: PlacementTypeSelection,
    tempData: IInpatientOrderSecondPage = secondPageData,
    orderResponseData?: InPatientOrderSummaryData,
    isFromInCompleteAlert: boolean = false
  ) => {
    let secondPageTempData: IInpatientOrderSecondPage =
      updatedAndReturnSecondPageDetails(
        tempData,
        placementType === PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM
      );
    const currentDatePlus5hrs = moment().add(5, "hours");
    const dateCheck = moment(secondPageTempData.placementDate.value).isBefore(
      currentDatePlus5hrs
    );
    if (placementType === PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM) {
      if (isFromInCompleteAlert && dateCheck) {
        secondPageTempData.deliveryPreference.value = {
          ...secondPageTempData.deliveryPreference.value,
          needByDate: {
            ...secondPageTempData.deliveryPreference.value.needByDate,
            valid: ValidationStatus.VALID,
            value: moment(orderResponseData?.placementDate).format(
              "MM/DD/YYYY"
            ),
          },
          needByTime: {
            ...secondPageTempData.deliveryPreference.value.needByTime,
            valid: ValidationStatus.VALID,
            value: orderResponseData?.needByTime,
          },
        };
      } else {
        const currentDateResponse = await handleCurrentServerDateTime();
        secondPageTempData = setDateTime(
          secondPageTempData,
          currentDateResponse
        );
      }
    }
    setSecondPageData(secondPageTempData);
    setPlacementTypeChanged(false);
    return isFromInCompleteAlert && dateCheck;
  };

  const nextButtonAction = () => {
    const validator = new InpatientOrderValidator();
    switch (inpatientOrderObj?.page) {
      case InpatientOrderPageSection.FIRST_PAGE:
        const [isAllValid, errorField] = validator.validateAll(
          inpatientOrderObj!.data,
          inpatientOrderObj!.setData
        );
        if (errorField) {
          checkErroredFieldAndFocusOnInputField(errorField);
        }
        if (isAllValid === ValidationStatus.VALID) {
          setIsAutoFocusEnabled(false);
          window.scrollTo(0, 0);
          if (
            inpatientOrderObj?.page === InpatientOrderPageSection.FIRST_PAGE &&
            !isCustomerPlusProduct
          ) {
            const patientInformation = data.patientInformation.value;
            const devicePlacement = data.devicePlacement.value;
            const placementType = devicePlacement.placementType.value;
            const selectedProdcut: IInventoryProductDetails | null =
              devicePlacement.selectedProduct.value;
            const solventumProduct = data.orderWithSolventum.value;
            updatePatientAndProductDetails(
              patientInformation,
              placementType,
              selectedProdcut,
              solventumProduct,
              true,
              isSelectionSuppliesFlow
            );
            updateSecondPageDetails(placementType, secondPageData);
          } else {
            inpatientOrderObj?.setOrderProgress(80);
            inpatientOrderObj?.setPage(InpatientOrderPageSection.REVIEW_PAGE);
          }
        }
        break;
      case InpatientOrderPageSection.SUPPLY_PAGE:
        if (cartState.totalQuantity > 0) {
          window.scrollTo(0, 0);
          inpatientOrderObj?.setOrderProgress(60);
          inpatientOrderObj?.setPage(InpatientOrderPageSection.SECOND_PAGE);
        } else {
          setProductAvailibiltyPopup(true);
        }
        setOpenCart(false);
        break;
      case InpatientOrderPageSection.SECOND_PAGE:
        const [isSecondPageAllValid, errorFieldInSecondPage] =
          validator.validateAllForSecondPage(
            inpatientOrderObj!.secondPageData,
            inpatientOrderObj!.setSecondPageData
          );
        if (errorFieldInSecondPage) {
          checkErroredFieldAndFocusOnInputField(errorFieldInSecondPage);
        }
        if (isSecondPageAllValid === ValidationStatus.VALID) {
          window.scrollTo(0, 0);
          inpatientOrderObj?.setOrderProgress(80);
          inpatientOrderObj?.setPage(InpatientOrderPageSection.REVIEW_PAGE);
        }
        break;
      case InpatientOrderPageSection.REVIEW_PAGE:
        callSubmitAcuteOrder(isCustomerPlusProduct!);
        break;
      default:
        break;
    }
  };

  const checkForNextButtonDisabled = () => {
    if (permissionObj?.mappedRolesPermissionData?.IsSupportRole) {
      return true;
    } else if (
      InpatientOrderPageSection.FIRST_PAGE &&
      cartState.invalidQuantity
    ) {
      return true;
    }
    return false;
  };

  const handleContinueOrderAction = () => {
    window.scrollTo(0, 0);
    inpatientOrderObj?.setOrderProgress(60);
    inpatientOrderObj?.setPage(InpatientOrderPageSection.SECOND_PAGE);
    setProductAvailibiltyPopup(false);
  };

  const getSubmitAcuteOrderFailureScreens = (
    errorCode: number
  ): ISubmitAcuteOrderFailureScreens => {
    let updatedScreeninfo: ISubmitAcuteOrderFailureScreens = {
      section: IInPatientOrderPopupSection.RE_SUBMIT_ACUTE_ORDER,
      openFlag: false,
      page: InpatientOrderPageSection.REVIEW_PAGE,
    };
    switch (errorCode) {
      case 5151:
      case 5153:
      case 5154:
      case 5150:
        updatedScreeninfo = {
          openFlag: true,
          section: IInPatientOrderPopupSection.RE_SUBMIT_ACUTE_ORDER,
          page: InpatientOrderPageSection.REVIEW_PAGE,
        };
        break;
      case 5158:
      case 5159:
      case 5162:
      case 5163:
      case 5164:
      case 5165:
      case 5166:
      case 5167:
      case 5168:
      case 5169:
      case 5170:
      case 5171:
      case 5172:
      case 5001:
        updatedScreeninfo = {
          openFlag: true,
          section: IInPatientOrderPopupSection.RE_SUBMIT_WOUND_IFNO_ACUTE_ORDER,
          page: InpatientOrderPageSection.SUMMARY_PAGE,
        };
        break;
    }
    return updatedScreeninfo;
  };

  const closePopUpButtonAction = async () => {
    history.goBack();
  };

  const getErrorMessage = (isCustomerPlusFlow: boolean): string => {
    const isStoredProductOrder =
      data.devicePlacement.value.placementType.value ===
      PlacementTypeSelection.PLACE_FROM_STORAGE;
    return isCustomerPlusFlow
      ? SVC_IN_PATIENT_ORDER_SUBMIT
      : isStoredProductOrder
      ? SVC_STORED_PRODUCT_IN_PATIENT_ORDER_SUBMIT
      : SVC_SOLVENTUM_PRODUCT_IN_PATIENT_ORDER_SUBMIT;
  };

  const callSubmitAcuteOrder = async (isCustomerPlusFlow: boolean) => {
    const patientInformation: IPatientInformation =
      data.patientInformation.value;
    const patientDateOfBirth = inPatientformatISODate(
      patientInformation.dateOfBirth.value,
      patientInformation.dateOfBirth.valid
    );
    if (
      authObj &&
      authObj.userProfile &&
      authObj.registeredFaciltyAddress &&
      permissionObj &&
      patientDateOfBirth
    ) {
      const reqParam: ISubmitAcuteOrderRequest = await mapAcuteOrderRequest(
        addresses ?? [],
        authObj,
        inpatientOrderObj!.data,
        isCustomerPlusFlow,
        data.devicePlacement.value.placementType.value ===
          PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM
          ? InpatientOrderSubmission.TYPE.SOLVENTUM_ORDER
          : isCustomerPlusFlow
          ? InpatientOrderSubmission.TYPE.CUSTOMER_PLUS
          : isFromStartNewOrder &&
            data.devicePlacement.value.placementType.value ===
              PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM
          ? InpatientOrderSubmission.TYPE.SOLVENTUM_ORDER
          : InpatientOrderSubmission.TYPE.STORED_PRODUCT,
        patientDateOfBirth,
        inpatientOrderObj!.secondPageData,
        inpatientOrderObj?.inPatientOrderID
          ? inpatientOrderObj?.inPatientOrderID
          : "",
        cartState
      );
      try {
        setShowLoader(true);
        const response = await submitAcuteOrderDetails(reqParam);
        inpatientOrderObj?.setInPatientOrderID(response?.item);
        setShowLoader(false);
        if (response && response.succeeded) {
          window.scrollTo(0, 0);
          inpatientOrderObj!.setPage(InpatientOrderPageSection.SUMMARY_PAGE);
        } else {
          const { openFlag, section, page } = getSubmitAcuteOrderFailureScreens(
            response?.error?.errorCode
          );
          if (openFlag && !isCustomerPlusFlow) {
            if (page) {
              window.scrollTo(0, 0);
              inpatientOrderObj?.setPage(page);
            }
            setPopupSection(section);
            setOpenAcuteOrderFailurepopup(true);
          } else {
            const errorForApiFailure = {
              errorCode:
                response?.error?.errorCode ||
                response?.error?.code ||
                response?.status,
              errorFlag: true,
              errorMessage: getErrorMessage(isCustomerPlusFlow),
            };
            setError(errorForApiFailure);
          }
        }
      } catch (error) {
        console.log("error", error);
        setShowLoader(false);
      }
    } else {
      let errorCode = "";
      if (!addresses || addresses.length === 0) {
        errorCode = IN_PATIENT_ORDER_BILLING_ADDRESSES_MISSING;
      } else if (!authObj || (authObj && !authObj.userProfile)) {
        errorCode = USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE;
      } else if (!authObj || (authObj && !authObj.registeredFaciltyAddress)) {
        errorCode = REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE;
      } else if (!patientDateOfBirth) {
        errorCode = IN_PATIENT_ORDER_INVALID_DATE_OF_BIRTH;
      }
      const errorFailure = {
        errorCode: errorCode,
        errorFlag: true,
        errorMessage: SVC_IN_PATIENT_ORDER_SUBMIT,
      };
      setError(errorFailure);
    }
  };

  const handleResubmitApiCall = async () => {
    if (
      authObj &&
      authObj.userProfile &&
      authObj.registeredFaciltyAddress &&
      permissionObj &&
      inpatientOrderObj?.inPatientOrderID
    ) {
      const isSalesRole =
        authObj.isInternalUser &&
        permissionObj.mappedRolesPermissionData.IsSalesRole;
      const spProduct = data.devicePlacement.value.selectedProduct;
      const reqBody: IReSubmitAcuteOrderRequest =
        await mapResubmitAcuteOrderRequest(
          inpatientOrderObj?.inPatientOrderID,
          authObj,
          isSalesRole,
          isCustomerPlusProduct!
        );
      setOpenAcuteOrderFailurepopup(false);
      setShowLoader(true);
      const reSubmitAcuteOrderReponse = await reSubmitAcuteOrderDetails(
        reqBody
      );
      setShowLoader(false);
      if (reSubmitAcuteOrderReponse && reSubmitAcuteOrderReponse.succeeded) {
        inpatientOrderObj?.setPage(InpatientOrderPageSection.SUMMARY_PAGE);
        setPopupSection(IInPatientOrderPopupSection.SUCCESS_ACUTE_ORDER);
      } else {
        const { openFlag, section, page } = getSubmitAcuteOrderFailureScreens(
          reSubmitAcuteOrderReponse?.error?.errorCode
        );
        if (openFlag) {
          if (page) {
            window.scrollTo(0, 0);
            inpatientOrderObj?.setPage(page);
          }
          setPopupSection(section);
          setOpenAcuteOrderFailurepopup(true);
        } else {
          const errorForApiFailure = {
            errorCode:
              reSubmitAcuteOrderReponse?.error?.errorCode ||
              reSubmitAcuteOrderReponse?.error?.code ||
              reSubmitAcuteOrderReponse?.status,
            errorFlag: true,
            errorMessage: SVC_ORDER_NOT_SUBMITTED,
          };
          setError(errorForApiFailure);
        }
      }
    }
  };

  const handleReSubmitWoundApiCall = async () => {
    if (
      authObj &&
      authObj.userProfile &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId
    ) {
      const woundReSubmitRequestBody: IReSubmitAcuteWoundInfo = {
        orderId: inpatientOrderObj?.inPatientOrderID!,
        siteUseId: authObj?.registeredFaciltyAddress?.siteUseId,
        loggedInUserPhone: authObj.userProfile!.phoneNumber
          ? authObj.userProfile!.phoneNumber
          : authObj.userProfile!.mobilePhoneNumber,
        loggedInUserFirstName: authObj?.userProfile?.firstName,
        loggedInUserLastName: authObj?.userProfile?.lastName,
        loggedInUserName: authObj?.userProfile?.userName,
        requestorFacility: authObj.registeredFaciltyAddress!.accountName,
        requestorFacilityAccountNo:
          authObj.registeredFaciltyAddress!.accountNumber!.toString(),
      };
      setOpenAcuteOrderFailurepopup(false);
      setShowLoader(true);
      const reSubmitWoundResponse = await reSubmitAcuteOrderWoundDetails(
        woundReSubmitRequestBody
      );
      setShowLoader(false);
      if (reSubmitWoundResponse && reSubmitWoundResponse.succeeded) {
        setPopupSection(IInPatientOrderPopupSection.SUCCESS_ACUTE_ORDER);
      } else {
        const { openFlag, section, page } = getSubmitAcuteOrderFailureScreens(
          reSubmitWoundResponse?.error?.errorCode
        );
        if (openFlag) {
          if (page) {
            window.scrollTo(0, 0);
            inpatientOrderObj?.setPage(page);
          }
          setPopupSection(section);
          setOpenAcuteOrderFailurepopup(true);
        } else {
          const errorForApiFailure = {
            errorCode:
              reSubmitWoundResponse?.error?.errorCode ||
              reSubmitWoundResponse?.error?.code ||
              reSubmitWoundResponse?.status,
            errorFlag: true,
            errorMessage: SVC_WOUND_NOT_SUBMITTED,
          };
          setError(errorForApiFailure);
        }
      }
    }
  };

  const getProductImageLink = async (productName: string): Promise<string> => {
    const params = {
      productName: productName,
    };
    const response = await getAcuteProductImageLink(params);
    if (response && response.succeeded) {
      return response.item.imageLink;
    } else {
      const apiFailure = {
        errorCode:
          response?.error?.errorCode ||
          response?.error?.code ||
          response?.status,
        errorFlag: true,
        errorMessage: SVC_IN_PATIENT_ORDER_SUBMIT,
      };
      setError(apiFailure);
      return "";
    }
  };

  const getCustomerInformationAPI = async (
    tempData: IInpatientOrder
  ): Promise<IBillingAddress[] | null> => {
    const siteUseId = getSiteUseId(authObj);
    if (siteUseId !== "") {
      const params = {
        siteUseId: siteUseId,
      };
      setShowLoader(true);
      const response = await getCustomerInformation(params);
      if (!inPatientOrderId) {
        setShowLoader(false);
      }
      if (response && response.succeeded) {
        const addressessValue: IBillingAddress[] = response.item.acuteBillTo;
        let finalData = {
          ...tempData,
          billingAddress: {
            ...data.billingAddress,
            required: isFromStartNewOrder
              ? true
              : addressessValue && addressessValue.length > 0
              ? true
              : false,
            valid:
              addressessValue.length === 1
                ? ValidationStatus.VALID
                : ValidationStatus.UNTOUCHED,
            value:
              addressessValue.length === 1
                ? addressessValue[0].billToSiteId.toString()
                : null,
          },
        };
        setData(finalData);
        const billingInfoData: IBillingInformation =
          response.item.isSalesPORequired;
        let secondPageTempData = {
          ...secondPageData,
          billingInformation: {
            ...secondPageData.billingInformation,
            required:
              response.item.rentalPOValue === null ||
              response.item.rentalPOValue === "NOT REQUIRED" ||
              !response.item.isSalesPORequired
                ? false
                : true,
            valid: ValidationStatus.VALID,
            value: {
              ...secondPageData.billingInformation.value,
              rentalPONumber: {
                ...secondPageData.billingInformation.value.rentalPONumber,
                required: response.item.isRentalPORequired ? true : false,
                valid:
                  response.item.isRentalPORequired &&
                  (response?.item?.rentalPOValue === null ||
                    response?.item?.rentalPOValue === "")
                    ? ValidationStatus.UNTOUCHED
                    : ValidationStatus.VALID,
                value: response.item.isRentalPORequired
                  ? response?.item?.rentalPOValue ?? ""
                  : "NOT REQUIRED",
              },
              isPORequired:
                response.item.rentalPOValue === "NOT REQUIRED" ? false : true,
              salesPONumber: {
                ...secondPageData.billingInformation.value.salesPONumber,
                required: response.item.isSalesPORequired,
              },
            },
          },
        };
        setSecondPageData(secondPageTempData);
        setAddresses(addressessValue);
        setSalesPOValue(billingInfoData);
        return addressessValue;
      } else {
        const apiFailure = {
          errorCode:
            response?.error?.errorCode ||
            response?.error?.code ||
            response?.status,
          errorFlag: true,
          errorMessage: SVC_GET_CUSTOMER_INFORMATION_ERROR,
          buttonTitle: "Done",
          isPhoneNumberRequired: true,
        };
        setError(apiFailure);
      }
    } else {
      const errorFailure = {
        errorCode: SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE,
        errorFlag: true,
        errorMessage: SVC_GET_CUSTOMER_INFORMATION_ERROR,
      };
      setError(errorFailure);
    }
    return null;
  };

  const fetchDropDownContent = async () => {
    try {
      const ddContent = format("{0}", DD_NEED_BY_TIME_CONTENT);
      const data = await getdropDownContent(ddContent);
      if (data.items.length > 0) {
        const needTimeObject = data.items.filter(
          (item: { name: string }) => item.name === DD_NEED_BY_TIME_CONTENT
        );
        const needTimeData = needTimeObject[0].data.sort(
          (a: { order: number }, b: { order: number }) =>
            a.order > b.order ? 1 : -1
        );
        inpatientOrderObj?.setNeedTimes(needTimeData);
        inpatientOrderObj?.setNeedTimesText(
          needTimeData.map((x: { text: string }) => x.text)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCurrentServerDateTime = async () => {
    const currentDateRes = await getCurrentServerDateTime();
    if (currentDateRes) {
      inpatientOrderObj?.setCurrentServerDate(currentDateRes);
      return currentDateRes;
    }
  };

  const getCustomerAndStoredProductListingData = async () => {
    const customerNumber = authObj?.registeredFaciltyAddress?.accountNumber;
    const siteUseId = getSiteUseId(authObj);
    const readyCareFlag =
      authObj?.registeredFaciltyAddress?.readyCareFlag?.toUpperCase() === "Y"
        ? true
        : false;
    const reqParams = {
      siteUseId: getSiteUseId(authObj),
      customerNumber: customerNumber,
      programType: 2,
      isReadyCare: readyCareFlag,
    };
    const customerPlusListingReqBody: ICustomerPlusListingReqBody = {
      siteUseId: siteUseId,
      customerPlusFlag: true,
      customerMasterNumber: getCustomerMasterNumber(authObj)!,
      facilityAccountNumber: authObj?.registeredFaciltyAddress?.accountNumber!,
    };
    const apiCollection = [
      getCustomerPlusListData(customerPlusListingReqBody),
      getInventoryInfoList(reqParams),
    ];
    const apiCollectionResult = await Promise.all(apiCollection);
    const customerPlusResponse = await apiCollectionResult[0];
    const storedProductResponse = await apiCollectionResult[1];
    let devicePlacementTableDataRes: IDevicePlacementTableData = {
      customerPlusListingData: [],
      storedProductListingData: [],
    };
    if (
      customerPlusResponse &&
      customerPlusResponse.succeeded &&
      customerPlusResponse.items
    ) {
      devicePlacementTableDataRes.customerPlusListingData =
        customerPlusResponse.items.filter(
          (item: ICustomerPlusListingDetails) =>
            item.status.toLowerCase() ===
            CustomerPlusListingTableTypes.AVAILABLE_UNITS.toLowerCase()
        );
    }
    if (
      storedProductResponse &&
      storedProductResponse.succeeded &&
      storedProductResponse.items
    ) {
      devicePlacementTableDataRes.storedProductListingData =
        storedProductResponse.items.filter(
          (item: IInventoryProductDetails) =>
            item.status.toLowerCase() === "available"
        );
    }
    setDevicePlacementTableData(devicePlacementTableDataRes);
    return devicePlacementTableDataRes;
  };

  const checkForPlacementTypeUpdate = async (
    addressesVal: IBillingAddress[] | null
  ) => {
    let tempData = data;
    tempData.devicePlacement.value = {
      ...tempData.devicePlacement.value,
      placementType: {
        ...tempData.devicePlacement.value.placementType,
        required: isFromStartNewOrder,
        value: PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM,
      },
      selectedProduct: {
        ...data.devicePlacement.value.selectedProduct,
        required: false,
      },
    };
    tempData = {
      ...data,
      orderWithSolventum: {
        ...data.orderWithSolventum,
        valid: ValidationStatus.UNTOUCHED,
        value: null,
        required: true,
      },
      selectionSupplies: {
        ...data.selectionSupplies,
        required: true,
        valid: ValidationStatus.UNTOUCHED,
        value: "",
      },
    };
    const billingAddress =
      addressesVal && addressesVal.length === 1
        ? addressesVal[0].billToSiteId.toString()
        : null;
    await updateSolventumProduct(tempData, billingAddress);
  };

  const mapInPatientSupplyProducts = async (
    orderResponseData: InPatientOrderSummaryData,
    orderSuppliesResult: IOrderSuppliesResult
  ): Promise<boolean> => {
    if (orderResponseData.supplies.length > 0) {
      const { billToSiteID, shipToSiteID, orderSuppliesProducts } =
        orderSuppliesResult;
      const mappedProducts = orderResponseData.supplies.map((supplyProduct) => {
        return orderSuppliesProducts.find(
          (orderProduct) => orderProduct.sku === supplyProduct.productCode
        );
      });
      if (mappedProducts.length > 0) {
        const productsCheck = mappedProducts.map(async (eachProduct) => {
          const reqParams = {
            billToSiteId: billToSiteID,
            inventoryId: eachProduct?.inventoryID,
            shipToSiteID: shipToSiteID,
            supplyPartNo: eachProduct?.sku,
            shipToState: authObj!.registeredFaciltyAddress?.state,
            shipToZipCode: authObj!.registeredFaciltyAddress?.zip,
          };
          const response = await getProductPriceAndAvailability(reqParams);
          if (response && response.succeeded) {
            const product = {
              maxAvailableQuantity: response.item.earliestATPDateQty,
              totalPrice:
                Number(eachProduct!.size) * Number(response.item.discountPrice),
              price: Number(response.item.discountPrice),
              quantity: 1,
              wareHouseID: response.item.wareHouseId,
              estimatedArrivalDate: "",
              categoryID: eachProduct?.categoryID ?? "",
              image: eachProduct?.image ?? "",
              inventoryID: Number(eachProduct?.inventoryID) ?? "",
              packageType: eachProduct?.packageType ?? "",
              productFamily: eachProduct?.productFamily ?? [],
              productName: eachProduct?.productName ?? "",
              size: eachProduct?.size ?? "",
              sku: eachProduct?.sku ?? "",
              supplyType: eachProduct?.supplyType ?? "",
            };
            dispatch({
              type: "ADD_PRODUCT",
              product: product,
              isProductNotAvailable: !response.item.isProductAvailable,
            });
            return response.item.isProductAvailable;
          } else {
            return false;
          }
        });
        const results = await Promise.all(productsCheck);
        return results.every(Boolean);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getSuppliesByFacility = async (
    firstPageData: IInpatientOrder
  ): Promise<IOrderSuppliesResult> => {
    let suppliesResult: IOrderSuppliesResult = {
      billToSiteID: "",
      shipToSiteID: "",
      orderSuppliesProducts: [],
    };
    const solventumProductData: ISolventumProduct =
      firstPageData.orderWithSolventum.value;
    const suppliesRequest: IInPatientSupplyOrderRequest = {
      description: solventumProductData.productCompleteDescrption,
      products: [solventumProductData.sku],
      siteUseId: getSiteUseId(authObj),
    };
    const response = await getOrderSuppliesByFacility(suppliesRequest);
    if (response && response.succeeded) {
      if (response.item) {
        suppliesResult = {
          billToSiteID: response.item.billToSiteID,
          shipToSiteID: response.item.shipToSiteID,
          orderSuppliesProducts: response.item.orderSupplies,
        };
      }
    }
    return suppliesResult;
  };

  const getOrderDetails = async (
    orderID: string,
    storedProductData: IInventoryProductDetails[]
  ) => {
    const reqBody = {
      orderId: orderID,
      siteUseId: getSiteUseId(authObj),
    };
    const orderResponse = await getAcuteOrderSummary(reqBody);
    if (orderResponse?.succeeded) {
      const orderTyepe = getDevicePlacementType(
        orderResponse.data.orderType.toString()
      );
      const billToSiteId = orderResponse.data.billingAddress.siteUseId;
      let allSolventumProducts: ISolventumProduct[] = [];
      if (
        orderTyepe === PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM &&
        billToSiteId !== ""
      ) {
        allSolventumProducts = await getSolventumProducts(billToSiteId);
      }
      const validator = new InpatientOrderValidator();
      const firstPageData = await mapInPatientOrderResponse(
        allSolventumProducts,
        orderResponse.data as InPatientOrderSummaryData,
        validator,
        storedProductData
      );
      setData(firstPageData);
      let allSuppliesProductsAvailable: boolean = true;
      if (orderResponse.data && orderResponse.data.isSuppliesOrdered) {
        setShowLoader(true);
        const orderSuppliesByFacilityResponse = await getSuppliesByFacility(
          firstPageData
        );
        allSuppliesProductsAvailable = await mapInPatientSupplyProducts(
          orderResponse.data as InPatientOrderSummaryData,
          orderSuppliesByFacilityResponse
        );
        setShowLoader(false);
      }
      const secondPageData = await mapInPatientSecondPageResponse(
        orderResponse.data as InPatientOrderSummaryData,
        validator
      );
      const patientInformation = firstPageData.patientInformation.value;
      const devicePlacement = firstPageData.devicePlacement.value;
      const placementType = devicePlacement.placementType.value;
      const selectedProdcut: IInventoryProductDetails | null =
        devicePlacement.selectedProduct.value ?? null;
      const solventumProduct = firstPageData.orderWithSolventum.value;
      updatePatientAndProductDetails(
        patientInformation,
        placementType,
        selectedProdcut,
        solventumProduct,
        false
      );
      const isDateLessThan5hrs = await updateSecondPageDetails(
        placementType,
        secondPageData,
        orderResponse.data as InPatientOrderSummaryData,
        true
      );
      if (placementType !== PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM) {
        setShowLoader(false);
      }
      if (inpatientOrderObj!.errorCode) {
        if (inpatientOrderObj!.errorCode === 200) {
          inpatientOrderObj!.setPage(InpatientOrderPageSection.SUMMARY_PAGE);
        } else {
          const { openFlag, section, page } = getSubmitAcuteOrderFailureScreens(
            inpatientOrderObj!.errorCode
          );
          if (openFlag) {
            if (page) {
              window.scrollTo(0, 0);
              inpatientOrderObj?.setPage(page);
            }
            setPopupSection(section);
            setOpenAcuteOrderFailurepopup(true);
          } else {
            const errorForApiFailure = {
              errorCode: inpatientOrderObj!.errorCode.toString(),
              errorFlag: true,
              errorMessage: SVC_STORED_PRODUCT_IN_PATIENT_ORDER_SUBMIT,
            };
            setError(errorForApiFailure);
          }
        }
      } else {
        if (selectedProdcut || solventumProduct) {
          if (solventumProduct) {
            if (!allSuppliesProductsAvailable) {
              inpatientOrderObj!.setPage(InpatientOrderPageSection.SUPPLY_PAGE);
              setOpenCart(true);
            } else if (!isDateLessThan5hrs) {
              inpatientOrderObj!.setPage(InpatientOrderPageSection.SECOND_PAGE);
            } else {
              inpatientOrderObj!.setPage(InpatientOrderPageSection.REVIEW_PAGE);
            }
          } else {
            inpatientOrderObj!.setPage(InpatientOrderPageSection.REVIEW_PAGE);
          }
        } else {
          inpatientOrderObj!.setPage(InpatientOrderPageSection.FIRST_PAGE);
        }
      }
    } else {
      setShowLoader(false);
      const errorFailure = {
        errorCode:
          orderResponse?.error?.code ||
          orderResponse?.error?.errorCode ||
          orderResponse?.status,
        errorFlag: true,
        errorMessage: SVC_GET_SOLVENTUM_PRODUCT,
      };
      setError(errorFailure);
    }
  };

  const initiateRequiredServiceCalls = async () => {
    const initialApiCallCollection: any[] = [getDropDownValues()];
    if (isFromStartNewOrder) {
      initialApiCallCollection.push(getCustomerAndStoredProductListingData());
    }
    setShowLoader(true);
    const addressesValue = await updtedProductDetails();
    let promiseCollectionResult = await Promise.all(initialApiCallCollection);
    if (
      isFromStartNewOrder &&
      promiseCollectionResult.length > 1 &&
      promiseCollectionResult[1]
    ) {
      let count =
        promiseCollectionResult[1].customerPlusListingData.length +
        promiseCollectionResult[1].storedProductListingData.length;
      if (count === 0) {
        await checkForPlacementTypeUpdate(addressesValue);
      }
    }
    if (inPatientOrderId) {
      if (
        isFromStartNewOrder &&
        promiseCollectionResult.length > 1 &&
        promiseCollectionResult[1]
      ) {
        setShowLoader(true);
        inpatientOrderObj!.setInPatientOrderID(inPatientOrderId);
        await getOrderDetails(
          inPatientOrderId,
          promiseCollectionResult[1].storedProductListingData
        );
        setShowLoader(false);
      }
    }
    setShowLoader(false);
  };

  const getSolventumProducts = async (billToSiteId: string) => {
    setShowLoader(true);
    const params = {
      billToSiteId: billToSiteId,
      siteUseId: getSiteUseId(authObj),
    };
    const response = await getOrderWithSolventumProduct(params);
    setShowLoader(false);
    if (response && response.succeeded && response.items) {
      inpatientOrderObj!.setSolventumProducts(response.items);
      inPatientSupplyOrderObj?.setInPatientSuppliesData(null);
      return response.items;
    } else {
      const errorFailure = {
        errorCode:
          response?.error?.code ||
          response?.error?.errorCode ||
          response?.status,
        errorFlag: true,
        errorMessage: SVC_GET_SOLVENTUM_PRODUCT,
      };
      setError(errorFailure);
    }
    return [];
  };

  const updateSolventumProduct = async (
    tempData: IInpatientOrder,
    billingAddress: string | null = null
  ) => {
    const isSolventumProduct =
      tempData.devicePlacement.value.placementType.value ===
      PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM;
    const isStoredProduct =
      tempData.devicePlacement.value.productType.value ===
      DevicePlacementTabs.STORED_PRODUCT_TAB;
    setPlacementTypeChanged(true);
    let temp = {
      ...tempData,
      billingAddress: {
        ...tempData.billingAddress,
        errorMessage:
          billingAddress && billingAddress !== ""
            ? null
            : tempData.billingAddress.errorMessage,
        required: isSolventumProduct || isStoredProduct,
        valid:
          billingAddress && billingAddress !== ""
            ? ValidationStatus.VALID
            : ValidationStatus.UNTOUCHED,
        value: billingAddress,
      },
      orderWithSolventum: {
        ...tempData.orderWithSolventum,
        valid: ValidationStatus.UNTOUCHED,
        value: null,
      },
    };
    setData(temp);
    inpatientOrderObj!.setProduct(null);
    const placementType = tempData.devicePlacement.value.placementType.value;
    if (
      billingAddress &&
      billingAddress !== "" &&
      placementType === PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM
    ) {
      await getSolventumProducts(billingAddress);
    }
  };

  useEffect(() => {
    if (inpatientOrderObj?.isInitialLoadTriggered) {
      if (authObj?.registeredFaciltyAddress) {
        if (data.devicePlacement.value.selectedProduct.value === null) {
          initiateRequiredServiceCalls();
        } else {
          if (
            inpatientOrderObj?.page === InpatientOrderPageSection.SECOND_PAGE
          ) {
            const patientInformation = data.patientInformation.value;
            const devicePlacement = data.devicePlacement.value;
            const placementType = devicePlacement.placementType.value;
            const selectedProdcut: IInventoryProductDetails | null =
              devicePlacement.selectedProduct.value ?? null;
            const solventumProduct = data.orderWithSolventum.value;
            updatePatientAndProductDetails(
              patientInformation,
              placementType,
              selectedProdcut,
              solventumProduct
            );
          }
        }
      } else {
        history.push("/home");
      }
      inpatientOrderObj!.setIsInitialLoadTriggered(false);
    }
  }, []);

  useEffect(() => {
    if (
      popupSection ===
      IInPatientOrderPopupSection.RE_SUBMIT_WOUND_IFNO_ACUTE_ORDER
    ) {
      inpatientOrderObj?.setSubmitClickCount(0);
    }
  }, [popupSection]);

  useEffect(() => {
    fetchDropDownContent();
  }, [inPatientOrderId]);

  const sectionToDisplay = () => {
    let page: ReactNode;
    switch (inpatientOrderObj!.page) {
      case InpatientOrderPageSection.FIRST_PAGE:
        page = (
          <InpatientOrderFirstPage
            addresses={addresses}
            allProducts={allProducts}
            data={data}
            editButtonAction={editButtonClick}
            isAutoFocusEnabled={isAutoFocusEnabled}
            isCustomerPlusProduct={isCustomerPlusProduct}
            setIsCustomerPlusProduct={setIsCustomerPlusProduct}
            setData={setData}
            woundInfoDropDownValues={woundInfoDropDownValues}
            devicePlacementTableData={devicePlacementTableData}
            isFromStartNewOrder={isFromStartNewOrder}
            devicePlacementScrollableTab={devicePlacementScrollableTab}
            setDevicePlacementScrollableTab={setDevicePlacementScrollableTab}
            devicePlacementSelectedTab={devicePlacementSelectedTab}
            setDevicePlacementSelectedTab={setDevicePlacementSelectedTab}
            updateSolventumProduct={updateSolventumProduct}
            setFilterValues={setFilterValues}
            resetCart={resetCart}
          />
        );
        break;

      case InpatientOrderPageSection.SUPPLY_PAGE:
        page = (
          <InPatientSupplyOrderFirstPage
            openCart={openCart}
            isFromStockSupplies={false}
            setOpenCart={setOpenCart}
            showLoader={false}
            setShowLoader={() => setShowLoader(true)}
            patient={patient}
            product={product}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            setIsSuppliesDataAvailable={setIsSuppliesDataAvailable}
            isFromInPatientOrderPage={true}
          />
        );
        break;
      case InpatientOrderPageSection.SECOND_PAGE:
        page = (
          <>
            {inpatientOrderObj!.patient && (
              <InpatientOrderSecondPage
                data={inpatientOrderObj!.secondPageData}
                editButtonAction={editButtonClick}
                patient={inpatientOrderObj!.patient}
                product={inpatientOrderObj!.product}
                setData={inpatientOrderObj!.setSecondPageData}
                isFromSolventum={
                  data.devicePlacement.value.placementType.value ===
                  PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM
                }
                isSelectionSuppliesFlow={isSelectionSuppliesFlow}
              />
            )}
          </>
        );
        break;
      case InpatientOrderPageSection.REVIEW_PAGE:
        page = (
          <InpatientOrderReviewPage
            addresses={addresses}
            allProducts={allProducts}
            data={data}
            editButtonAction={editButtonClick}
            isCustomerPlusProduct={isCustomerPlusProduct}
            setData={setData}
            secondPageData={secondPageData}
            isFromSolventum={
              data.devicePlacement.value.placementType.value ===
              PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM
            }
            isSelectionSuppliesFlow={isSelectionSuppliesFlow}
            cartData={cartState}
          />
        );
        break;
      case InpatientOrderPageSection.SUMMARY_PAGE:
        page = (
          <InpatientOrderSummaryPage
            addresses={addresses}
            allProducts={allProducts}
            data={data}
            editButtonAction={editButtonClick}
            isCustomerPlusProduct={isCustomerPlusProduct}
            isSummaryOrder={true}
            secondPageData={secondPageData!}
            setData={setData}
            isFromSolventum={
              data.devicePlacement.value.placementType.value ===
              PlacementTypeSelection.PLACE_ORDER_WITH_SOLVENTUM
            }
            isSelectionSuppliesFlow={isSelectionSuppliesFlow}
            cartData={cartState}
          />
        );
        break;
    }
    return page;
  };

  const checkForNextButtonDisable = (): boolean => {
    let isToDisable: boolean = false;
    const permissionCheck =
      (permissionObj &&
        permissionObj.mappedRolesPermissionData.IsSupportRole) ??
      false;
    const suppliesPageCheck =
      inpatientOrderObj?.page === InpatientOrderPageSection.SUPPLY_PAGE;

    const availabilityCheck = cartState.cartItems.some(
      (cartItem) => cartItem.maxAvailableQuantity === 0
    );
    if (
      permissionCheck ||
      (suppliesPageCheck && (availabilityCheck || cartState.invalidQuantity))
    ) {
      isToDisable = true;
    }
    return isToDisable;
  };

  return (
    <div className="in-patient-order-container-component">
      {inpatientOrderObj?.page !== InpatientOrderPageSection.SUMMARY_PAGE && (
        <ProgressBar
          pageTitle="In-Patient Order"
          progressValue={inpatientOrderObj?.orderProgress}
          backButtonAction={previousButtonAction}
          isShowBackButton={true}
        />
      )}
      {!error.errorFlag && sectionToDisplay()}
      {inpatientOrderObj?.page !== InpatientOrderPageSection.SUMMARY_PAGE && (
        <InpatientOrderFooter
          firstButtonAction={cancelButtonAction}
          firstButtonDisabled={false}
          firstButtonTitle="Cancel Order"
          secondButtonAction={previousButtonAction}
          secondButtonDisabled={false}
          secondButtonTitle={checkForPreviousButtonText()}
          thirdButtonAction={nextButtonAction}
          thirdButtonDisabled={checkForNextButtonDisable()}
          thirdButtonTitle={checkForNextButtonText()}
        />
      )}
      <Popup
        closeHandler={() => setShowLoader(false)}
        dialogParentClass={"in-patient-order-container-loader-pop-up"}
        hideCloseButton={true}
        openFlag={showLoader}
      >
        <div className="in-patient-order-container-loader-pop-up-div">
          <LoadingSpinner />
        </div>
      </Popup>
      <ErrorPopup
        buttontitle={"Done"}
        errorCode={error.errorCode}
        errorPopupFlag={error.errorFlag}
        errorMessage={error.errorMessage}
        isShortSentence={true}
        handleBackButton={() => {
          closePopUpButtonAction();
        }}
        popUpStyles="errorPopup"
        isSupportPhoneRequired={true}
        showTitle={false}
      />
      <Popup
        openFlag={openAcuteOrderFailurepopup}
        closeHandler={() => {
          setOpenAcuteOrderFailurepopup(false);
        }}
        dialogParentClass="inpatient-order-container-popup"
      >
        <InpatientOrderPopup
          popupSection={popupSection}
          handleResubmitAcuteOrder={handleResubmitApiCall}
          handleResubmitWoundInfoAcuteOrder={handleReSubmitWoundApiCall}
          closePopup={() => {
            setOpenAcuteOrderFailurepopup(false);
          }}
        />
      </Popup>
      <Popup
        openFlag={productAvailibiltyPopup}
        closeHandler={() => setProductAvailibiltyPopup(false)}
        dialogParentClass="inPatient-supply-order-list-popup"
      >
        <ProductAvailibiltyPopup
          firstButtonText="Add supplies"
          secondButtonText="Continue your order"
          title={
            isSuppliesDataAvailable
              ? "Are you sure you want to continue without adding any supplies?"
              : "There are no available supplies."
          }
          dialogParentClass={`Only your ${
            product.name
          } Therapy unit will be ordered at this time. ${
            isSuppliesDataAvailable
              ? "You can also purchase supplies separately later."
              : ""
          }`}
          firstButtonHandler={() => setProductAvailibiltyPopup(false)}
          secondButtonHandler={handleContinueOrderAction}
          showFirstButton={isSuppliesDataAvailable}
        />
      </Popup>
    </div>
  );
};
