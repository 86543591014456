import { useContext, useState } from "react";
import { ReactComponent as GridView } from "../../../../../assets/gridView.svg";
import { ReactComponent as GridViewSlected } from "../../../../../assets/gridView_selected.svg";
import { ReactComponent as ListView } from "../../../../../assets/listView.svg";
import { ReactComponent as ListViewSelected } from "../../../../../assets/listView_selected.svg";
import { ExpressButton } from "../../../../../core/expressButton/expressButton.component";
import SubmittedDocumentGridView from "./SubmittedDocumentGridView/submittedDocumentGridView.component";
import SubmitDocumentListView from "./SubmittedDocumentsListView/submittedDocumentListView.component";
import "./orderOverviewSubmittedDocument.css";
import { IDropZoneDocumentSelect } from "../../../../../core/customDropZone/dropZoneDocumentSelect.interface";
import {
  checkPopUpBlocker,
  getDocumentUrl,
  getPdfUrl,
  getPdfUrlGif,
} from "../../../../../util/utilityFunctions";
import { getDocumentContent } from "../../../../../util/orderOverViewService";
import {
  IGetDocumentContentRequest,
  IGetDocumentContentResponse,
} from "./submittedDocument.interface";
import SnackBar from "../../../../../core/snackBar/snackBar.component";
import jsPDF from "jspdf";
import { Popup } from "../../../../../core/popup/popup.component";
import { LoadingSpinner } from "../../../../../core/loader/LoadingSpinner";
import { Grid } from "@mui/material";
import { IPatient } from "../../../patient.interface";
import moment from "moment";
import {
  AuthContext,
  AuthContextType,
} from "../../../../../context/AuthContext";
import ErrorPopup from "../../../../../core/errorPopup/errorPopup.component";
import { ERROR_MSG_POPUP_BLOCKER } from "../../../../../util/errorMsg";
import { OPEN_PDF_POP_UP_BLOCKED } from "../../../../../util/errorCode";
type Props = {
  documentsData: IDropZoneDocumentSelect[];
  documentTypeCode: any;
  patientData: IPatient;
};
const OrderOverviewSubmittedDocuments = ({
  documentsData,
  documentTypeCode,
  patientData,
}: Props) => {
  const authObj = useContext<AuthContextType | null>(AuthContext);
  const [switchToListView, setSwitchTolistView] = useState<boolean>(true);
  const [failedToDisplayPdf, setFailedToDisplayPdf] = useState<boolean>(false);
  const [isPopUpBlocked, setIsPopUpBlocked] = useState<boolean>(false);
  const [getDocumentContentLoader, setGetDocumentContentLoader] =
    useState(false);
  const toggleToListView = () => {
    setSwitchTolistView(true);
  };
  const toggleToGridView = () => {
    setSwitchTolistView(false);
  };
  const spinner = () => {
    return (
      <div>
        <div className="addWound-spinner">
          <LoadingSpinner />
        </div>
      </div>
    );
  };
  const clickToOpentheDocument = async (documentId: string) => {
    setGetDocumentContentLoader(true);
    if (
      documentId &&
      documentId !== "" &&
      ((patientData && patientData.dob && patientData.dob !== "") ||
        patientData.isAcuteOrder) &&
      authObj &&
      authObj.userProfile &&
      authObj.userProfile.userName &&
      authObj.userProfile.userName !== "" &&
      authObj.registeredFaciltyAddress &&
      authObj.registeredFaciltyAddress.siteUseId &&
      authObj.registeredFaciltyAddress.siteUseId !== ""
    ) {
      let reqBody: IGetDocumentContentRequest = {
        documentId: documentId,
        roNumber: patientData.roNumber,
        userName: authObj.userProfile.userName,
        siteUseId: authObj.registeredFaciltyAddress.siteUseId,
        isAcuteOrder: patientData.isAcuteOrder,
      };
      if (!patientData.isAcuteOrder) {
        reqBody.dob = patientData.dob;
      }
      const response = await getDocumentContent(reqBody);
      if (response && response.succeeded) {
        const item: IGetDocumentContentResponse = response.item;
        if (item.fileType && item.documentContent) {
          if (item.fileType.toLowerCase() === "pdf") {
            const url: any = await getPdfUrl(item.documentContent);
            const windowObject = window.open(url, "_blank");
            const result = checkPopUpBlocker(windowObject);
            setIsPopUpBlocked(result);
            setGetDocumentContentLoader(false);
          } else {
            const url = await getDocumentUrl(
              item.fileType,
              item.documentContent
            );
            const windowObject = window.open(url, "_blank");
            const result = checkPopUpBlocker(windowObject);
            setIsPopUpBlocked(result);
            setGetDocumentContentLoader(false);
          }
        }
      } else {
        setFailedToDisplayPdf(true);
        setTimeout(() => {
          setFailedToDisplayPdf(false);
        }, 5000);
        setGetDocumentContentLoader(false);
      }
    }
  };
  return (
    <>
      <div className="submitted-documents-component">
        <SnackBar
          toastStyle="submittedDocumentTrackingToast"
          handleCloseAlert={() => {
            setFailedToDisplayPdf(false);
          }}
          msg="File does not yet exist. Please try again in a few minutes"
          openFlag={failedToDisplayPdf}
        />
        <div className="submitted-documents-info">
          <div className="submitted-documents-info-header">
            <h2
              className="submitted-documents-info-title"
              data-testId="submitted-documents-info-title"
            >
              Submitted Documents
            </h2>
          </div>
          <div className="submitted-documents-info-button">
            <ExpressButton
              clickHandler={toggleToListView}
              parentClass={
                switchToListView
                  ? "parent-button list-view-button-selected"
                  : "parent-button list-view-button"
              }
              variant="outlined"
              testId="list-view-button"
            >
              {!switchToListView ? (
                <ListView className="icon" />
              ) : (
                <ListViewSelected className="icon" />
              )}
              <span
                className={
                  switchToListView ? "icon-text-selected" : "icon-text"
                }
              >
                List
              </span>
            </ExpressButton>
            <ExpressButton
              clickHandler={toggleToGridView}
              parentClass={
                !switchToListView
                  ? "parent-button grid-view-button-selected"
                  : "parent-button grid-view-button"
              }
              variant="outlined"
              testId="grid-view-button"
            >
              {!switchToListView ? (
                <GridViewSlected className="icon" />
              ) : (
                <GridView className="icon" />
              )}
              <span
                className={
                  !switchToListView ? "icon-text-selected" : "icon-text"
                }
              >
                Grid
              </span>
            </ExpressButton>
          </div>
        </div>
        <div className="submit-documents-content-div">
          <p
            className="submit-documents-content-text"
            data-testId="submitted-documents-content-text"
          >
            To remove documents created within 3M™ Express Therapy Portal
            (V.A.C.® Therapy Insurance Authorization Forms, Wound Progress
            Reports, Discharges, Proof of Delivery), please contact our 3M™
            Express Therapy Portal Support Team at{" "}
            <span className="submit-documents-contentText">
              1-800-275-4524 ext. 41858.
            </span>{" "}
            The view of the documents provided may be a low resolution view.
            This view is for your reference only; it is not the resolution that
            is received by 3M.
          </p>
        </div>
        {documentsData && (
          <div className="submitted-documents-list-div">
            {switchToListView && (
              <div className="mainDiv-list">
                <SubmitDocumentListView
                  documentsData={documentsData}
                  documentTypeCode={documentTypeCode}
                  clickToOpentheDocument={clickToOpentheDocument}
                />
              </div>
            )}
            {!switchToListView && (
              <div className="mainDiv-grid">
                <SubmittedDocumentGridView
                  documentsData={documentsData}
                  clickToOpentheDocument={clickToOpentheDocument}
                />
              </div>
            )}
          </div>
        )}
        {documentsData && documentsData.length < 1 && (
          <Grid
            item
            xs={6}
            className="submitted-documents-list-not-avaliable-item"
          >
            <div className="submitted-documents-list-not-avaliable">
              <p className="submitted-documents-list-not-avaliable-text">
                The request to display the list of documents or open a selected
                document has failed. Please try again or contact our 3M™ Express
                Therapy Portal Support Team at{" "}
                <span className="submit-documents-contentText">
                  1-800-275-4524 ext. 41858.
                </span>{" "}
              </p>
            </div>
          </Grid>
        )}
        <Popup
          closeHandler={() => {}}
          openFlag={getDocumentContentLoader}
          hideCloseButton={true}
        >
          {spinner()}
        </Popup>
        <ErrorPopup
          errorPopupFlag={isPopUpBlocked}
          popUpStyles="errorPopup"
          showTitle={false}
          handleBackButton={() => setIsPopUpBlocked(false)}
          isSupportPhoneRequired={false}
          errorMessage={ERROR_MSG_POPUP_BLOCKER}
          errorCode={OPEN_PDF_POP_UP_BLOCKED}
        />
      </div>
    </>
  );
};
export default OrderOverviewSubmittedDocuments;
