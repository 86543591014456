import { useContext } from "react";
import { ExpressButton } from "../../../core/expressButton/expressButton.component";
import "./duplicateOrderCheckDialogue.css";
import {
  RolesPermissionContext,
  RolesPermissionContextType,
} from "../../../context/RolesPermissionContext";
import { IDuplicateRO } from "../patientInformation/patientInformation.interface";
import moment from "moment";

interface DuplicateOrderCheckDialogProps {
  closeDialog: Function;
  handleSaveAndExit: Function;
  handleDeleteOrder: Function;
  duplicateRODetails:IDuplicateRO
  isVacOrderFromSSO?:boolean
}

const DuplicateOrderCheckDialog = ({
  closeDialog,
  handleSaveAndExit,
  handleDeleteOrder,
  duplicateRODetails,
  isVacOrderFromSSO
}: DuplicateOrderCheckDialogProps) => {
  const permissionContext = useContext<RolesPermissionContextType | null>(
    RolesPermissionContext
  );

  return (
    <div
      className="duplicate-order-check-dialog"
      data-testid="duplicate-order-dialog"
      id="duplicate-order-check-dialog"
    >
      <h2
        className="dialog-title"
        data-testid="dialog-title"
        id="dialog-title"
      >
        Possible duplicate order
      </h2>
      <p
        className="dialog-subtitle"
        data-testid="dialog-subtitle"
        id="dialog-subtitle"
      >
        The patient info you entered matches an existing Rental Order 
        <span className="dialog-subtitle-value"> #{duplicateRODetails.roNumber}, </span> 
        placed on <span className="dialog-subtitle-value">{moment
                                                    .utc(duplicateRODetails.createdDate)
                                                    .local()
                                                    .format("L")}</span>.
      </p>
      <p
        className="dialog-instructions"
        data-testid="dialog-instructions"
        id="dialog-instructions"
      >
        How would you like to proceed?
      </p>
      <div className="continue-order" id="continue-order">
        <ExpressButton
          parentClass="continue-order-button"
          variant="outlined"
          clickHandler={() => closeDialog()}
          testId="continue-order-button"
          id="continue-order-button"
        >
          Continue with Your New Order
        </ExpressButton>
      </div>
      {!isVacOrderFromSSO && (<div className="save-and-exit-order" id="save-and-exit-order">
        <ExpressButton
          parentClass="save-and-exit-button"
          variant="outlined"
          clickHandler={() => handleSaveAndExit()}
          testId="save-and-exit-button"
          id="save-and-exit-button"
          disabled={permissionContext?.mappedRolesPermissionData.IsSupportRole}
        >
          Save and Exit
        </ExpressButton>
      </div>)}
      <div className="delete-order" id="delete-order">
        <ExpressButton
          parentClass="delete-order-button"
          variant="contained"
          clickHandler={() => handleDeleteOrder()}
          testId="delete-order-button"
          id="delete-order-button"
        >
          Delete Order
        </ExpressButton>
      </div>
    </div>
  );
};

export default DuplicateOrderCheckDialog;
