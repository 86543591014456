import { Grid } from "@mui/material";
import { CustomDropDown } from "../../../../core/customDropdown/customDropdown.component";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ISRTServiceCoveringInformationProps } from "./serviceCoveringInformation.interface";
import { ISalesRoundingPatientDetails } from "../../salesRoundingTool.interface";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { SalesRoundingValidator } from "../../salesRoundingTool.validator";
import { getTextFromCode } from "../../../../util/utilityFunctions";
import "./serviceCoveringInformation.css";

export const ServiceCoveringInformation = ({
  data,
  setData,
  srtDropDownValues,
}: ISRTServiceCoveringInformationProps) => {
  const validator = new SalesRoundingValidator();

  const validateAndSetServiceCoveringData = async (e: any) => {
    const { name, value } = e.target;
    const isValid = validator.validate(value, "serviceCovering");
    const tempData: ISalesRoundingPatientDetails = {
      ...data,
      serviceCoveringInformation: {
        ...data.serviceCoveringInformation,
        serviceCovering: {
          ...data.serviceCoveringInformation.serviceCovering,
          errorMessage: null,
          valid: isValid!.status,
          value: value,
        },
      },
    };
    setData(tempData);
  };

  return (
    <div className="srtServiceCovering-info">
      <h2
        className="srtServiceCovering-info-title"
        data-testid="srtServiceCovering-info-title"
        id="srtServiceCovering-info-titleid"
      >
        Service Covering
      </h2>
      <Grid className="srtServiceCovering-info-grid-container" container>
        <Grid className="srtServiceCovering-info-item" item xs={12}>
          <InputWithLabel
            error={
              data.serviceCoveringInformation.serviceCovering.valid ===
              ValidationStatus.INVALID
            }
            id={data.serviceCoveringInformation.serviceCovering.componentId!}
            isRequired={
              data.serviceCoveringInformation.serviceCovering.required
            }
            isShowWarning={true}
            label={data.serviceCoveringInformation.serviceCovering.title!}
            labelClassName="srtServiceCovering-type-title"
            testId={
              data.serviceCoveringInformation.serviceCovering.componentId!
            }
            warningMessage={
              data.serviceCoveringInformation.serviceCovering.errorMessage
            }
            isDropdown={true}
          >
            <CustomDropDown
              handleChange={validateAndSetServiceCoveringData}
              placeHolder="Select a Service Covering"
              menuItem={srtDropDownValues.isServiceCoveringTextDropdown}
              name="serviceCovering"
              selectpropsClassName={
                data.serviceCoveringInformation.serviceCovering.value
                  ? "srtServiceCovering-info-select"
                  : "placeHolder"
              }
              selectClassName={
                data.serviceCoveringInformation.serviceCovering.value
                  ? "srtServiceCovering-info-input"
                  : "placeHolder"
              }
              testId="serviceCovering"
              value={
                data.serviceCoveringInformation.serviceCovering.value
                  ? getTextFromCode(
                      srtDropDownValues.isServiceCoveringCodeDropdown,
                      data.serviceCoveringInformation.serviceCovering.value
                    )
                  : null
              }
            />
          </InputWithLabel>
        </Grid>
      </Grid>
    </div>
  );
};
