export const USER_PROFILE_OBJ_FROM_AUTH_CONTEXT_FAILURE = "901";
export const REGISTERED_FACILITY_OBJ_FROM_AUTH_CONTEXT_FAILURE = "902";
export const PATIENT_OBJ_FROM_CONFIRM_PLACEMENT_CONTEXT_FAILURE = "903";
export const ROPN_VALUE_EMPTY_STRING = "904";
export const WO_NUMBER_EMPTY_STRING = "905";
export const USER_FACILITY_WO_FACILITY_DONOT_MATCH = "906";
export const CONFIRM_PLACEMENT_ALREADY_COMPLETED = "907";
export const CONFIRM_PLACEMENT_SERIAL_NUMBER_EMPTY = "908";
export const EXCHANGE_VAC_SERIAL_NUMBER_EMPTY_CODE = "908";
export const OPEN_PDF_POP_UP_BLOCKED = "909";
export const SITEUSEID_FROM_REGISTERED_FACILITY_OBJ_FAILURE = "910";
export const LOGGED_IN_USERNAME_FAILURE = "911";
export const PATIENT_OBJ_FROM_POS_FAILURE = "912";
export const SERIAL_NUMBER_IS_EMPTY_OR_NULL = "913";
export const NO_DROPDOWN_DATA_AVAIALABLE = "914";
export const USER_NAME_OR_SELECTED_FACILITY_IS_NULL = "915";
export const NO_PRESCRIBER_DATA_AVAIALABLE = "916";
export const WOUND_LIST_EMPTY_ERROR_CODE = "917";
export const ORDER_OVERVIEW_DATA_MAPPING_ISSUE_FOR_CUSTOMERPLUS = "918";
export const VAC_ORDER_ID_MISSING = "919";
export const IN_PATIENT_ORDER_BILLING_ADDRESSES_MISSING = "920";
export const IN_PATIENT_ORDER_INVALID_DATE_OF_BIRTH = "921";
export const SELECTED_PATIENT_DATA_FROM_MPD_IS_NULL = "922";
export const ORDER_OVERVIEW_NAVIGATION_EXCEPTION = "923";
export const UPDATE_USER_SESSION_EXPIRED_EXCEPTION = "924";
