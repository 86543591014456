import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { InputWithLabel } from "../../../../core/inputWithLabel/inputWithLabel.component";
import { ReactComponent as RadioButtonIcon } from "../../../../assets/radioButton.svg";
import { ReactComponent as SelectedRadioButtonIcon } from "../../../../assets/selectedRadioButton.svg";
import "./inPatientOrderSupply.css";
import { IInpatientOrder } from "../../inpatientOrder.interface";
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { ERROR_TO_SELECT_ANY_OPTION } from "../../../../util/errorMsg";
import { defaultFilterValues } from "../../../inpatientSupplyOrder/container/inpatientSupplyOrderContainer.component";
import { useContext } from "react";
import {
  InpatientSupplyOrderContext,
  InpatientSupplyOrderContextType,
} from "../../../../context/InpatientSupplyOrderContext";

interface IDeliveryPreference {
  data: IInpatientOrder;
  setData: Function;
  setFilterValues: Function;
  resetCart: Function;
}

export const InPatientOrderSupply = ({
  data,
  setData,
  setFilterValues,
  resetCart,
}: IDeliveryPreference) => {
  const inPatientSupplyOrderObj =
    useContext<InpatientSupplyOrderContextType | null>(
      InpatientSupplyOrderContext
    );
  const isCustomerOwnedDisabled: boolean =
    inPatientSupplyOrderObj?.isCustomerOwnedDisabled ?? false;
  const validateAndSetData = (e: any) => {
    const { value, name } = e.target;
    setData({
      ...data,
      selectionSupplies: {
        ...data.selectionSupplies,
        value: value,
        valid: ValidationStatus.VALID,
      },
    });
    resetCart();
    setFilterValues(defaultFilterValues);
  };

  return (
    <div
      className="in-patient-order-supply-selection-component"
      data-testid="in-patient-order-supply-selection-component"
    >
      <p
        className="in-patient-order-supply-selection-title"
        data-testid="in-patient-order-supply-selection-title"
        id="in-patient-order-supply-selection-title"
      >
        Supply Selection
      </p>

      <InputWithLabel
        error={data.selectionSupplies.valid === ValidationStatus.INVALID}
        id={data.selectionSupplies.componentId!}
        isRequired={true}
        isShowWarning={true}
        label={data.selectionSupplies.title}
        labelClassName="in-patient-order-supply-selection-header-title"
        testId="in-patient-order-supply-selection-radio-button"
        warningMessage={
          data.selectionSupplies.errorMessage ? ERROR_TO_SELECT_ANY_OPTION : ""
        }
      >
        <RadioGroup
          name="isSelectionSupplies"
          classes={{ root: "radioRoot" }}
          onChange={validateAndSetData}
          value={data.selectionSupplies.value}
        >
          <FormControlLabel
            classes={{
              root:
                data.selectionSupplies.valid === ValidationStatus.INVALID
                  ? "optionRoot-error"
                  : data.selectionSupplies.value === "yes"
                  ? "optionRoot-active"
                  : "optionRoot",
            }}
            componentsProps={{
              typography: {
                classes: {
                  root:
                    data.selectionSupplies.value === "yes"
                      ? "optiontxtSelect"
                      : "optiontxt",
                },
              },
            }}
            control={
              <Radio
                icon={<RadioButtonIcon />}
                checkedIcon={<SelectedRadioButtonIcon />}
              />
            }
            data-testid="in-patient-order-supply-selection-radio-button-yes"
            label="Yes"
            value="yes"
            disabled={isCustomerOwnedDisabled}
          />
          <FormControlLabel
            classes={{
              root:
                data.selectionSupplies.valid === ValidationStatus.INVALID
                  ? "optionRoot-error"
                  : data.selectionSupplies.value === "no"
                  ? "optionRoot-active"
                  : "optionRoot",
            }}
            componentsProps={{
              typography: {
                classes: {
                  root:
                    data.selectionSupplies.value === "no"
                      ? "optiontxtSelect"
                      : "optiontxt",
                },
              },
            }}
            control={
              <Radio
                icon={<RadioButtonIcon />}
                checkedIcon={<SelectedRadioButtonIcon />}
              />
            }
            data-testid="in-patient-order-supply-selection-radio-button-no"
            label="No"
            value="no"
          />
        </RadioGroup>
      </InputWithLabel>
    </div>
  );
};
