import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { ISRTServiceCoveringInformation } from "./serviceCoveringInformation.interface";

export const defaultSrtServiceCoveringInformation: ISRTServiceCoveringInformation =
  {
    serviceCovering: {
      componentId: "srt-service-covering",
      id: "srt-service-covering-select",
      order: 11,
      required: true,
      title: "Service Covering",
      valid: ValidationStatus.UNTOUCHED,
      value: "",
    },
  };
