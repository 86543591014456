import { createContext, useState } from "react";
import { SalesRoundingToolPageSection } from "../sso/salesRoundingTool/salesRoundingTool.enum";
import {
  ISalesRoundingPatientDetails,
  ITerritoryFacility,
} from "../sso/salesRoundingTool/salesRoundingTool.interface";
import { defaultSalesRoundingPatientDetails } from "../sso/salesRoundingTool/salesRoundingTool.model";

export type ISalesRoundingContextType = {
  pageSection: SalesRoundingToolPageSection;
  setPageSection: React.Dispatch<
    React.SetStateAction<SalesRoundingToolPageSection>
  >;
  selectedFacility: ITerritoryFacility | null;
  setSelectedFacility: React.Dispatch<
    React.SetStateAction<ITerritoryFacility | null>
  >;
  data: ISalesRoundingPatientDetails;
  setData: React.Dispatch<React.SetStateAction<ISalesRoundingPatientDetails>>;
  resetContext: Function;
  srtPatientDetailsUpdated: boolean;
  setSrtPatientDetailsUpdated: React.Dispatch<React.SetStateAction<boolean>>;
};

type SalesRoundingToolContextProviderProps = {
  children: React.ReactNode;
};

export const SaleRoundingToolContext =
  createContext<ISalesRoundingContextType | null>(null);

export const SaleRoundingToolContextProvider = ({
  children,
}: SalesRoundingToolContextProviderProps) => {
  const [pageSection, setPageSection] = useState<SalesRoundingToolPageSection>(
    SalesRoundingToolPageSection.FACILITY_SELECTION
  );
  const [selectedFacility, setSelectedFacility] =
    useState<ITerritoryFacility | null>(null);
  const [data, setData] = useState<ISalesRoundingPatientDetails>(
    defaultSalesRoundingPatientDetails
  );
  const [srtPatientDetailsUpdated, setSrtPatientDetailsUpdated] =
    useState(false);
  const resetContext = () => {
    setPageSection(SalesRoundingToolPageSection.FACILITY_SELECTION);
    setSelectedFacility(null);
    setSrtPatientDetailsUpdated(false);
  };
  return (
    <SaleRoundingToolContext.Provider
      value={{
        data,
        setData,
        pageSection,
        setPageSection,
        selectedFacility,
        setSelectedFacility,
        resetContext,
        srtPatientDetailsUpdated,
        setSrtPatientDetailsUpdated,
      }}
    >
      {children}
    </SaleRoundingToolContext.Provider>
  );
};
