export const RouteWithStickyFooter = [
  "/manageProfile",
  "/registration",
  "/home/pickUpRequest",
  "/home/sendNote",
  "/facilitySettings",
  "/inventory/inventoryAdjustment",
  "/inventory/createServiceRequest",
  "/administration/rolesPermissions",
  "/administration/manageUsers/userProfile",
  "/administration/facilitySettings",
  "/administration/manageSiteStatuses/siteStatusDetail",
  "/orders/inpatientOrder",
  "/home/acuteOrderPickUpRequest",
  "/orders/inpatientSupplyOrder",
  "/orders/inpatientStockSupplyOrder",
  "/salesAssistance",
  "/salesRounding",
];
