import { format } from "react-string-format";
import {
  SVC_GET_ACUTE_ORDER_SUPPLIES_DELIVERY_DETAILS,
  SVC_GET_ACUTE_ORDER_SUPPLIES_SHIPPING_COST_DETAILS,
  SVC_GET_CUSTOMER_INFORMATION,
  SVC_GET_PRODUCT_PRICE_AND_AVAILABILITY,
  SVC_RENTAL_PO_DETAILS,
} from "./staticText";
import { getKeyData } from "./encryptDecrypt";
export const rentalPODetails = async (reqParams: any) => {
  try {
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const baseUrl = process.env.REACT_APP_3ME_SVC_BASE_URL ?? "";
    const submitInventoryAdjustmentUrl = format(
      "{0}{1}",
      baseUrl,
      SVC_RENTAL_PO_DETAILS
    );
    const authorizationToken = format("Bearer {0}", accessToken);
    const response = await fetch(submitInventoryAdjustmentUrl, {
      method: "POST",
      headers: {
        AuthorizationKey: process.env.REACT_APP_3ME_SVC_API_KEY ?? "",
        Authorization: authorizationToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(reqParams),
    });
    if (response.status === 200) {
      const data = response.json();
      return data;
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getCustomerInformation = async (params: any) => {
  try {
    const baseUrl = process.env.REACT_APP_FACILITY_MANAGER_FUNC_BASE_URL ?? "";
    const submitAcuteOrderdetailsURL = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_CUSTOMER_INFORMATION
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = AuthDetails ? JSON.parse(AuthDetails ?? "") : "";
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(submitAcuteOrderdetailsURL, {
      method: "POST",
      headers: {
        "x-functions-key": funckeyParse.FacilityManagerFunction ?? "",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(params),
    });
    if (response.status === 200) {
      return response.json();
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getProductPriceAndAvailability = async (params: any) => {
  try {
    const baseUrl = process.env.REACT_APP_PRODUCT_MGR_FUNC_BASE_URL ?? "";
    const getProductAndPriceDetailsURL = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_PRODUCT_PRICE_AND_AVAILABILITY
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(getProductAndPriceDetailsURL, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "x-functions-key": funckeyParse.ProductManagerFunction ?? "",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(params),
    });
    if (response.status === 200) {
      return response.json();
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getAcuteSupplyOrderDeliveryDetails = async (params: any) => {
  try {
    const baseUrl = process.env.REACT_APP_PRODUCT_MGR_FUNC_BASE_URL ?? "";
    const getProductAndPriceDetailsURL = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_ACUTE_ORDER_SUPPLIES_DELIVERY_DETAILS
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(getProductAndPriceDetailsURL, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "x-functions-key": funckeyParse.ProductManagerFunction ?? "",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(params),
    });
    if (response.status === 200) {
      return response.json();
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getAcuteSupplyOrderShippingDetails = async (params: any) => {
  try {
    const baseUrl = process.env.REACT_APP_PRODUCT_MGR_FUNC_BASE_URL ?? "";
    const getAcuteSupplyOrderShippingDetailsURL = format(
      "{0}{1}",
      baseUrl,
      SVC_GET_ACUTE_ORDER_SUPPLIES_SHIPPING_COST_DETAILS
    );
    const AuthDetails = sessionStorage.getItem("okta-token-storage");
    const data = JSON.parse(AuthDetails ?? "");
    const accessToken = data.accessToken?.accessToken;
    const authorizationToken = format("Bearer {0}", accessToken);
    const funckeyParse = getKeyData();
    const response = await fetch(getAcuteSupplyOrderShippingDetailsURL, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "x-functions-key": funckeyParse.ProductManagerFunction ?? "",
        Authorization: authorizationToken,
      },
      body: JSON.stringify(params),
    });
    if (response.status === 200) {
      return response.json();
    } else {
      return response;
    }
  } catch (error) {
    console.log("error", error);
  }
};
