export const ERROR_MSG_CONFIRM_PLACEMENT =
  "Your request to begin the Confirm Placement process has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_FORGOT_PWD =
  "Your forgot password request has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at (800) 275-4524 ext 41858.";
export const ERROR_MSG_SUPPLY_ORDER =
  "Your supply order request has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_SUPPLY_ORDER_INITIAL_LOAD =
  " Your request to place a supply order has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_SEND_NOTE =
  "Your send a note request has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_PICKUP_REQUEST_CONFIRM =
  " Your request to print the shipping label has failed. Please try again or contact 3M for assistance with this order";
export const ERROR_MSG_PICKUP_REQUEST_LOAD =
  "Your pickup request has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_DISCHARGE_REQUEST_LOAD =
  " Your request to print the shipping label has failed. Please try again or contact 3M for assistance with this order ";
export const ERROR_MSG_DIS_REQ_LOAD =
  "Your request to discharge therapy has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_VAC_ORDER_SAVE_SUBMIT_UPDATE =
  "Your request to save/update/submit the V.A.C.<sup>®</sup> Therapy Order has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_ADD_WOUND_ASSESMENT =
  "Your request to add a wound assessment has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_UPDATE_CONTATCT_INFO =
  "Your request to update the Manage Profile details has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_UPDATE_PRESCRIPTION_TYPE =
  "Your request to update the prescription type has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_MYLIST_PRESCRIBERS =
  "Your request to load the prescriber data has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_UPDATE_CONTACT_US =
  "Your request to submit a Contact Us email has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_CANCEL_VAC_ORDER =
  "Your request to cancel/stop the V.A.C.<sup>®</sup> Therapy Order has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_MPD_PATIENT_LOAD =
  "Your request to retrieve the patient data has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_HOLD_THERPAY =
  " Your request to hold therapy has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_RESUME_THERPAY =
  " Your request to resume therapy has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_STOP_SHARING_ORDER =
  " Your request to cancel/stop sharing the V.A.C.<sup>®</sup> Therapy Order has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const PRODUCT_SERVICE_ERROR =
  "Your request to get product details has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at (800) 275-4524.";
export const ERROR_CMS_CONTENT =
  "Your request to get CMS Content has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at (800) 275-4524.";
export const ERROR_VAC_ORDER_DEL =
  "Your request to Delete the V.A.C.<sup>®</sup> Therapy Order has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_GET_UNLINKED_USERS =
  "Your request to get unlinked users has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_LINK_TO_FACILITY =
  "Your request to update the User/Facility link has failed. Please try again or contact 3M™ Express Therapy Portal support team at ";
export const ERROR_TO_SELECT_ANY_OPTION =
  "Please select any one of the above option";
export const ERROR_TO_ADD_CONTACT_DETAIALS = "Please add contact details";
export const ERROR_TO_ADD_DELIVERY_ADDRESS = "Please add delivery address";
export const ERROR_TO_SEARCH_AND_SELECT_HOME_CARE_PROVIDER =
  "Please search and select Home Care Provider";
export const ERROR_CONFIRM_PLACEMENT_SITEID_MATCHED =
  "The V.A.C.<sup>®</sup> Therapy Unit is pending confirmation of placement by the requesting facility.";
export const ERROR_INVALID_INCOMPLETE_DATA = "Remove invalid / incomplete data";
export const ERROR_MSG_INITIAL_SUPPLY_ORDER_LOAD =
  "Your request to retrieve the previous supply order data has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const KEYVALUT_SERVICE_ERROR =
  "Request to load for a Scanbot settings has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const PRESCRIBER_SEARCH_DB_SERVICE_ERROR =
  "Your request to search for a Prescriber has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const PRESCRIBER_SEARCH_NPI_SERVICE_ERROR =
  "Your request to search for a Prescriber has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const HOME_CARE_PROVIDER_SEARCH_MS_SERVICE_ERROR =
  "Your request to search for a Home Care Provider has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const HOME_CARE_PROVIDER_SEARCH_DB_SERVICE_ERROR =
  "Your request to search for a  Home Care Provider has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const ALL_FACILITY_SEARCH_SERVICE_ERROR =
  "Your request to search for a facility has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const HCP_FACILITY_LOAD_SERVICE_ERROR =
  "Your request to load Home Care Provider has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const HCP_FACILITY_UPDATE_SERVICE_ERROR =
  "Your request to update Home Care Provider has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_ADD_PRESCRIBER =
  "Your request to add the prescriber has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_ADD_HOME_CARE_PROVIDER =
  "Your request to add the Home Care Provider has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_GET_PRESCRIBER_DETAILS =
  "Your request to load the prescriber details has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_UPDATE_PRESCRIBER_DETAILS =
  "Your request to update the prescriber details has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_REMOVE_PRESCRIBER =
  "Your request to remove the prescriber has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_UPDATE_PRESCRIBER =
  "Your request to enable/disable the prescriber has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const FILE_UPLOAD_INVALID_FILETYPE_ERROR_MESSAGE =
  "One of your files has invalid file type. Please remove it from the Upload Documents section and try again to save the order.";
export const FILE_UPLOAD_UNABLETOSCAN_ERROR_MESSAGE =
  "One of your files has error from scan service. Please remove it from the Upload Documents section and try again to save the order.";
export const ERROR_MSG_VIEW_REPORT_IN_PROCESS =
  "Your request to retrieve the in-process orders has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_ORDER_DETAIL_FAILED =
  "Your request to view the order has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_DOWNLOAD_REPORT_IN_PROCESS =
  " Your request to download the order report has failed. Please try again or contact 3M for assistance with this order ";
export const ERROR_MSG_EXCHANGE_VAC =
  "Your request to exchange the V.A.C.<sup>®</sup> Therapy Unit has failed to process due to a technical issue. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_DOWNLOAD_MY_PATIENTS =
  "Your request to download the patients has failed. Please try again or contact 3M for assistance with this order ";
export const ERROR_MSG_DOWNLOAD_INVENTORY_READY_CARE =
  "Your request to download the inventory list has failed. Please try again or contact 3M for assistance with this order ";
export const ERROR_MSG_POPUP_BLOCKER =
  "Please update your browser setting so that pop-ups are not blocked";
export const ERROR_MSG_NO_PATIENTS_MPD =
  "We are unable to display the facility's patient list at this time due to a technical issue. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_FACILITY_ADMIN_DETAILS =
  "Your request to load the Facility Admin details has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_UPDATE_USER_DETAILS =
  "Your request to update the user details has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_CREATE_USER_DETAILS =
  "Your request to create the user has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_ACUTE_ORDER_DETAIL =
  "Your request to retrieve the order detail has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_ADD_PATIENT =
  "Your request to update the user details has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_ADD_ACUTE_PATIENT =
  "We are unable to display the Acute patient search results at this time due to a technical issue. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_SEARCH_AND_ADD_PATIENT =
  "We are unable to add the patient at this time due to a technical issue. Please try again later.";
export const ERROR_MSG_SEARCH_PATIENT =
  "We are unable to search the patient at this time due to a technical issue. Please try again later.";
export const ERROR_MSG_VAC_ORDER_UPLOAD_DOCUMENT =
  "Your order was successfully submitted but we encountered a problem uploading the document(s) below. Please submit the document(s) below within the Documents tab once it becomes available for this patient.";
export const ERROR_MSG_ACUTE_ORDER_VIEW =
  "Your request to view the order details has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_PROOF_OF_DELIVERY =
  "Your request to submit proof of delivery has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_SEND3M_NOTE =
  "Your request to send a note has failed. Please try again or contact 3M for assistance with this order 1-800-275-4524";
export const STORAGE_LOCATION_LOAD_SERVICE_ERROR =
  "Your request to load Storage Locations has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const STORAGE_LOCATION_ADD_LOC_SERVICE_ERROR =
  "Your request to add Storage Location has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const STORAGE_LOCATION_UPDATE_SERVICE_ERROR =
  "Your request to update Storage Location has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_DUPLICATE_STORAGE_LOCATIONS =
  "A duplicate Storage Location has been entered. Please update the Storage Location.";
export const ERROR_MSG_GET_SITE_STATUSES =
  "Your request to get site status has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_ADD_SITE_STATUS =
  "Your request to add/edit the Site Status Detail has failed. Please try again.";
export const EXCHANGE_VAC_SERIAL_NUMBER_EMPTY =
  "There are no ActiV.A.C.™ consignment units currently available at your facility.";
export const EXCHANGE_VAC_SERIAL_NUMBER_EMPTY_ACUTE_ORDER =
  "There is currently no inventory available to perform an exchange. For assistance, please contact the Express Therapy Portal support team at ";
export const EXCHANGE_VAC_GET_SERIAL_NUMBER_API_ERROR =
  "Your request to select a Ready Care Serial Number for this patient has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_SEARCH_USERS =
  "Your request to search for a User has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at 1-800-275-4524 ext.41858.";
export const ERROR_LINK_TO_FACILITY_SELECT =
  "Your request to update the User/Facility link has failed. Please try again or contact 3M™ Express Therapy Portal support team at 1-800-275-4524 ext.41858.";
export const ERROR_MSG_UPDATE_PRESCRIBER_EMAIL =
  "Your request to update the prescriber details has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const EXCHANGE_VAC_SUBMIT_POS_DWONLOAD_ERROR =
  "Your request to download and print the Proof of Service has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_ADD_NEW_USER =
  "Your request to add new users has failed. Please try again or contact 3M for assistance with this user 1-800-275-4524.";
export const SVC_FETCH_USERS =
  "Your request to fetch users details has failed. Please try again or contact 3M for assistance with this user 1-800-275-4524.";
export const SVC_FETCH_FACILITIES =
  "Your request to fetch facilities has failed. Please try again or contact 3M for assistance with this user 1-800-275-4524.";
export const SVC_RESET_PASSWORD =
  "Your request to initiate a password Reset for the user has failed. Please try again or contact 3M for assistance with this user 1-800-275-4524.";
export const SVC_UPDATE_USER_PROFILE_DETAILS =
  "Your request to update the user profile details has failed. Please try again or contact 3M for assisstance with this user 1-800-275-4524.";
export const SVC_ADD_USER_PROFILE =
  "Your request to add the user profile details has failed. Please try again or contact 3M for assistance with this user 1-800-275-4524.";
export const SVC_FETCH_USER_DETAILS =
  "Your request to fetch user details has failed. Please try again or contact 3M for assistance with this user 1-800-275-4524.";
export const SVC_SEND_NOTE_FAILURE =
  "Your request to Send a note has failed. Please try again or contact 3M for assistance with this order at ";
export const PICKUP_LOCATION_LOAD_SERVICE_ERROR =
  "Your request to display the Pickup Location options has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SUBMIT_RETURN_SERVICE_ERROR =
  "Your request to return the unit has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_UPDATE_INVENTORY_LOCATION_ERROR_MESSAGE =
  "Your request to update the Inventory Storage Location has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_INVENTORY_ADJUSTMENT_ERROR_MESSAGE =
  "Your request to adjust your V.A.C.<sup>®</sup> Ready Care inventory has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const MOVE_UNIT_TO_READY_CARE_SERVICE_ERROR =
  "Your request to move the unit to the V.A.C.® Ready Care listing has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const MOVE_UNIT_TO_STORED_PRODUCT_SERVICE_ERROR =
  "Your request to move the unit to the Stored Product listing has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_INVALID_PHONE_NUMBER =
  "Phone Number - Remove invalid/incomplete data. Phone number must begin with value other than 0 or 1";
export const STATIC_TEXT_END_THERAPY_ERROR =
  "The request to return your Customer Plus Inventory unit for preparation has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const STATIC_TEXT_MAKE_UNIT_AVAILABLE_ERROR =
  "The request to make your Customer Plus Inventory unit available for placement has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_UPDATE_PERMISSION =
  "Your  request to update permission has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const FETCH_SERVICE_OPTIONS_FAILED =
  "The request to retrieve the service options has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SUBMIT_CREATE_SERVICE_REQUEST_FAILED =
  "The request to submit the create service request has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const PICKUP_PENDING_ERROR =
  "The request to determine the Pickup Pending status for each Stored Product unit has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SERVICE_PENDING_ERROR =
  "The request to determine the Service Pending status for each Customer Plus unit has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const FETCH_STATES_FAILED =
  "The request to fetch states has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_IN_PATIENT_ORDER_SUBMIT =
  "Your request to submit an In-Patient Order has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_IN_PATIENT_SUPPLY_ORDER_SUBMIT =
  "Your request to submit an In-Patient Supply Order has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_STOCK_SUPPLY_ORDER_SUBMIT =
  "Your request to submit a Stock Supply Order has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_STORED_PRODUCT_IN_PATIENT_ORDER_SUBMIT =
  "The request to submit or save the In-Patient Stored Product order has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_SOLVENTUM_PRODUCT_IN_PATIENT_ORDER_SUBMIT =
  "The request to submit or save the In-Patient Solventum Product order has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_SERVICE_ALREADY_REQUESTED =
  '<p>A Service Work Order has already been opened for this unit. Please contact the 3M™ Express Therapy Portal support team at  <a className="phone-value" href="tel:18002754524,41858">(800) 275-4524 ext 41858</a> with any questions.</p>';
export const SVC_FACILITY_SEARCH_FAILED =
  "Your request to search for a facility has failed. Please try again or contact the 3M™ Express Therapy Portal support team at 1-800-275-4524 ext.41858.";
export const SVC_NO_FACILITIES_FOUND =
  "No search results found. Please try again or contact the 3M™ Express Therapy Portal support team at 1-800-275-4524 ext.41858.";
export const SVC_FETCH_CUSTOMER_PLUS_INFO =
  "Your request to fetch customer plus Patient details has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_GET_CUSTOMER_INFORMATION_ERROR =
  "Your request to fetch customer information has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_GET_TERRITORY_FACILITY_ERROR =
  "Your request to fetch territory facilities has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_GET_SOLVENTUM_PRODUCT =
  "Your request to fetch Acute Solventum Product has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_ORDER_NOT_SAVED_TO_ACUTE_INPATIENT_ORDER =
  "Wound details were not added for this order as the wound data could not be saved. Please go to <strong>My Patients</strong> and try again later.";
export const SVC_ORDER_NOT_SUBMITTED =
  "Your request to submit an order has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_WOUND_NOT_SUBMITTED =
  "Your request to submit wound details has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_UPDATE_HOMECAREPROVIDER_DETAILS =
  "Your request to load the homecare provider details has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SVC_ACUTE_ORDER_PICK_UP_REQUST_ERROR =
  "Your request to complete a Pickup/Discharge for this unit has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_FACILITY_SUPPLY_ORDER_LOAD =
  "Your request to retrieve the facility supply order data has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_FACILITY_SUPPLY_ORDER_DOWNLOAD_CSV =
  "Your request to download the facility supply order data has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_GET_ASSESSMENTS_FOR_WOUND_ASSESSMENT_REPORT =
  "Your request to get assessment has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_GET_All_FACILITY =
  "Your request to fetch all the facilities has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_GET_PATIENTS_FOR_WOUND_ASSESSMENT_REPORT =
  "Your request to fetch order details has failed. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_GET_INPATIENT_SUPPLY_PRODUCTS =
  "The Supply List details are not available at this time. Please try again or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_CHECK_PRODUCT_PRICE_AND_AVAILABILITY =
  "We are unable to determine the availability of the supplies at this time. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_PRODUCT_OUT_OF_STOCK =
  " is currently unavailable. Please check back later.";
export const ERROR_MSG_ESTIMATE_DELIVERY_ACUTE_SUPPLY_ORDER_ERROR =
  "We are unable to determine the estimated delivery date at this time. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_SHIPPING_TOTAL_ACUTE_SUPPLY_ORDER_ERROR =
  "We are unable to determine the shipping total at this time. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_GET_ACUTE_SUPPLY_ORDER_DETAILS =
  "The Stock Supply order details are not available at this time. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_GET_GET_CURRENT_DATE_TIME =
  "Your request to get the Current Date Time failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_TO_SUBMIT_SEND_MESSAGE =
  "Your request to submit a Help request has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at";
export const ERROR_MSG_TO_UPDATE_ACUTE_ORDER_DATA =
  "Your request to update the order details has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at  ";
export const ERROR_MSG_SRT_FACILITES_PATIENT =
  "Your request to load the patient data has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_SSO_ADD_INTERNAL_USER =
  "Your request to add/update user data has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_SSO_FETCH_INTERNAL_USER =
  "Your request to fetch user details has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_SSO_FAVOURITE_FACILITY_ERROR =
  "Your request to fetch facility details has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_GET_TERRITORY_DETAILS =
  "Your request to get the territory details has failed. Please try again later or contact the 3M™ Express Therapy Portal support team at ";
export const ERROR_MSG_EXCESSIVE_SUPPLY_PDF_FAIL =
  "Your request to download the 'Letter of Medical Necessity for Excessive Supplies' has failed. Please Try again later or contact the 3M™ Express Therapy Portal support team at ";
export const SESSION_EXPIRED_ERROR_MSG_UPDATE_USER_DETAILS =
  "Your request to update the user details has failed. Please enter the valid authentication code within 10 minutes.";
export const SRT_PATIENT_DETAIl_UPDATE =
  "Your request to update the user details has failed. Please Try again later or contact the 3M™ Express Therapy Portal support team at ";
  export const ERROR_MSG_DUPLICATE_CHECK_FAILED =  "The duplicate order check has failed. Please continue with your order.";