
import { ValidationStatus } from "../../../../core/interfaces/input.interface";
import { IPatientStatus } from "./srtPatientStatus.interface";

export const defaultSrtStatusData: IPatientStatus = {
  dischargeStatus: {
    componentId: "srt-Status-DischargeStatus",
    id:"srt-Status-DischargeStatus-select",
    order: 6,
    required: true,
    title: "Discharge Status",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  notTransitioningHomeReason: {
    componentId: "srt-Status-notTransitioningToHomeReason",
    id:"srt-Status-notTransitioningToHomeReason-select",
    order: 7,
    required: false,
    title: "Reason for Patient Not Transitioning Home",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  other: {
    componentId: "srt-Status-Other-desc",
    id:"srt-Status-Other-desc-select",
    order: 8,
    required: false,
    title: "Describe more about the reason",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  }
};
