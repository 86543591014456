import { useContext, useState } from "react";
import {
  IInputField,
  ValidationStatus,
} from "../../../../../core/interfaces/input.interface";
import "./salesAssistanceAdditionalInformation.css";
import { SalesAssistanceNeedHelpValidator } from "../../salesAssistanceNeedhelp.validator";
import { Grid, InputBase, Typography } from "@mui/material";
import { InputWithLabel } from "../../../../../core/inputWithLabel/inputWithLabel.component";
import {
  IMobileDisplayContext,
  MobileDisplayContext,
} from "../../../../../context/MobileDisplayContext";

export interface IAdditionalInformation {
  data: IAdditionalInfoData;
  setData: Function;
  Validator?: SalesAssistanceNeedHelpValidator;
  isFromSalesAssistance?: boolean;
}

export interface IAdditionalInfoData {
  additionalInformation: IInputField;
}
export const SalesAssistanceAdditionalInformation = ({
  data,
  setData,
  Validator = new SalesAssistanceNeedHelpValidator(),
}: IAdditionalInformation) => {
  const [validator] = useState<SalesAssistanceNeedHelpValidator>(Validator!);
  const { isMobileScreen } =
    useContext<IMobileDisplayContext>(MobileDisplayContext);
  const validateAndSetData = (e: any) => {
    const { name, value } = e.target;
    const isValid = validator.validate(value, name);
    let tempData = {
      ...data,
      [name]: {
        ...Object(data)[name],
        errorMessage: null,
        valid: isValid!.status,
        value: value,
      },
    };
    setData(tempData);
  };

  return (
    <div
      className="sales-assistance-additional-info-component"
      data-testid="sales-assistance-additional-info-component"
      id="sales-assistance-additional-info-component"
    >
      <Grid
        className="additional-info-grid-container"
        container
        xs={isMobileScreen ? 11 : 8}
      >
        <Typography
          className="additional-info-header"
          data-testid="additional-info-header"
          id="additional-info-header"
        >
          Additional information
        </Typography>
        <Grid className="additional-info-grid-item" item xs={12}>
          <InputWithLabel
            label={data.additionalInformation.title}
            labelClassName="additional-info-input-label"
            error={
              data?.additionalInformation?.valid === ValidationStatus.INVALID
            }
            isRequired={data.additionalInformation.required}
            testId="additional-info-label"
            isShowWarning={true}
            warningMessage={data.additionalInformation.errorMessage}
            id={data.additionalInformation.componentId}
          >
            <InputBase
              className="additional-info-input"
              data-testid="additional-info-input"
              error={
                data?.additionalInformation?.valid === ValidationStatus.INVALID
              }
              inputProps={{
                className:
                  data?.additionalInformation?.valid ===
                  ValidationStatus.INVALID
                    ? "showCommentError"
                    : "noCommentError",
                maxLength: 500,
              }}
              multiline={true}
              name="additionalInformation"
              onChange={validateAndSetData}
              rows={4}
              value={data?.additionalInformation?.value}
            />
          </InputWithLabel>
        </Grid>
      </Grid>
    </div>
  );
};
