import {
  IInpatientWoundInformation,
  IWoundInfoDropDownValues,
  IWoundInformation,
} from "./woundInformation.interface";
import {
  IInputField,
  ValidationStatus,
} from "../../../../core/interfaces/input.interface";

export let defaultWoundInfoCount: IInputField = {
  componentId: "in-patient-order-wound-qty-count",
  id: "in-patient-order-wound-qty-count",
  isDefaultValid: true,
  order: 11,
  required: false,
  title: "",
  valid: ValidationStatus.VALID,
  value: "0",
};

export const defaultInPatientPrimaryWoundInfo: IInpatientWoundInformation = {
  woundType: {
    componentId: "in-patient-order-wound-type",
    id: "in-patient-order-wound-type-input",
    order: 12,
    required: true,
    title: "Primary Wound Type",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundLocation: {
    componentId: "in-patient-order-wound-location",
    id: "in-patient-order-wound-location-input",
    order: 13,
    required: true,
    title: "Wound Location",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundDirection: {
    componentId: "in-patient-order-wound-direction",
    id: "in-patient-order-wound-direction-input",
    isOptional: true,
    order: 14,
    required: false,
    title: "Wound Direction",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundOrientation: {
    componentId: "in-patient-order-wound-orientation",
    id: "in-patient-order-wound-orientation-input",
    isOptional: true,
    order: 15,
    required: false,
    title: "Wound Orientation",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundMeasurementDate: {
    componentId: "in-patient-order-wound-measurment-date",
    id: "in-patient-order-wound-measurment-date-input",
    order: 16,
    required: false,
    title: "Wound Measurement Date",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundLength: {
    componentId: "in-patient-order-wound-length",
    id: "in-patient-order-wound-length-input",
    isOptional: true,
    order: 17,
    required: false,
    title: "Length",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundWidth: {
    componentId: "in-patient-order-wound-width",
    id: "in-patient-order-wound-width-input",
    isOptional: true,
    order: 18,
    required: false,
    title: "Width",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundDepth: {
    componentId: "in-patient-order-wound-depth",
    id: "in-patient-order-wound-depth-input",
    isOptional: true,
    order: 19,
    required: false,
    title: "Depth",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundVolume: {
    componentId: "in-patient-order-wound-volume",
    id: "in-patient-order-wound-input",
    isOptional: true,
    required: false,
    title: "Volume",
    valid: ValidationStatus.VALID,
    value: "",
  },
};

export const defaultInPatientSecondaryWoundInfo: IInpatientWoundInformation = {
  woundLocation: {
    componentId: "in-patient-order-secondary-wound-location",
    id: "in-patient-order-secondary-wound-location-input",
    order: 20,
    required: true,
    title: "Wound Location",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundType: {
    componentId: "in-patient-order-secondary-wound-type",
    id: "in-patient-order-secondary-wound-type-input",
    order: 21,
    required: true,
    title: "Secondary Wound Type",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundDirection: {
    componentId: "in-patient-order-secondary-wound-direction",
    id: "in-patient-order-secondary-wound-direction-input",
    isOptional: true,
    order: 22,
    required: false,
    title: "Wound Direction",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundOrientation: {
    componentId: "in-patient-order-secondary-wound-orientation",
    id: "in-patient-order-secondary-wound-orientation-input",
    isOptional: true,
    order: 23,
    required: false,
    title: "Wound Orientation",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundMeasurementDate: {
    componentId: "in-patient-order-secondary-wound-measurment-date",
    id: "in-patient-order-secondary-wound-measurment-date-input",
    order: 24,
    required: false,
    title: "Wound Measurement Date",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundLength: {
    componentId: "in-patient-order-secondary-wound-length",
    id: "in-patient-order-secondary-wound-length-input",
    isOptional: true,
    order: 25,
    required: false,
    title: "Length",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundWidth: {
    componentId: "in-patient-order-secondary-wound-width",
    id: "in-patient-order-secondary-wound-width-input",
    title: "Width",
    isOptional: true,
    order: 26,
    required: false,
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundDepth: {
    componentId: "in-patient-order-secondary-wound-depth",
    id: "in-patient-order-secondary-wound-depth-input",
    isOptional: true,
    order: 27,
    required: false,
    title: "Depth",
    valid: ValidationStatus.UNTOUCHED,
    value: "",
  },
  woundVolume: {
    componentId: "in-patient-order-secondary-wound-volume",
    id: "in-patient-order-secondary-wound-input",
    title: "Volume",
    isOptional: true,
    required: false,
    valid: ValidationStatus.VALID,
    value: "",
  },
};

export let defaultWoundInformation: IWoundInformation = {
  woundInfoCount: defaultWoundInfoCount,
  primaryWoundInformation: defaultInPatientPrimaryWoundInfo,
  secondaryWoundInformation: defaultInPatientSecondaryWoundInfo,
};

export const defaultWoundInfoDropDownValues: IWoundInfoDropDownValues = {
  woundDirectionDropDownValues: [],
  woundLocationDropDownValues: [],
  woundOrientationDropDownValues: [],
  woundTypeDropDownValues: [],
};
