import {
  IInputField,
  Validation,
  ValidationStatus,
} from "../../core/interfaces/input.interface";
import { getDeepClone } from "../../util/ObjectFunctions";
import { Validator } from "../../util/order.validations";
import { getValidObj, updateErrorMessage } from "../../util/utilityFunctions";

export class SalesRoundingValidator {
  private _validator;
  constructor(defValidator = new Validator()) {
    this._validator = defValidator;
  }

  private noValidation(): Validation {
    return getValidObj();
  }

  private fieldToMethodMapping(
    field: string
  ): ((txt: string) => Validation) | undefined {
    const mapping = new Map<string, (txt: string) => Validation>([
      ["firstName", this._validator.inPatientOrderNameValidation],
      ["lastName", this._validator.inPatientOrderNameValidation],
      ["dateOfBirth", this._validator.dobValidation],
      ["room", this._validator.roomValidation],
      ["patientId", this._validator.patientIdValidation],
      ["dischargeStatus", this._validator.emptyCheck],
      ["notTransitioningHomeReason", this._validator.emptyCheck],
      ["other", this._validator.exchangeVacCommentsValidation],
      [
        "transitionTherapyStartDate",
        this._validator.transitionTherapyDateValidation,
      ],
      ["leadGeneration", this._validator.emptyCheck],
      ["serviceCovering", this._validator.emptyCheck],
      ["woundType", this._validator.emptyCheck],
      ["woundLocation", this._validator.emptyCheck],
      ["woundDirection", this._validator.emptyCheck],
      ["woundOrientation", this._validator.emptyCheck],
      [
        "woundMeasurementDate",
        this._validator.measurementDateInPatientValidation,
      ],
      ["woundLength", this._validator.woundDimension],
      ["woundWidth", this._validator.woundDimension],
      ["woundDepth", this._validator.woundDimension],
    ]);
    const validator = mapping.get(field);
    return validator ? validator : this.noValidation;
  }

  public validate(input: string, field: string) {
    try {
      const validator = this.fieldToMethodMapping(field)!;
      return validator(input);
    } catch (error) {
      console.log(`validator method for field ${field} is not configured`);
    }
  }

  public validateAll(
    data: any,
    updateDataIfUntouchedAndValidated: Function
  ): [ValidationStatus, IInputField | null] {
    let tempData = getDeepClone(data);
    let smallestOrderWithInvalidStatus: IInputField | null = null;
    const nonValidatedFields = [
      "orderInformation",
      "attendingPhysician",
      "prescribingPhysician",
    ];
    Object.keys(tempData).forEach((key: string) => {
      const subTempData = tempData[key];
      if (subTempData) {
        let subTempDataValue = tempData[key].value;
        if (key === "patientWoundInformation") {
          smallestOrderWithInvalidStatus = this.validateWoundInformation(
            tempData[key],
            smallestOrderWithInvalidStatus
          )[1];
        } else if (tempData[key].hasChildsToValidate && subTempDataValue) {
          let isAllValid = ValidationStatus.VALID;
          Object.keys(subTempDataValue).forEach((subkey: string) => {
            let inputField = subTempDataValue[subkey];
            smallestOrderWithInvalidStatus = this.validateInputField(
              inputField,
              smallestOrderWithInvalidStatus
            );
            if (inputField.valid === ValidationStatus.INVALID) {
              if (isAllValid !== ValidationStatus.INVALID) {
                isAllValid = ValidationStatus.INVALID;
              }
            } else if (
              subTempDataValue[subkey].valid === ValidationStatus.UNTOUCHED
            ) {
              subTempDataValue[subkey].valid = ValidationStatus.INVALID;
            }
          });
          tempData = {
            ...tempData,
            [key]: {
              ...Object(tempData)[key],
              valid: isAllValid,
              value: subTempDataValue,
            },
          };
        } else {
          if (!nonValidatedFields.includes(key)) {
            Object.keys(tempData[key]).forEach((x: string) => {
              if (
                tempData[key][x].isOptional === true &&
                tempData[key][x].valid !== ValidationStatus.VALID
              ) {
                tempData[key][x].valid = ValidationStatus.VALID;
              } else if (
                tempData[key][x].required &&
                tempData[key][x].valid === ValidationStatus.UNTOUCHED
              ) {
                tempData[key][x].valid = ValidationStatus.INVALID;
              } else if (
                tempData[key][x].required === false &&
                tempData[key][x].valid === ValidationStatus.UNTOUCHED
              ) {
                tempData[key][x].valid = ValidationStatus.VALID;
              } else if (
                tempData[key][x].isDefaultValid &&
                tempData[key][x].valid !== ValidationStatus.VALID
              ) {
                tempData[key][x].valid = ValidationStatus.INVALID;
              }
              // Find smallest order number which has invalid status
              if (
                tempData[key][x].valid === ValidationStatus.INVALID &&
                tempData[key][x].order &&
                ((smallestOrderWithInvalidStatus &&
                  smallestOrderWithInvalidStatus.order! > 45) ||
                  !smallestOrderWithInvalidStatus)
              ) {
                if (
                  !smallestOrderWithInvalidStatus ||
                  (smallestOrderWithInvalidStatus.order &&
                    tempData[key][x].order &&
                    smallestOrderWithInvalidStatus.order >
                      tempData[key][x].order)
                ) {
                  smallestOrderWithInvalidStatus = tempData[key][x];
                }
              }
              updateErrorMessage(tempData[key][x]);
            });
          } else {
            let inputField = tempData[key];
            smallestOrderWithInvalidStatus = this.validateInputField(
              inputField,
              smallestOrderWithInvalidStatus
            );
          }
        }
      }
    });
    updateDataIfUntouchedAndValidated(tempData);
    const patientInformation = getDeepClone(tempData.patientInformation);
    const patientStatus = getDeepClone(tempData.patientStatus);
    const isPatientInformationIsValid = Object.keys(patientInformation)
      .filter((key: string) => patientInformation[key].valid)
      .every(
        (key: string) =>
          patientInformation[key].valid === ValidationStatus.VALID
      );
    const ispatientStatusIsValid = Object.keys(patientStatus)
      .filter((key: string) => patientStatus[key].valid)
      .every(
        (key: string) => patientStatus[key].valid === ValidationStatus.VALID
      );
    const ifAllValid = Object.keys(tempData)
      .filter(
        (key: string) => tempData[key].isDefaultValid || tempData[key].required
      )
      .every((key: string) => tempData[key].valid === ValidationStatus.VALID);
    return [
      ifAllValid && isPatientInformationIsValid && ispatientStatusIsValid
        ? ValidationStatus.VALID
        : ValidationStatus.INVALID,
      smallestOrderWithInvalidStatus,
    ];
  }

  private validateWoundInformation(
    woundData: IInputField,
    smallestOrderWithInvalidStatus: IInputField | null
  ): [IInputField, IInputField | null] {
    let woundDetails = woundData.value;
    let woundCount = Number(woundDetails.woundInfoCount.value);
    if (woundCount > 0) {
      let isWoundInformationValid = true;
      Object.keys(woundDetails.primaryWoundInformation).forEach(
        (key: string) => {
          let woundDetail = woundDetails.primaryWoundInformation[key];
          smallestOrderWithInvalidStatus = this.validateInputField(
            woundDetail,
            smallestOrderWithInvalidStatus
          );
          if (
            woundDetail.valid === ValidationStatus.INVALID &&
            isWoundInformationValid
          ) {
            isWoundInformationValid = false;
          }
        }
      );
      if (woundCount === 2) {
        Object.keys(woundDetails.secondaryWoundInformation).forEach(
          (key: string) => {
            let woundDetail = woundDetails.secondaryWoundInformation[key];
            smallestOrderWithInvalidStatus = this.validateInputField(
              woundDetail,
              smallestOrderWithInvalidStatus
            );
            if (
              woundDetail.valid === ValidationStatus.INVALID &&
              isWoundInformationValid
            ) {
              isWoundInformationValid = false;
            }
          }
        );
      }
      if (!isWoundInformationValid) {
        woundData.valid = ValidationStatus.INVALID;
      }
    }
    return [woundData, smallestOrderWithInvalidStatus];
  }

  private validateInputField(
    input: IInputField,
    smallestOrderWithInvalidStatus: IInputField | null
  ) {
    if (input.isOptional === true && input.valid !== ValidationStatus.VALID) {
      input.valid = ValidationStatus.VALID;
    } else if (input.required && input.valid === ValidationStatus.UNTOUCHED) {
      input.valid = ValidationStatus.INVALID;
    } else if (input.isDefaultValid && input.valid !== ValidationStatus.VALID) {
      input.valid = ValidationStatus.INVALID;
    }
    if (input.valid === ValidationStatus.INVALID && input.order) {
      if (
        !smallestOrderWithInvalidStatus ||
        (smallestOrderWithInvalidStatus.order &&
          input.order &&
          smallestOrderWithInvalidStatus.order > input.order)
      ) {
        smallestOrderWithInvalidStatus = input;
      }
    }
    updateErrorMessage(input);
    return smallestOrderWithInvalidStatus;
  }
}
